<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/case-studies/beiersdorf/logo.svg"
              class="case-study__logo"
              alt="Beiersdorf Logo"
            >
            <h2 class="case-study__title">
              AI-driven Driver Analysis of Hansaplast Amazon Reviews
            </h2>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              Caplena, in collaboration with Beiersdorf, proudly received the prestigious <a href="https://www.bvm.org/der-bvm/preis-der-deutschen-marktforschung/innovationspreis/preistraeger-innovationspreis-2022/">
                2023 Innovation Award at the German Market Research Congress in Frankfurt/M
              </a> on June 14th, 2023. This award recognizes their innovative approach to analyzing online reviews by leveraging AI-powered insights to optimize product quality. This accomplishment is the backdrop for this case study to showcase how Beiersdorf adopted this approach by utilizing Caplena to improve its customer feedback loop in Hansaplast’s product development.
            </div>
            <img
              src="@/assets/landing/case-studies/beiersdorf/hero.jpg"
              class="case-study__image"
              alt="Beiersdorf"
            >
            <label class="case-study__image-text" />
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Beiersdorf AG, specifically its market research team at Hansaplast, relies on Amazon reviews to gain insights into customer sentiment regarding their products. However, they faced a challenge in analyzing these reviews comprehensively due to the difficulty of categorizing them across the many languages and product categories. The existing analysis focused solely on star ratings, lacking an exploration of the underlying factors that contributed to each rating. To gain a more comprehensive understanding of the reviews, it was necessary to conduct an analysis that could identify themes and sentiments associated with the respective star ratings.
            </div>

            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              Beiersdorf's market research team now leverage the automated insights platform, Caplena, to conduct text analysis and effectively address these challenges. The methodology within the platform consists of the following steps
            </div>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">•</span> <b>Topic Identification:</b> Caplena employs an AI model trained on a vast dataset of processed projects to identify and synthesize themes from text comments. This allows for a comprehensive understanding of the amazon reviews utilised, going beyond pre-defined topics.
                </div>
                <div class="mt-1">
                  <span class="mr-2">•</span> <b>Topic Assignment: </b> After topics are identified, they - and their respective (topic level) sentiment - are automatically assigned to reviews. Although the automatic assignment is of high quality, measured through the F1 score which is transparently displayed at all times, domain experts manually review a subset of the comments to bump up the accuracy to the desired amount.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Driver Analysis: </b> By assigning topics and sentiment to the comments, the unstructured data becomes structured. A driver analysis is performed using the topics as explanatory variables and the star ratings as the target variable. This analysis provides regression coefficients that indicate the strength and direction of influence each topic has on the star ratings.
                </div>
              </div>
            </div>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              By leveraging Caplena, Beiersdorf successfully achieves a structured analysis of unstructured online reviews. A fully interactive dashboard with a driver analysis on the topics allows insight managers to understand which mentions impact the star rating for specific products. This allows decision-makers to act on frequently mentioned and newly arising issues that strongly impact the star rating.
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Beiersdorf
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Beiersdorf is a global skin care company for leading brands such as NIVEA, Eucerin, Hansaplast and La Prairie. With a strong focus on innovation and quality, Beiersdorf offers a diverse range of skin care products to meet the needs of individuals worldwide.
              </div>
              <div class="case-study__company__subtitle">
                Products on Amazon tracked
              </div>
              <div class="case-study__company__text">
                400+
              </div>
              <div class="case-study__company__subtitle">
                Countries analyzed
              </div>
              <div class="case-study__company__text">
                10
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                6+
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />