<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/case-studies/brack/logo.jpg"
              class="case-study__logo"
              style="height: 25px;"
              alt="Brack"
            >
            <h2 class="case-study__title">
              How BRACK.CH transforms thousands of feedback into data-informed decisions
            </h2>
            <img
              src="@/assets/landing/case-studies/brack/hero.jpg"
              class="case-study__image w-100"
              alt="Brack"
              style="height: auto;"
            >
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img
                class="mr-2"
                src="@/assets/landing/case-studies/brack/sonja.jpg"
                alt="Tal"
                style="object-fit: cover; object-position: top;"
              >
              <div class="ml-2">
                <div class="case-study__person__name">
                  Sonja Hubl
                </div>
                <div class="case-study__person__position">
                  Market research project manager @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.brack.ch/"
                  >
                    Brack
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              BRACK.CH, a leading Swiss online retailer, leverages Caplena’s AI-powered text analytics to continuously enhance customer satisfaction. By conducting semi-annual analyses of open-ended responses from their ongoing customer satisfaction surveys, BRACK.CH efficiently extracts actionable insights from thousands of comments. These insights drive data-informed decisions, turning feedback into concrete actions that continuously enhance the customer experience.
              <br>
              <br>
              Caplena is also used across BRACK Business, which serves B2B customers, and Alltron, a wholesale supplier serving Swiss specialized retailers, integrators, and installers, ensuring that all segments of the company benefit from advanced feedback analysis.
            </div>

            <h5 class="case-study__title">
              The Challenge
            </h5>
            <div class="case-study__text">
              Before adopting Caplena, BRACK.CH was unable to fully tap into the wealth of insights hidden in open-ended customer feedback due to a lack of systematic analysis tools. Now, with Caplena, they can efficiently process this feedback, uncovering key trends and gaining clear, actionable insights that reveal what customers truly want. This has transformed previously inaccessible data into a powerful resource for informed, strategic decision-making.
            </div>

            <h5 class="case-study__title">
              Transition to Caplena
            </h5>
            <div class="case-study__text">
              In February 2024, BRACK.CH began using Caplena to address these challenges and improve their feedback analysis process. Caplena’s AI-driven platform enables BRACK.CH to process thousands of open-ended responses quickly, identifying key insights with minimal manual effort.
            </div>

            <h5 class="case-study__title">
              Key results
            </h5>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">1.</span> <b>Time Efficiency: </b> Tasks that would have required weeks of manual analysis are now completed in just a few hours with Caplena, enabling BRACK.CH to gain insights that were previously unavailable.
                </div>
                <div class="mt-1">
                  <span class="mr-2">2.</span> <b>Comprehensive Insights: </b> Caplena uncovers key trends and customer needs from thousands of responses in real-time, continuously providing a precise picture of customer satisfaction in all operating areas.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">3.</span> <b>Dashboard Visualization: </b> Caplena’s easy-to-use dashboard is shared with internal teams and members of decision-making groups, helping them make quick, data-based decisions.
                </div>
              </div>
            </div>

            <div class="case-study__quote">
              The added value of this tool is very high. Caplena allows us to analyze and quantify open-ended responses comprehensively. Previously, resource constraints made this very difficult.
            </div>

            <h5 class="case-study__title">
              Conclusion
            </h5>

            <div class="case-study__text">
              By adopting Caplena, BRACK.CH has transformed its approach to customer feedback, turning what was once an untapped resource into a powerful tool for driving improvements. The ability to efficiently analyze and quantify open-ended responses has revolutionized how BRACK.CH understands and responds to its customers' needs. This automated, data-driven process not only saves valuable time but also ensures that key insights are immediately actionable. With Caplena, BRACK.CH is better equipped to stay agile and customer-focused, delivering a superior experience across all business segments.
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                BRACK
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                BRACK.CH is a Swiss online retailer offering a vast selection of electronics, household goods, sports equipment, and more. Known for its fast delivery and strong customer service, BRACK.CH serves both private customers and businesses with a focus on quality products and a seamless shopping experience.
              </div>
              <div class="case-study__company__subtitle">
                Projects on Caplena
              </div>
              <div class="case-study__company__text">
                Semi-annual satisfaction surveys
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                February 2024
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {
      showVideo: false
    }
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />
