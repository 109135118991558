<template>
  <div class="column col-6 hide-md form-page__right position-relative">
    <div class="testimonial form-page__right__testimonial">
      <div class="d-flex align-center testimonial-author">
        <div class="img-holder flex-center-center">
          <img src="@/assets/landing/testimonial_selina.jpg" alt="Selina">
        </div>
        <div class="subtitle">
          <span>Selina Pietsch</span>
          Analyst at <a target="_blank" href="https://www.factworks.com/">Factworks</a>
        </div>
      </div>
      <div class="form-page__right__testimonial__text">
        Caplena’s intelligent algorithm has increased our efficiency with both tracking and ad hoc studies.
      </div>
    </div>
    <div class="form-page__right__logos w-100">
      <div class="text-grey text-uppercase logo-text text-center">
        {{ $t('trusted') }}
      </div>
      <div class="logos d-flex w-100">
        <div class="logo-holder">
          <img src="@/assets/landing/customer_logos/factworks_dark.svg?data" alt="Factworks" style="height: 30px;">
        </div>
        <div class="logo-holder">
          <img src="@/assets/landing/customer_logos/ebay_dark.svg?data" style="height: 30px;" alt="Ebay">
        </div>
        <div class="logo-holder">
          <img src="@/assets/landing/customer_logos/swisscom.svg?data" alt="Swisscom" style="height: 50px;">
        </div>
        <div class="logo-holder">
          <img src="@/assets/landing/customer_logos/coop.svg?data" style="height: 25px;" alt="Coop">
        </div>
        <div class="logo-holder">
          <img src="@/assets/landing/customer_logos/dhl.svg?data" alt="DHL" style="height: 20px;">
        </div>
        <!-- <div class="logo-holder">
                  <img src="@/assets/landing/customer_logos/joyn.svg?data" style="height: 28px; top: 4px;" alt="Joyn">
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data () {
    return {}
  },

  computed: {
  },

  created () {
  },

  mounted () {
  },

  methods: {
  }
}

</script>

<style lang=scss>
  @import '~@/css/landing/landing.scss';
</style>

<i18n locale='en' src='@/i18n/en/landing/Register.json' />
<i18n locale='de' src='@/i18n/de/landing/Register.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />