export default {
  name: 'filterDemoDataMixin',
  codit: true,

  computed: {
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    isPrerendering () {
      return window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.IS_PRERENDERING
    }
  }
}