<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/factworks.svg"
              class="case-study__logo"
              alt="Factworks Logo"
            >
            <h2 class="case-study__title">
              Factworks has been able to cut the turnaround time on their open-ended analyses in half.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/testimonial_pia.jpg" alt="Pia">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Pia Helen Kampf
                </div>
                <div class="case-study__person__position">
                  Research Consultant @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.factworks.com/"
                  >
                    Factworks
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              Factworks is a boutique market research agency, helping the world’s top forward-looking tech and finance companies answer their strategic questions. With offices in Berlin and Silicon Valley, they are trusted with the most intricate product, pricing and loyalty questions by brands like Facebook, Visa or eBay.
            </div>
            <img
              src="@/assets/landing/case-studies/factworks/hero.jpg"
              class="case-study__image"
              alt="Factworks"
            >
            <label class="case-study__image-text" />
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Quantitative research is Factworks’ strong suit: They have sensed the possibilities that advanced analytics and automation in the field of open-ended feedback can provide early on and won innovation awards for research on unstructured text data. With innovation as one of their core values, Factworks is always on the lookout for smart and efficient solutions to make the researcher’s life easier.
            </div>
            <div class="case-study__quote">
              Since 2002 Factworks has grown from a three-person garage startup to a thriving company with over 60 research experts who love crunching numbers. All research at Factworks is precisely tailored to their client’s needs, ensuring excellence throughout the entire process.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              Since mid 2018, Factworks employs Caplena as their main verbatim coding solution – making them one of our oldest customers 👵😊. Since then, they have continuously increased the usage scope of Caplena.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Pia, which other software did you consider before choosing Caplena?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Pia (Factworks)
                </div>
                <div class="case-study__conversation__answer__text">
                  We looked at both, long-term established NLP solutions that have been widely used in the space as well as entrance solutions with more modern approaches. We found that they either lacked user friendliness/collaboration features or didn’t have sufficient analysis capabilities. Most of the tools lacked in their ability to match responses with the required accuracy required to match manual coding. Caplena showed high responsiveness on change requests and offers a competitive pricing.
                </div>
              </div>
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  What is your favorite feature on Caplena?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Pia (Factworks)
                </div>
                <div class="case-study__conversation__answer__text">
                  There are three major game-changers for us:
                  <div>
                    1. How the AI is constantly improving with any new data reducing the time to finalize coding, especially for tracking projects where the speed and accuracy of the coding process keeps increasing without additional effort on our end.
                  </div>
                  <div>
                    2. Collaboration and access management: We appreciate that project members have one point of truth and can see or edit the latest version allowing for seamless collaboration (according to their permissions).
                  </div>
                  <div>
                    3. Flexibility in adjusting and optimizing code frames: It is easy to make changes and refine changes quickly.
                  </div>
                </div>
              </div>
            </div>
            <img
              src="@/assets/landing/case-studies/factworks/cooperation.jpg"
              class="case-study__image"
              alt="Factworks"
            >
            <label class="case-study__image-text">
              Selina (2<sup>nd</sup> from right) and Maurice (2<sup>nd</sup> from left) had the pleasure to both speak at an
              Industry Event at the University of Pforzheim in 2019
            </label>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              Through Caplena, Factworks has been able to significantly reduce the turnaround time on their open-ended analyses by cutting it in half. This enables Factworks to deliver insights faster which is increasingly important with their fast-paced technology clients. At the same time, they would have a much higher spend on verbatim coding if employing external manual coding agencies for the job instead of using Caplena.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Do you have any recommendations for other teams looking to setup an automated feedback analysis pipeline?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Pia (Factworks)
                </div>
                <div class="case-study__conversation__answer__text">
                  <div>
                    1. Start small, scale quickly - test on single projects, then continuously expand
                  </div>
                  <div>
                    2. Test and learn – run manual coding in parallel to fully understand how the AI works and where potential strengths and weaknesses are and adjust were needed
                  </div>
                  <div>
                    3. Push the limits – collaborate/engage in conversations with your software provider to improve capabilities with the tool and optimize your workflow, e.g., integrate R workflows with Caplena via their API
                  </div>
                  <div>
                    4. Shift from spending time on coding to spending time on insights generation and use the capabilites of the tool to better explore the data and extract relevant insights.
                  </div>
                </div>
              </div>
            </div>
            <div class="case-study__quote">
              We chose Caplena as our coding tool after carefully comparing alternatives back in 2018. It is great to witness the development of the platform over the past 3 years - making us happier customers with each update. Maurice and Pascal provide amazing support for their product. They are responsive and listen to our needs, making this partnership very valuable for us.
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Factworks
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Factworks is a midsized market research agency, helping the world’s top forward-looking tech and finance companies answer their strategic questions. With offices in Berlin and Silicon Valley, they are trusted with the most intricate product, pricing and loyalty questions by brands like Facebook, Visa or eBay.
              </div>
              <div class="case-study__company__subtitle">
                Users on Caplena
              </div>
              <div class="case-study__company__text">
                50
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                May 2018
              </div>
              <div class="case-study__company__subtitle">
                Verbatims analyzed
              </div>
              <div class="case-study__company__text">
                3.5M+
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                English, Spanish, Portuguese, French, German and 44 more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />