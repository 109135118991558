<template>
  <landing-scaffold class="sales-page professionals-page">
    <section class="hero hero-text overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-5 col-md-12">
            <h1 v-html="$t('hero.h1')" />
            <div class="hero-text__subtitle" v-html="$t('hero.subtitle')" />
            <div class="hero-text__btns-containers">
              <div class="d-flex">
                <a class="btn btn-primary btn-green mr-2" :href="`/${$i18n.locale}/register/`" v-text="$t('cta.book_demo')" />
              </div>
              <div class="d-flex">
                <div class="d-flex align-center check-text">
                  <check :size="17" />
                  {{ $t('trial.trial') }}
                </div>
                <div class="d-flex align-center check-text">
                  <check :size="17" />
                  {{ $t('trial.no_credit_card') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 col-md-0" />
          <div class="col-6 col-md-12 align-center d-flex justify-center position-relative">
            <!-- <img src="@/assets/landing/empower-professionals.svg" alt="Empower professionals hero" class="w-100"> -->
            <iframe
              v-if="!isPrerendering"
              loading="lazy"
              class="sales-page__video"
              src="https://www.youtube-nocookie.com/embed/vRX4wLM_acU"
              title="Caplena for professionals"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute hide-md">
        </div>
      </div>
    </section>
    <section class="section-testimonials pt-0 light-blue">
      <div class="container">
        <div class="text-center">
          <div class="column col-12 text-grey text-uppercase logo-text">
            {{ $t('hero.trusted') }}
          </div>
          <div class="logos d-flex flex-wrap">
            <a :href="`/en/case-studies/factworks`" class="logo-holder logo-holder--link">
              <img src="@/assets/landing/customer_logos/factworks_dark.svg?data" alt="Factworks" style="height: 30px;">
            </a>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/swisscom.svg?data" alt="Swisscom" style="height: 50px;">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/ebay_dark.svg?data" style="height: 30px;" alt="Ebay">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/dhl.svg?data" alt="DHL" style="height: 20px;">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/joyn.svg?data" style="height: 30px;" alt="Link institut">
            </div>
          </div>
          <div class="logos d-flex flex-wrap" style="margin-top: 35px;">
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/dr-schar.svg?data" alt="Dr shar" style="height: 25px;">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/teufel.svg?data" style="height: 34px;" alt="Ebay">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/tegut.svg?data" alt="DHL" style="height: 40px;">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/lufthansa.svg?data" style="height: 35px;" alt="Lufthansa">
            </div>
          </div>
        </div>
        <div class="testimonial column col-11 ml-auto mr-auto">
          <div class="d-flex">
            <img
              src="@/assets/landing/case-studies/joyn/hero.png"
              class="testimonial__image"
              alt="Factworks"
            >
            <div>
              <img
                src="@/assets/landing/customer_logos/joyn_dark.svg"
                class="testimonial__logo"
                alt="Factworks Logo"
              >
              <div class="testimonial__text">
                {{ $t('testimonial.text') }}
              </div>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img src="@/assets/landing/testimonial_kilian.jpg" alt="Selina">
                </div>
                <div class="subtitle">
                  <span>Kilian Hughes</span>
                  DIRECTOR RESEARCH & INSIGHTS @<label>JOYN</label>
                </div>
              </div>
              <div class="testimonial__stats">
                <div class="testimonial__stats__stat-container">
                  <div class="testimonial__stats__stat">
                    90%
                  </div>
                  <div class="testimonial__stats__label">
                    {{ $t('testimonial.stat_1') }}
                  </div>
                </div>
                <div class="testimonial__stats__stat-container">
                  <div class="testimonial__stats__stat">
                    800
                  </div>
                  <div class="testimonial__stats__label">
                    {{ $t('testimonial.stat_2') }}
                  </div>
                </div>
                <div class="testimonial__stats__stat-container">
                  <div class="testimonial__stats__stat">
                    125k +
                  </div>
                  <div class="testimonial__stats__label">
                    {{ $t('testimonial.stat_3') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-phone blue pb-0 position-relative">
      <div class="container d-flex flex-column align-center justify-center">
        <h2>{{ $t('phone.title') }}</h2>
        <div class="section-phone__subtitle">
          {{ $t('phone.subtitle') }}
        </div>
        <div class="d-flex justify-center align-center section-phone__match">
          <div class="d-flex flex-column justify-center align-center">
            <img
              src="@/assets/landing/match-logo.svg"
              alt="Caplena match logo"
              class="section-phone__match__image"
            >
            <div class="section-phone__match__entity">
              Caplena
            </div>
          </div>
          <div class="d-flex flex-column justify-center align-center">
            <img
              src="@/assets/landing/professionals.svg"
              alt="Professionals match image"
              class="section-phone__match__image"
            >
            <div class="section-phone__match__entity section-phone__match__entity--pr">
              {{ $t('phone.match') }}
            </div>
          </div>
        </div>
        <img
          v-if="$i18n.locale === 'en'"
          src="@/assets/landing/pro-phone-en.svg"
          alt="Phone conversation"
          class="section-phone__image"
        >
        <img
          v-if="$i18n.locale === 'de'"
          src="@/assets/landing/pro-phone-de.svg"
          alt="Phone conversation"
          class="section-phone__image"
        >
      </div>
      <img
        src="@/assets/landing/sales-arrow.png"
        alt="pointing-arrow"
        class="why-arrow"
        style="left: 20%; bottom: -7%; top: auto; transform: scaleX(-1);"
      >
    </section>
    <section class="section-why-it-works position-relative">
      <div class="container">
        <h2 class="text-center" v-html=" $t('why_works.title')" />
        <div class="text-center" v-html="$t('why_works.text')" />
        <h2 class="text-center" v-html=" $t('why_works.subtitle')" />
      </div>
      <img src="@/assets/landing/clouds.svg" alt="clouds image" class="why-clouds">
    </section>
    <section class="section-take-aways">
      <div class="container">
        <div class="columns take-aways-container">
          <div class="col-5 col-md-12 take-away-links">
            <div
              v-for="(linkItem, index) in $t('take_aways.list')"
              :key="index"
              class="take-away-link"
              :class="takeAwayActiveIndex === Number(index) && 'take-away-link--active'"
              @click="setTakeAwayActiveIndex(Number(index))"
            >
              <div class="take-away-link__label" v-html="linkItem.link.label" />
              <div class="take-away-link__title" v-html="linkItem.link.title" />
              <div class="take-away-link__text" v-html="linkItem.link.text" />
            </div>
          </div>
          <div class="col-7 col-md-12 take-away-container">
            <div
              class="take-away take-away--1"
              :class="takeAwayActiveIndex === 0 && 'take-away--active'"
            >
              <div class="columns container">
                <div class="d-flex w-100">
                  <div class="col-5 take-away__cell take-away__cell--first">
                    <div class="take-away__label" v-html="$t('take_aways.list[0].card.column_1.label')" />
                  </div>
                  <div class="col-7 take-away__cell">
                    <div class="take-away__label" v-html="$t('take_aways.list[0].card.column_2.label')" />
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="col-5 take-away__cell take-away__cell--first align-center">
                    <img
                      src="@/assets/landing/cx-professionals.svg"
                      class="take-away__image"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[0].card.column_1.professionals')" />
                  </div>
                  <div class="col-7 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[0].card.column_2.professionals')" />
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="col-5 take-away__cell take-away__cell--first align-center">
                    <img
                      src="@/assets/landing/teams-blue.svg"
                      class="take-away__image"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[0].card.column_1.ux')" />
                  </div>
                  <div class="col-7 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[0].card.column_2.ux')" />
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="col-5 take-away__cell take-away__cell--first align-center">
                    <img
                      src="@/assets/landing/teams.svg"
                      class="take-away__image"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[0].card.column_1.teams')" />
                  </div>
                  <div class="col-7 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[0].card.column_2.teams')" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="take-away take-away--2"
              :class="takeAwayActiveIndex === 1 && 'take-away--active'"
            >
              <div class="columns container">
                <div class="d-flex w-100">
                  <div class="col-3 take-away__cell take-away__cell--first" />
                  <div class="col-4 take-away__cell take-away__cell--second pt-0 pb-0">
                    <div class="take-away__label" v-html="$t('take_aways.list[1].card.column_2.label')" />
                  </div>
                  <div class="col-5 take-away__cell pt-0 pb-0">
                    <div class="take-away__label" v-html="$t('take_aways.list[1].card.column_3.label')" />
                  </div>
                </div>

                <div class="d-flex w-100">
                  <div class="col-3 take-away__cell take-away__cell--first">
                    <img
                      src="@/assets/landing/cx-professionals.svg"
                      class="take-away__image ml-0 mr-2 pr-2"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large mt-2 pt-1" v-html="$t('take_aways.list[1].card.column_1.cx')" />
                  </div>
                  <div class="col-4 take-away__cell take-away__cell--second pb-0">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_2.cx')" />
                  </div>
                  <div class="col-5 take-away__cell pb-0">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_3.cx')" />
                  </div>
                </div>

                <div class="d-flex w-100">
                  <div class="col-3 take-away__cell take-away__cell--first">
                    <img
                      src="@/assets/landing/teams-blue.svg"
                      class="take-away__image ml-0 mr-2 pr-2"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large mt-2" v-html="$t('take_aways.list[1].card.column_1.ux')" />
                  </div>
                  <div class="col-4 take-away__cell take-away__cell--second pb-0">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_2.ux')" />
                  </div>
                  <div class="col-5 take-away__cell pb-0">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_3.ux')" />
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="col-3 take-away__cell take-away__cell--first">
                    <img
                      src="@/assets/landing/sales-pie.svg"
                      class="take-away__image ml-0 mr-2 pr-2 mt-2"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[1].card.column_1.insight')" />
                  </div>
                  <div class="col-4 take-away__cell take-away__cell--second pb-0">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_2.insight')" />
                  </div>
                  <div class="col-5 take-away__cell pb-0">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_3.insight')" />
                  </div>
                </div>
                <div class="take-away__footnotes" v-html="$t('take_aways.list[1].card.footnotes')" />
              </div>
            </div>
            <div
              class="take-away take-away--3 d-flex justify-content-center align-center"
              :class="takeAwayActiveIndex === 2 && 'take-away--active'"
            >
              <img
                src="@/assets/landing/professional-insights.svg"
                class="take-away__image take-away__image--full"
                alt="Professional insights"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <comparison-table />
    <section class="section-cases case-studies-container">
      <h3 class="text-center">
        {{ $t('cases.title') }}
      </h3>
      <div class="container">
        <div class="columns case-study-list">
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/dr-schar`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/dr-schar_color.svg"
                    class="case-study__logo mb-2"
                    alt="Dr.Schar Logo"
                    style="height: 20px;"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.dr_schar') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/joyn`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/joyn_dark.svg"
                    class="case-study__logo"
                    alt="Joyn"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.joyn') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/link`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/link_dark.svg"
                    class="case-study__logo"
                    alt="Link Institut"
                    style="height: 20px;"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.link') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="section-use-cases">
      <div class="container">
        <h3 class="text-center">
          {{ $t('use_cases.title') }}
        </h3>
        <div class="columns">
          <div class="column col-4 col-md-12 use-cases-container">
            <a
              :href="`/${$i18n.locale}/use-cases/survey`"
              class="use-case-item d-flex align-center"
            >
              <div>
                <div class="use-case-item__title d-flex align-center w-100 justify-content-between">
                  {{ $t('survey.title') }}
                  <arrow-right class="use-case-item__arrow" :size="16" />
                </div>
                <div class="use-case-item__text">
                  {{ $t('survey.subtitle') }}
                </div>
              </div>
            </a>
          </div>
          <div class="column col-4 col-md-12 use-cases-container">
            <a
              :href="`/${$i18n.locale}/use-cases/transactional-cx`"
              class="use-case-item d-flex align-center"
            >
              <div>
                <div class="use-case-item__title d-flex align-center">
                  {{ $t('cx.title') }}
                  <arrow-right class="use-case-item__arrow" :size="16" />
                </div>
                <div class="use-case-item__text">
                  {{ $t('cx.subtitle') }}
                </div>
              </div>
            </a>
          </div>
          <div class="column col-4 col-md-12 use-cases-container">
            <a
              class="use-case-item d-flex align-center"
              :href="`/${$i18n.locale}/use-cases/review-analysis`"
            >
              <div>
                <div class="use-case-item__title d-flex align-center">
                  {{ $t('review.title') }}
                  <arrow-right class="use-case-item__arrow" :size="16" />
                </div>
                <div class="use-case-item__text">
                  {{ $t('review.subtitle') }}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="faq">
      <h3 class="text-center">
        {{ $t('faq.title') }}
      </h3>
      <div class="container">
        <div v-for="(question, index) in $t('faq.questions')" :key="index">
          <faq-item
            :title="question.title"
            :answer="question.answer"
          />
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>
<script>

import LandingScaffold from '@/pages/LandingScaffold'
import FaqItem from '@/landing/FaqItem'
import Check from 'vue-material-design-icons/Check.vue'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import Trial from '@/landing/Trial'
import ComparisonTable from '@/landing/ComparisonTable.vue'
import BrowserType from '@/mixins/browserType'
// import ListCheck from 'vue-material-design-icons/CheckboxMarkedCircleOutline.vue'

export default {
  components: {
    LandingScaffold,
    Check,
    Trial,
    ArrowRight,
    FaqItem,
    ComparisonTable
    // ListCheck
  },

  mixins: [BrowserType],

  data () {
    return {
      takeAwayActiveIndex: 0,
      takeAwayClicked: false,
      takeAwayInterval: null
    }
  },

  watch: {
    'takeAwayClicked' (clicked) {
      if (clicked) {
        clearInterval(this.takeAwayInterval)
      }
    }
  },

  created () {
    if (!this.isMobile) {
      this.animateTakeAways()
    }
  },

  beforeDestroy () {
    this.clearInterval(this.takeAwayInterval)
  },

  methods: {
    /**
     * Change the active take away tab every x seconds until the user clicks on one.
     */
    animateTakeAways () {
      this.takeAwayInterval = setInterval(() => {
        if (this.takeAwayActiveIndex < 2) {
          this.takeAwayActiveIndex += 1
        } else {
          this.takeAwayActiveIndex = 0
        }
      }, 2500)
    },

    /**
     * Sets the currently active take-away tab
     */
    setTakeAwayActiveIndex (idx) {
      this.takeAwayActiveIndex = idx
      this.takeAwayClicked = true
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />
<i18n locale='en' src='@/i18n/en/landing/Index.json' />
<i18n locale='de' src='@/i18n/de/landing/Index.json' />
<i18n locale='en' src='@/i18n/en/landing/UseCases.json' />
<i18n locale='de' src='@/i18n/de/landing/UseCases.json' />
<i18n locale='en' src='@/i18n/en/landing/Professionals.json' />
<i18n locale='de' src='@/i18n/de/landing/Professionals.json' />