<template>
  <landing-scaffold class="info-page">
    <component :is="currentComponent" v-bind="{ email }" />
    <trial />
  </landing-scaffold>
</template>

<script>

import LandingScaffold from '@/pages/LandingScaffold'
import TermsAndConditions from '@/landing/TermsAndConditions'
import PrivacyPolicy from '@/landing/PrivacyPolicy'
import SupportedLanguages from '@/landing/SupportedLanguages'
import MeetingBooked from '@/landing/MeetingBooked'
import EmailUnsubscribed from '@/landing/EmailUnsubscribed'
import Trial from '@/landing/Trial'

const VALID_CONTENT = ['terms-and-conditions', 'privacy-policy', 'supported-languages', 'meeting-booked', 'email-unsubscribed']

export default {
  components: {
    LandingScaffold,
    TermsAndConditions,
    PrivacyPolicy,
    SupportedLanguages,
    MeetingBooked,
    EmailUnsubscribed,
    Trial
  },

  data () {
    return {
      email: 'support@caplena.com'
    }
  },

  computed: {
    currentComponent () {
      let pathParts = window.location.pathname.split('/').slice(1)
      if (VALID_CONTENT.indexOf(pathParts[1]) === -1) throw Error(`Unrecognized path ${pathParts[1]}`)
      return pathParts[1]
    }
  },

  created () {
  },

  mounted () {
  },

  methods: {
  }
}

</script>

<i18n locale='en' src='@/i18n/en/landing/Register.json' />
<i18n locale='de' src='@/i18n/de/landing/Register.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />