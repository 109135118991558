<template>
  <section class="section-trial">
    <div class="container">
      <div class="section-header">
        <h2 v-html="$t('trial.title')" />
        <div class="subtitle trial-subtitle hide-md" v-html="$t('trial.subtitle')" />
      </div>
      <div class="columns hide-md">
        <div class="col-6 d-flex flex-col justify-center align-center text-center text-white position-relative">
          <div class="trial-seperator" />
          <div class="d-flex">
            <div class="trial-star" />
            <div class="trial-star" />
            <div class="trial-star" />
            <div class="trial-star" />
            <div class="trial-star" />
          </div>
          <div class="trial-star-text">
            {{ $t('trial.trust_col_1') }}
          </div>
        </div>
        <div class="col-6 d-flex flex-col justify-center align-center text-center text-white">
          <div class="d-flex">
            <div class="trial-star" />
            <div class="trial-star" />
            <div class="trial-star" />
            <div class="trial-star" />
            <div class="trial-star" />
          </div>
          <div class="trial-star-text">
            {{ $t('trial.trust_col_2') }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-center w-100 trial-input">
        <!-- <input
          type="email"
          class="trial-input"
          placeholder="Enter your email..."
        > -->
        <a
          :href="`/${$i18n.locale}/register/`"
          class="btn btn-primary btn-green btn-trial text-white"
        >
          {{ $t('cta.book_demo') }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>

export default {}

</script>

<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />
<i18n locale="en" src="@/i18n/en/landing/Index.json" />
<i18n locale="de" src="@/i18n/de/landing/Index.json" />