<template>
  <landing-scaffold>
    <section class="hero-text overflow-hidden hero-text--use-cases hero-text--dense">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-12 text-center">
            <div class="d-flex flex-column justify-center align-center">
              <h1 v-html="$t('hero.h1')" />
              <!-- <div class="hero-text__subtitle" v-html="$t('hero.subtitle')" /> -->
              <a
                class="btn btn-primary btn-green"
                :href="`/${$i18n.locale}/register/`"
              >
                {{ $t('menu.register') }}
                <arrow-right :size="19" />
              </a>
            </div>
          </div>
          <div class="col-6 col-md-12 container use-cases-container">
            <a
              class="use-case-item d-flex align-center"
              :href="`/${$i18n.locale}/use-cases/review-analysis`"
            >
              <img
                src="@/assets/landing/use-cases/review.svg"
                class="use-case-item__image"
                alt="Review"
              >
              <div>
                <div class="use-case-item__title">
                  {{ $t('review.title') }}
                </div>
                <div class="use-case-item__text">
                  {{ $t('review.subtitle') }}
                </div>
              </div>
              <arrow-right class="use-case-item__arrow" :size="22" />
            </a>
            <a
              :href="`/${$i18n.locale}/use-cases/transactional-cx`"
              class="use-case-item d-flex align-center"
            >
              <img
                src="@/assets/landing/use-cases/cx.svg"
                class="use-case-item__image"
                alt="cx"
              >
              <div>
                <div class="use-case-item__title">
                  {{ $t('cx.title') }}
                </div>
                <div class="use-case-item__text">
                  {{ $t('cx.subtitle') }}
                </div>
              </div>
              <arrow-right class="use-case-item__arrow" :size="22" />
            </a>
            <a
              :href="`/${$i18n.locale}/use-cases/survey`"
              class="use-case-item d-flex align-center"
            >
              <img
                src="@/assets/landing/use-cases/survey.svg"
                class="use-case-item__image"
                alt="Survey"
              >
              <div>
                <div class="use-case-item__title">
                  {{ $t('survey.title') }}
                </div>
                <div class="use-case-item__text">
                  {{ $t('survey.subtitle') }}
                </div>
              </div>
              <arrow-right class="use-case-item__arrow" :size="22" />
            </a>
          </div>
          <div class="col-6 col-md-12 container use-cases-container">
            <a
              :href="`/${$i18n.locale}/use-cases/brand-awareness`"
              class="use-case-item d-flex align-center"
            >
              <img
                src="@/assets/landing/use-cases/brand.svg"
                class="use-case-item__image"
                style="height: 100px;"
                alt="Brand"
              >
              <div>
                <div class="use-case-item__title">
                  {{ $t('brand.title') }}
                </div>
                <div class="use-case-item__text">
                  {{ $t('brand.subtitle') }}
                </div>
              </div>
              <arrow-right class="use-case-item__arrow" :size="22" />
            </a>
            <a
              :href="`/${$i18n.locale}/use-cases/text-analytics`"
              class="use-case-item d-flex align-center"
            >
              <img
                src="@/assets/landing/use-cases/analytics.svg"
                class="use-case-item__image"
                alt="Analytics"
                style="height: 85px;"
              >
              <div>
                <div class="use-case-item__title">
                  {{ $t('analytics.title') }}
                </div>
                <div class="use-case-item__text">
                  {{ $t('analytics.subtitle') }}
                </div>
              </div>
              <arrow-right class="use-case-item__arrow" :size="22" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'

export default {
  components: {
    LandingScaffold,
    Trial,
    ArrowRight
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/UseCases.json' />
<i18n locale='de' src='@/i18n/de/landing/UseCases.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />