<template>
  <landing-scaffold>
    <section class="hero hero-text">
      <div class="container">
        <div class="columns align-center">
          <div class="column col-5 col-md-12">
            <div class="hero__text position-relative hero-text__column">
              <h1 v-html="$t('hero.h1')" class="mb-2 pb-2" />
              <div class="tagline mt-2 pt-2">
                <span v-html="$t('hero.subtitle_part1')" />
              </div>
              <div class="hero-text__btns-containers">
                <div class="d-flex">
                  <a
                    class="btn btn-primary btn-green mr-2"
                    target="_blank"
                    href="./register/"
                    rel="noopener"
                  >
                    {{ $t("cta.book_demo") }}
                    <arrow-right :size="19" />
                  </a>
                  <!-- <a class="btn btn-primary btn-dark ml-2" href="./register/">{{
                    $t("cta.register")
                  }}</a> -->
                </div>
              </div>
            </div>
            <img
              src="@/assets/landing/hero-mask.svg"
              alt="hero-mask"
              class="hero-mask position-absolute hide-md"
            >
          </div>
          <div class="column col-7  col-md-12 justify-content-end">
            <div class="d-flex justify-end hero__video-container">
              <div class="position-relative hover" @click="toggleVideo">
                <button class="play absolute-center flex-center-center hover">
                  <play-icon :size="45" />
                </button>
                <div>
                  <!-- <img
                    alt="Video poster image"
                    class="hero__video-poster"
                    srcset="@/assets/landing/video-poster_2x.jpg 2x"
                    src="@/assets/landing/video-poster_1x.jpg"
                  > -->
                  <picture>
                    <source
                      type="image/webp"
                      media="(min-width: 1150px)"
                      srcset="
                        @/assets/landing/video-poster_1x.webp 1x,
                        @/assets/landing/video-poster_2x.webp 2x
                      "
                    >
                    <source
                      type="image/jpeg"
                      media="(min-width: 1150px)"
                      srcset="
                        @/assets/landing/video-poster_1x.jpg 1x,
                        @/assets/landing/video-poster_2x.jpg 2x
                      "
                    >
                    <source
                      type="image/webp"
                      media="(max-width: 1150px)"
                      srcset="
                        @/assets/landing/video-poster-mobile_1x.webp 1x,
                        @/assets/landing/video-poster-mobile_2x.webp 2x
                      "
                    >
                    <source
                      type="image/jpeg"
                      media="(max-width: 1150px)"
                      srcset="
                        @/assets/landing/video-poster-mobile_1x.jpg 1x,
                        @/assets/landing/video-poster-mobile_2x.jpg 2x
                      "
                    >
                    <img
                      alt="hero__video-poster"
                      src="@/assets/landing/video-poster_1x.jpg"
                      srcset="
                        @/assets/landing/video-poster_1x.jpg 1x,
                        @/assets/landing/video-poster_2x.jpg 2x
                      "
                    >
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="text-center">
            <div class="column col-12 text-grey text-uppercase logo-text">
              {{ $t("hero.trusted") }}
            </div>
            <div class="logos d-flex flex-wrap">
              <div class="logo-holder">
                <img
                  src="@/assets/landing/customer_logos/ebay_dark.svg?data"
                  style="height: 30px;"
                  alt="Ebay"
                >
              </div>
              <div
                class="logo-holder logo-holder--link"
              >
                <img
                  src="@/assets/landing/customer_logos/lufthansa.svg?data"
                  alt="Lufthansa"
                  style="height: 30px;"
                >
              </div>
              <a
                :href="`/en/case-studies/factworks`"
                class="logo-holder logo-holder--link"
              >
                <img
                  src="@/assets/landing/customer_logos/factworks_dark.svg?data"
                  alt="Factworks"
                  style="height: 30px;"
                >
              </a>
              <div
                class="logo-holder"
              >
                <img
                  src="@/assets/landing/customer_logos/flixbus.svg?data"
                  style="height: 24px;"
                  alt="Flixbus"
                >
              </div>
              <div class="logo-holder">
                <img
                  src="@/assets/landing/customer_logos/dhl.svg?data"
                  alt="DHL"
                  style="height: 20px;"
                >
              </div>
              <div class="logo-holder">
                <img
                  src="@/assets/landing/customer_logos/swisscom.svg?data"
                  alt="Swisscom"
                  style="height: 50px;"
                >
              </div>
              <!-- <a :href="`/en/case-studies/link`" class="logo-holder logo-holder--link">
                <img src="@/assets/landing/customer_logos/link.svg?data" alt="Link Institut" style="height: 23px;">
              </a> -->
              <!-- <div class="logo-holder">
                <img src="@/assets/landing/customer_logos/hsr.png" alt="HSR Specialist Researchers">
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-process">
      <div class="container">
        <div class="columns">
          <div class="column col-4 col-md-12 process-container">
            <a
              :href="`/${$i18n.locale}/features#feature-2`"
              class="process-column d-flex flex-column"
            >
              <img
                src="@/assets/landing/process-step-1.svg"
                alt="Discover and define topics"
                class="process-image"
              >
              <div
                class="process-title"
                v-html="$t('process.steps[0].title')"
              />
              <div
                class="process-text"
                v-html="$t('process.steps[0].description')"
              />
            </a>
          </div>
          <div class="column col-4 col-md-12 process-container">
            <a
              :href="`/${$i18n.locale}/features#feature-3`"
              class="process-column process-column--middle position-relative d-flex flex-column"
            >
              <div class="process-seperator" />
              <img
                src="@/assets/landing/process-step-2.svg"
                alt="Auto-tag and fine-tune"
                class="process-image"
              >
              <div
                class="process-title"
                v-html="$t('process.steps[1].title')"
              />
              <div
                class="process-text"
                v-html="$t('process.steps[1].description')"
              />
              <div class="process-seperator last" />
            </a>
          </div>
          <div class="column col-4 col-md-12 process-container">
            <a
              :href="`/${$i18n.locale}/features#feature-4`"
              class="process-column d-flex flex-column"
            >
              <img
                src="@/assets/landing/process-step-3.svg"
                alt="Visualize insights and tell your story"
                class="process-image"
              >
              <div
                class="process-title"
                v-html="$t('process.steps[2].title')"
              />
              <div
                class="process-text"
                v-html="$t('process.steps[2].description')"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="section-empower">
      <div class="container">
        <div class="columns">
          <div class="column col-12 section-header text-center">
            <h2 v-html="$t('empower.title')" />
            <div v-html="$t('empower.subtitle')" class="feature__text ml-auto mr-auto" />
          </div>
        </div>
        <div class="columns p-0">
          <a
            class="column col-3 col-md-12 empower-column"
            :href="`/${$i18n.locale}/market-research`"
          >
            <div class="d-flex flex-column justify-center align-center">
              <img
                src="@/assets/landing/empower-researchers.svg"
                alt="Market researchers"
              >
              <div class="text-center">
                {{ $t("empower.researchers") }}
                <div class="link mt-0">
                  {{ $t("what.learn") }}
                  <arrow-right :size="18" />
                </div>
              </div>
            </div>
          </a>
          <a
            class="column col-3 col-md-12 empower-column middle-empower-column"
            :href="`/${$i18n.locale}/professionals`"
          >
            <div class="d-flex flex-column justify-center align-center">
              <img
                src="@/assets/landing/empower-professionals.svg"
                alt="CX Proffessionals"
              >
              {{ $t("empower.professionals") }}
              <div class="link mt-0">
                {{ $t("what.learn") }}
                <arrow-right :size="18" />
              </div>
            </div>
          </a>
          <a
            class="column col-3 col-md-12 empower-column"
            :href="`/${$i18n.locale}/consultants`"
          >
            <div class="d-flex flex-column justify-center align-center">
              <img
                src="@/assets/landing/empower-consultants.svg"
                alt="Consultants"
              >
              {{ $t("empower.consultants") }}
              <div class="link mt-0">
                {{ $t("what.learn") }}
                <arrow-right :size="18" />
              </div>
            </div>
          </a>
          <a
            class="column col-3 col-md-12 empower-column"
            :href="`/${$i18n.locale}/case-studies/dhl`"
          >
            <div class="d-flex flex-column justify-center align-center">
              <img
                src="@/assets/landing/empower-hr.svg"
                alt="Human resources"
              >
              {{ $t("empower.human_resources") }}
              <div class="link mt-0">
                {{ $t("what.learn") }}
                <arrow-right :size="18" />
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
    <section class="section-what">
      <div class="container">
        <div class="columns">
          <div class="column col-12 section-header text-center">
            <h2 v-html="$t('what.title')" />
          </div>
        </div>
      </div>
      <div class="what-step">
        <div class="container">
          <div class="columns">
            <div class="col-6 col-md-12 what-column">
              <div>
                <div class="what-subheader">
                  {{ $t("what.steps[0].subheader") }}
                </div>
                <h3 class="what-title">
                  {{ $t("what.steps[0].title") }}
                </h3>
                <div class="what-text">
                  {{ $t("what.steps[0].text") }}
                </div>
                <a class="link" :href="`/${$i18n.locale}/features#feature-3`">
                  {{ $t("what.learn") }}
                  <arrow-right :size="18" />
                </a>
              </div>
            </div>
            <div class="col-6 col-md-12 what-column what-column--no-pad-top">
              <img
                src="@/assets/landing/what-step-1.svg"
                alt="Auto-tag your data"
                class="what-image"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="what-step blue">
        <div class="container">
          <div class="columns">
            <div
              class="col-6 what-column col-md-12 order-md-2 what-column--no-pad-top"
            >
              <img
                src="@/assets/landing/what-step-2.svg"
                alt="Auto-tag your data"
                class="what-image"
              >
            </div>
            <div class="col-6 what-column col-md-12 order-md-1">
              <div>
                <div class="what-subheader">
                  {{ $t("what.steps[1].subheader") }}
                </div>
                <h3 class="what-title">
                  {{ $t("what.steps[1].title") }}
                </h3>
                <div class="what-text">
                  {{ $t("what.steps[1].text") }}
                </div>
                <a class="link" :href="`/${$i18n.locale}/features#feature-2`">
                  {{ $t("what.learn") }}
                  <arrow-right :size="18" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="what-step">
        <div class="container">
          <div class="columns">
            <div class="col-6 what-column col-md-12">
              <div>
                <div class="what-subheader">
                  {{ $t("what.steps[2].subheader") }}
                </div>
                <h3 class="what-title">
                  {{ $t("what.steps[2].title") }}
                </h3>
                <div class="what-text">
                  {{ $t("what.steps[2].text") }}
                </div>
                <a class="link" :href="`/${$i18n.locale}/features#feature-4`">
                  {{ $t("what.learn") }}
                  <arrow-right :size="18" />
                </a>
              </div>
            </div>
            <div class="col-6 what-column col-md-12 what-column--no-pad-top">
              <img
                src="@/assets/landing/what-step-3.svg"
                alt="Auto-tag your data"
                class="what-image"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="what-step blue">
        <div class="container">
          <div class="columns">
            <div
              class="col-6 what-column col-md-12 order-md-2 what-column--no-pad-top"
            >
              <img
                src="@/assets/landing/what-step-4.svg"
                alt="Auto-tag your data"
                class="what-image what-image--4"
              >
            </div>
            <div class="col-6 what-column col-md-12 order-md-1">
              <div>
                <div class="what-subheader">
                  {{ $t("what.steps[3].subheader") }}
                </div>
                <h3 class="what-title">
                  {{ $t("what.steps[3].title") }}
                </h3>
                <div
                  class="what-text"
                  v-html="
                    $t('what.steps[3].text', {
                      link: `/${$i18n.locale}/supported-languages/`
                    })
                  "
                />
                <a class="link" :href="`/${$i18n.locale}/features#feature-1`">
                  {{ $t("what.learn") }}
                  <arrow-right :size="18" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
    <section class="section-testimonials">
      <div class="container">
        <div class="columns">
          <!-- <div class="column col-4 col-md-12">
            <a :href="`/en/case-studies/cx-ai`" class="testimonial d-block">
              <p>
                At success drivers we were in need of a powerful survey-coding
                platform for our new CX.ai product. After evaluating a number of
                commercial and open-source solutions we found Caplena, which
                outperformed all alternatives by a mile.
              </p>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/testimonial_frank.jpg"
                    alt="Frank"
                  >
                </div>
                <div class="subtitle">
                  <span>Dr. Frank Buckler</span>
                  CEO & founder at
                  <label>Success Drivers</label>
                </div>
              </div>
            </a>
          </div> -->
          <!-- <div class="column col-4 col-md-12">
            <a :href="`/en/case-studies/link`" class="testimonial d-block">
              <p>
                We have chosen Caplena as our everyday tool for coding
                responses. It helped us become significantly more efficient,
                improved our data management and enabled us to execute on
                projects with tens of thousands of responses.
              </p>

              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/testimonial_thomas.jpg"
                    alt="Thomas"
                  >
                </div>
                <div class="subtitle">
                  <span>Thomas Bauer</span> Head Data & Analytics at
                  <label>Link</label>
                </div>
              </div>
            </a>
          </div> -->
          <!-- <div
            class="column col-4 col-md-12 mt-3"
            style="margin-top: 1.625rem;"
          >
            <div class="testimonial d-block">
              <p>
                {{ $t('testimonials.julia') }}
              </p>

              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/testimonial_julia.jpg"
                    alt="Julia"
                    style="object-position: top;"
                  >
                </div>
                <div class="subtitle">
                  <span>Julia Richardson</span> Director at
                  <label>The Friday Collective</label>
                </div>
              </div>
            </div>
          </div> -->
          <div
            class="column col-4 col-md-12 mt-3"
            style="margin-top: 1.625rem;"
          >
            <a :href="`/en/case-studies/rugby`" class="testimonial d-block">
              <p>
                {{ $t('testimonials.tory') }}
              </p>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/testimonial_tory.jpg"
                    alt="Tory"
                    style="object-position: top;"
                  >
                </div>
                <div class="subtitle">
                  <span>Tory Clarkson</span> Insights Manager at
                  <label>England Rugby</label>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column col-4 col-md-12 mt-3"
            style="margin-top: 1.625rem;"
          >
            <div class="testimonial d-block">
              <p>
                {{ $t('testimonials.simon') }}
              </p>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/testimonial_simon.jpg"
                    alt="Simon"
                    style="object-position: top;"
                  >
                </div>
                <div class="subtitle">
                  <span>Simon Bühler</span> Project Manager Market at
                  <label>Swisscom</label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column col-4 col-md-12"
            style="margin-top: 1.625rem;"
          >
            <a :href="`/en/case-studies/factworks`" class="testimonial d-block">
              <p>
                {{ $t('testimonials.selina') }}
              </p>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/testimonial_selina.jpg"
                    alt="Selina"
                  >
                </div>
                <div class="subtitle">
                  <span>Selina Pietsch</span>
                  Analyst at <label>Factworks</label>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column col-4 col-md-12 mt-3"
            style="margin-top: 1.625rem;"
          >
            <a :href="`/en/case-studies/flixbus`" class="testimonial d-block">
              <p>
                {{ $t('testimonials.tal') }}
              </p>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/case-studies/flixbus/tal.jpg"
                    alt="Tal"
                    style="object-position: top;"
                  >
                </div>
                <div class="subtitle">
                  <span>Tal Schechter</span>
                  <div v-html="$escapeHtml('Head of Operations tools & systems at')" />
                  <label>Flixbus</label>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column col-4 col-md-12 mt-3"
            style="margin-top: 1.625rem;"
          >
            <a :href="`/en/case-studies/dr-schar`" class="testimonial d-block">
              <p>
                {{ $t('testimonials.silvia') }}
              </p>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/testimonial_silvia.jpg"
                    alt="Silvia"
                    style="object-position: top;"
                  >
                </div>
                <div class="subtitle">
                  <span>Silvia Hofer</span> Market Insight Manager at
                  <label>Dr. Schär</label>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column col-4 col-md-12"
            style="margin-top: 1.625rem;"
          >
            <a :href="`/en/case-studies/lufthansa`" class="testimonial d-block">
              <p>
                {{ $t('testimonials.thomas') }}
              </p>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img
                    src="@/assets/landing/testimonial_thomas_lufthansa.jpg"
                    alt="Thomas"
                  >
                </div>
                <div class="subtitle">
                  <span>Thomas Storbeck</span>
                  Senior Manager Customer Insights at <label>Lufthansa</label>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="d-flex justify-center w-100">
          <a :href="`/${$i18n.locale}/case-studies/`" class="btn btn-primary">
            {{ $t("testimonials.read_more") }}
          </a>
        </div>
      </div>
    </section>
    <transition name="fade">
      <div class="overlay" v-if="showVideo" @click="toggleVideo" />
    </transition>
    <transition name="fade">
      <div v-if="showVideo" id="video" class="modal-input modal-input--video">
        <video class="hero__video" autoplay controls preload="auto">
          <source src="@/assets/landing/explainer-video.mp4" type="video/mp4">
        </video>
      </div>
    </transition>
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import PlayIcon from 'vue-material-design-icons/Play.vue'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    PlayIcon,
    ArrowRight,
    Trial
  },

  data () {
    return {
      showVideo: false
    }
  },

  computed: {},
  mounted () {},
  methods: {
    toggleVideo () {
      this.showVideo = !this.showVideo
    }
  }
}
</script>

<i18n locale="en" src="@/i18n/en/landing/Index.json" />
<i18n locale="de" src="@/i18n/de/landing/Index.json" />
<i18n locale="en" src="@/i18n/en/landing/Global.json" />
<i18n locale="de" src="@/i18n/de/landing/Global.json" />