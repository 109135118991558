<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/joyn_dark.svg?data"
              class="case-study__logo"
              alt="Joyn"
            >
            <h2 class="case-study__title">
              Thanks to Caplena, Joyn understands its users' needs & pain points like few other companies do.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/testimonial_kilian.jpg" alt="Kilian">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Kilian Hughes
                </div>
                <div class="case-study__person__position">
                  Director Research & Insights @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.joyn.de/"
                  >
                    Joyn
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              Joyn is an streaming platform from Germany, standing on the shoulder of the giant ProSiebenSat.1 and Discovery. With up to 3.9 million monthly unique users, Joyn is aiming to become the local streaming service number 1 in Germany. At Joyn it’s all about data & users. That’s why their research & insights team, led by the amazing Kilian Hughes, plays such a crucial role in product decisions.
            </div>
            <img
              src="@/assets/landing/case-studies/joyn/hero.png"
              class="case-study__image"
              alt="Joyn"
            >
            <label class="case-study__image-text" />

            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Kilian, tell me one thing you do differently than other insights teams?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Kilian (Joyn)
                </div>
                <div class="case-study__conversation__answer__text">
                  We really go the extra mile to make it easy for our users to share their feedback. At every step of the user journey there is at least one, often multiple ways to make yourself heard. For example: When in the app, just shake your smartphone and a feedback form will pop up.
                </div>
              </div>
            </div>

            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Joyn’s challenge is that their efforts in collecting user-feedback were proving to be hugely successful: They collect over 800 feedbacks per week. Evaluating every one of these by hand would almost be a full-time job. Outsourcing this was no option either, as this would have cost a lot, aside from all other downsides.
              Besides the actual analysis, data handling was another issue: You don’t want to send around CSV files by email every other day to update your dashboards. That’s both error-prone, slow and tedious.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              In 2018 Kilian’s team decided to use Caplena to analyze their in-app and app-store feedback as well as UX surveys.
              <div>
                They implemented an automated pipeline: Feedbacks coming in from the Google Playstore or Instabug are ingested automatically into Caplena through a Zapier task. Once analyzed, the data is then transferred to Tableau to be shown in dashboards.
              </div>
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  What is your favorite feature on Caplena?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Kilian (Joyn)
                </div>
                <div class="case-study__conversation__answer__text">
                  Our team loves the “transparent” automation concept of Caplena: Having a fully automatic analysis, but still being able to modify things that we interpret differently and the application then learning from these changes is a truly powerful way of collaboration.
                </div>
              </div>
            </div>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              Kilian’s team now only spends a couple of hours (instead of days) per week to process over 800 detailed feedbacks. The insights are:
              <div>
                - Visualized in dashboards in real-time.
              </div>
              <div>
                - Delivered directly to the inbox of key teams as personalized, weekly digests.
              </div>
              <div>
                - Accessible for deep dives to product and UX teams upon demand.
              </div>
              <div>
                Armed with this unbiased voice of the customer, Joyn’s research teams enables their product, UX and marketing teams to prioritize features, understand issues and foresee trends. For example, they guided their product team to prioritize Chromecast support, which significantly increased user satisfaction and app-store ratings.
              </div>
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Do you have any recommendations for other teams setting up a semi or fully automated feedback analysis pipeline?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Kilian (Joyn)
                </div>
                <div class="case-study__conversation__answer__text">
                  Start quickly, iterate fast. Often managers want to implement an all-encompassing solution suitable for every purpose and event. But this will result in huge, long-running projects which all-too often fail. Be lean: Start, also if many things are still manual, but just start. Then automate one step after another. You will automatically prioritize the processes which are most tedious – and often those aren’t the ones you expected initially!
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Joyn
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Joyn is an streaming platform from Germany, standing on the shoulder of the giant ProSiebenSat.1 and Discovery. With up to 3.9 million monthly unique users, Joyn is aiming to become the local streaming service number 1 in Germany.
              </div>
              <div class="case-study__company__subtitle">
                Users on caplena
              </div>
              <div class="case-study__company__text">
                4
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                May 2019
              </div>
              <div class="case-study__company__subtitle">
                Verbatims analyzed
              </div>
              <div class="case-study__company__text">
                125'000+
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                German, French, Italian, English, Dutch and 20 more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />