<template>
  <landing-scaffold>
    <section class="hero-text overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="column col-12 text-center">
            <div class="d-flex flex-column justify-center align-center">
              <h1 v-html="$t('hero.h1')" />
              <div class="hero-text__subtitle" v-html="$t('hero.subtitle')" />
              <a
                class="btn btn-primary btn-green"
                :href="`/${$i18n.locale}/register/`"
              >
                {{ $t('menu.register') }}
                <arrow-right :size="19" />
              </a>
              <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="features-nav d-flex align-center hide-md">
      <button
        v-for="(step, index) in steps"
        class="features-nav__btn d-flex align-center w-100 justify-center hover h-100"
        :class="index === activeStep && 'features-nav__btn--active'"
        :key="index"
        @click="scrollToFeature(`feature-${index + 1}`)"
      >
        {{ index + 1 }}. {{ step.title }}
      </button>
    </div>
    <section class="feature" id="feature-1">
      <div class="container">
        <div class="columns">
          <div class="col-6 col-md-12 position-relative order-md-1">
            <div class="feature__item feature__item--main">
              <div class="position-absolute feature__number">
                1
              </div>
              <h2 class="position-relative">
                {{ $t('steps[0].title') }}
              </h2>
              <div class="feature__text">
                {{ $t('steps[0].text') }}
              </div>
              <div class="feature__list feature__list--dense">
                <div
                  v-for="(feature, idx) in getFeatureListByIdx(0)"
                  :key="idx"
                  class="feature__list__item d-flex"
                >
                  <check class="feature__list__check h-100" :size="24" />
                  <div>
                    <div class="feature__list__title">
                      {{ feature.title }}
                    </div>
                    <div class="feature__list__subtitle">
                      {{ feature.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12 order-md-2">
            <img
              src="@/assets/landing/feature-1.svg"
              class="feature__image feature__image--offset-top-md feature__image--1"
              alt="Data Ingestion"
            >
          </div>
          <div class="col-6 col-md-12 d-flex justify-center feature__subitem order-md-4">
            <img
              src="@/assets/landing/review-subfeature-1.svg"
              class="feature__image feature__image--integrations"
              alt="Data Ingestion"
              style="height: 359px; "
            >
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex flex-column justify-center align-center feature__subitem--no-margin-md order-md-3">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('steps[0].title') }}
              </div>
              <h4>{{ $t('steps[0].sub_features[0].title') }}</h4>
              <div class="feature__text feature__text--small feature__text--long" v-html="$t('steps[0].sub_features[0].text')" />
            </div>
          </div>
          <div class="col-6 col-md-12 d-flex justify-center feature__subitem feature__subitem--offset-top order-md-5">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('steps[0].title') }}
              </div>
              <h4>
                {{ $t('steps[0].sub_features[1].title') }}
              </h4>
              <div class="feature__text feature__text--small">
                {{ $t('steps[0].sub_features[1].text') }}
              </div>
              <a
                class="link"
                :href="`/${$i18n.locale}/supported-languages`"
              >
                {{ $t('steps[0].sub_features[1].link') }}
                <arrow-right :size="18" />
              </a>
            </div>
          </div>
          <div class="col-6 col-md-12 d-flex justify-center feature__subitem order-md-6">
            <img
              src="@/assets/landing/feature-1-language.svg"
              class="feature__image"
              alt="Language support"
            >
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex order-md-8">
            <img
              src="@/assets/landing/feature-1-anonymization.svg"
              class="feature__image"
              alt="Anonymization"
            >
          </div>
          <div class="col-6 col-md-12 d-flex align-center justify-center feature__subitem order-md-7">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('steps[0].title') }}
              </div>
              <h4>{{ $t('steps[0].sub_features[2].title') }}</h4>
              <div class="feature__text feature__text--small">
                {{ $t('steps[0].sub_features[2].text') }}
              </div>
              <a
                class="link"
                target="_blank"
                rel="noopener"
                href="https://caplena.com/docs/knowledge-base/hzrjzb1fw3n55-anonymize-text-comments"
              >
                {{ $t('steps[0].sub_features[2].link') }}
                <arrow-right :size="18" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature blue" id="feature-2">
      <div class="container">
        <div class="columns">
          <div class="col-6 col-md-12 position-relative">
            <div class="feature__item feature__item--main">
              <div class="position-absolute feature__number feature__number--dark">
                2
              </div>
              <h2 class="position-relative">
                {{ $t('steps[1].title') }}
              </h2>
              <div class="feature__text">
                {{ $t('steps[1].text') }}
              </div>
              <div class="feature__list">
                <div
                  v-for="(feature, idx) in getFeatureListByIdx(1)"
                  :key="idx"
                  class="feature__list__item d-flex"
                >
                  <check class="feature__list__check h-100" :size="24" />
                  <div>
                    <div class="feature__list__subtitle">
                      {{ feature.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12">
            <img
              src="@/assets/landing/feature-2.svg"
              class="feature__image feature__image--offset-top-md"
              alt="Discover topics"
            >
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex order-md-2">
            <img
              src="@/assets/landing/feature-2-learning.svg"
              class="feature__image"
              alt="Auto learning"
            >
          </div>
          <div class="col-6 col-md-12 d-flex align-center justify-center feature__subitem order-md-1">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('steps[1].title') }}
              </div>
              <h4>{{ $t('steps[1].sub_features[0].title') }}</h4>
              <div class="feature__text feature__text--small">
                {{ $t('steps[1].sub_features[0].text') }}
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex align-center justify-center">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('steps[1].title') }}
              </div>
              <h4>{{ $t('steps[1].sub_features[1].title') }}</h4>
              <div class="feature__text feature__text--small">
                {{ $t('steps[1].sub_features[1].text') }}
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12 feature__subitem">
            <img
              src="@/assets/landing/feature-2-brand.svg"
              class="feature__image"
              alt="Brand management"
            >
          </div>
          <div class="col-12 feature__subitem d-flex flex-column justify-center align-center">
            <div class="feature__tag">
              {{ $t('steps[1].title') }}
            </div>
            <h4>{{ $t('steps[1].sub_features[2].title') }}</h4>
            <div class="feature__text feature__text--small text-center">
              {{ $t('steps[1].sub_features[2].text') }}
            </div>
            <div class="feature__codebooks w-100 d-flex align-center justify-space-around hide-md">
              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item">
                    <img src="@/assets/landing/codebooks/travel.svg" alt="Travel codebook">
                  </div>
                </template>
                {{ $t('codebooks.travel') }}
              </helptip>

              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item feature__codebooks__item--offset">
                    <img src="@/assets/landing/codebooks/slack.svg" alt="App codebook">
                  </div>
                </template>
                {{ $t('codebooks.app') }}
              </helptip>

              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item">
                    <img src="@/assets/landing/codebooks/insurance.svg" alt="Insurance codebook">
                  </div>
                </template>
                {{ $t('codebooks.insurance') }}
              </helptip>

              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item feature__codebooks__item--offset">
                    <img src="@/assets/landing/codebooks/ecommerce.svg" alt="Ecommerce codebook">
                  </div>
                </template>
                {{ $t('codebooks.ecommerce') }}
              </helptip>

              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item">
                    <img src="@/assets/landing/codebooks/telecommnunications.svg" alt="Telecommnunications codebook">
                  </div>
                </template>
                {{ $t('codebooks.telecommnunications') }}
              </helptip>

              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item feature__codebooks__item--offset">
                    <img src="@/assets/landing/codebooks/rating.svg" alt="Rating codebook">
                  </div>
                </template>
                {{ $t('codebooks.rating') }}
              </helptip>

              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item">
                    <img src="@/assets/landing/codebooks/survey.svg" alt="Survey codebook">
                  </div>
                </template>
                {{ $t('codebooks.survey') }}
              </helptip>

              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item feature__codebooks__item--offset">
                    <img src="@/assets/landing/codebooks/chat.svg" alt="Chat codebook">
                  </div>
                </template>
                {{ $t('codebooks.chat') }}
              </helptip>

              <helptip :is-icon="false">
                <template v-slot:icon>
                  <div class="feature__codebooks__item">
                    <img src="@/assets/landing/codebooks/appstore.svg" alt="App codebook">
                  </div>
                </template>
                {{ $t('codebooks.app') }}
              </helptip>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature" id="feature-3">
      <div class="container">
        <div class="columns">
          <div class="col-6 col-md-12 position-relative">
            <div class="feature__item feature__item--main">
              <div class="position-absolute feature__number">
                3
              </div>
              <h2 class="position-relative">
                {{ $t('steps[2].title') }}
              </h2>
              <div class="feature__text">
                {{ $t('steps[2].text') }}
              </div>
              <div class="feature__list">
                <div
                  v-for="(feature, idx) in getFeatureListByIdx(2)"
                  :key="idx"
                  class="feature__list__item d-flex"
                >
                  <check class="feature__list__check h-100" :size="24" />
                  <div>
                    <div class="feature__list__subtitle">
                      {{ feature.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12">
            <img
              src="@/assets/landing/feature-3.svg"
              class="feature__image feature__image--offset-top-md"
              alt="Auto tag data"
            >
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex align-center justify-center order-md-2">
            <img
              src="@/assets/landing/feature-3-prediction.svg"
              class="feature__image w-100"
              style="max-width: 500px;"
              alt="Unlimited prediction"
            >
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex align-center justify-center order-md-1">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('steps[2].title') }}
              </div>
              <h4>
                {{ $t('steps[2].sub_features[0].title') }}
              </h4>
              <div class="feature__text feature__text--small">
                {{ $t('steps[2].sub_features[0].text') }}
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex align-center justify-center feature__subitem--no-margin-md">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('steps[2].title') }}
              </div>
              <h4>
                {{ $t('steps[2].sub_features[1].title') }}
              </h4>
              <div class="feature__text feature__text--small">
                {{ $t('steps[2].sub_features[1].text') }}
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex align-center justify-center hide-md">
            <img
              src="@/assets/landing/feature-3-filtering.svg"
              class="feature__image w-100"
              alt="Easy filtering"
            >
          </div>
        </div>
      </div>
    </section>
    <section class="feature blue" id="feature-4">
      <div class="container">
        <div class="columns">
          <div class="col-12">
            <div class="d-flex flex-column align-center justify-center text-center feature__centered">
              <div class="position-absolute feature__number feature__number--dark hidden-lg-only">
                4
              </div>
              <h2 class="position-relative">
                {{ $t('steps[3].title') }}
                <div class="position-absolute feature__number feature__number--dark feature__number--centered hide-md">
                  4
                </div>
              </h2>
              <div class="feature__text feature__text--long">
                {{ $t('steps[3].text') }}
              </div>
              <img
                src="@/assets/landing/feature-4.svg"
                class="feature__image feature__image--full w-100"
                alt="Dashboard"
              >
            </div>
          </div>
          <div class="col-3 col-md-12 position-relative feature__small-col">
            <img
              src="@/assets/landing/feature-icon-1.svg"
              class="feature__icon position-absolute"
              alt="Interactive charts"
            >
            <h5>
              {{ $t('steps[3].sub_features[0].title') }}
            </h5>
            <div class="feature__text feature__text--x-small feature__text--short">
              {{ $t('steps[3].sub_features[0].text') }}
            </div>
          </div>
          <div class="col-3 col-md-12 position-relative feature__small-col">
            <img
              src="@/assets/landing/feature-icon-2.svg"
              class="feature__icon position-absolute"
              alt="Visualisation"
            >
            <h5>
              {{ $t('steps[3].sub_features[1].title') }}
            </h5>
            <div class="feature__text feature__text--x-small feature__text--short">
              {{ $t('steps[3].sub_features[1].text') }}
            </div>
          </div>
          <div class="col-3 col-md-12 position-relative feature__small-col">
            <img
              src="@/assets/landing/feature-icon-3.svg"
              class="feature__icon position-absolute"
              alt="Advanced analysis"
            >
            <h5>
              {{ $t('steps[3].sub_features[2].title') }}
            </h5>
            <div class="feature__text feature__text--x-small feature__text--short">
              {{ $t('steps[3].sub_features[2].text') }}
            </div>
          </div>
          <div class="col-3 col-md-12 position-relative feature__small-col">
            <img
              src="@/assets/landing/feature-icon-4.svg"
              class="feature__icon position-absolute"
              alt="Data correlation"
            >
            <h5>
              {{ $t('steps[3].sub_features[3].title') }}
            </h5>
            <div class="feature__text feature__text--x-small feature__text--short">
              {{ $t('steps[3].sub_features[3].text') }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import Check from 'vue-material-design-icons/CheckboxMarkedCircleOutline.vue'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial,
    Check,
    // PlayIcon,
    ArrowRight
  },

  data () {
    return {
      activeStep: 0,
      steps: [
        {
          title: this.$t('steps[0].title')
        },
        {
          title: this.$t('steps[1].title')
        },
        {
          title: this.$t('steps[2].title')
        },
        {
          title: this.$t('steps[3].title')
        }
      ]
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleNavScroll)
  },

  mounted () {
    if (window.location.hash) {
      setTimeout(() => {
        window.scrollTo({ top: window.scrollY - 175 })
      }, 1)
    }

    window.addEventListener('scroll', _.throttle(this.handleNavScroll, 100))
  },
  methods: {
    // getURLParam (key) {
    //   const urlParams = new URLSearchParams(window.location.search.substr(1))
    //   let val = urlParams.get(key)
    //   return val !== null ? decodeURIComponent(val) : null
    // },

    getFeatureListByIdx (idx) {
      return this.$t(`steps[${idx}].feature_list`)
    },

    scrollToFeature (feature, smooth = true) {
      history.pushState(null, null, `#${feature}`)
      const featureOffset = document.getElementById(feature).offsetTop - 175
      window.scrollTo({ top: featureOffset, behavior: 'smooth' })
    },

    between (x, min, max) {
      return x >= min && x <= max
    },

    handleNavScroll () {
      // const feature1 = document.getElementById('feature-1').offsetTop - window.innerHeight / 3
      const feature2 = document.getElementById('feature-2').offsetTop - window.innerHeight / 3
      const feature3 = document.getElementById('feature-3').offsetTop - window.innerHeight / 3
      const feature4 = document.getElementById('feature-4').offsetTop - window.innerHeight / 3

      if (window.scrollY < feature2) this.activeStep = 0
      if (this.between(window.scrollY, feature2, feature3)) this.activeStep = 1
      if (this.between(window.scrollY, feature3, feature4)) this.activeStep = 2
      if (window.scrollY > feature4) this.activeStep = 3
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/Features.json' />
<i18n locale='de' src='@/i18n/de/landing/Features.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />