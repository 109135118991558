<template>
  <section class="hero-text overflow-hidden">
    <div class="container">
      <div class="columns">
        <div class="column col-7 col-md-12 col-mx-auto">
          <h1 class="ml-0 mb-2 pb-2">
            Sorry to see you go! 😔
          </h1>
          <div class="subtitle mt-2">
            You have been unsubscribed from our <em>{{ campaign }}</em> emails.
            <div v-if="newsletterUnsubscribeLink">
              To unsubscribe from our newsletter as well, <a :href="newsletterUnsubscribeLink">click here</a>.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

const CAMPAIGN_MAPPING = {
  'onboarding': 'onboarding'
}

export default {
  codit: true,
  name: 'MeetingBooked',

  data () {
    return {
      campaign: '',
      newsletterUnsubscribeLink: ''
    }
  },

  created () {
    const urlParams = new URLSearchParams(window.location.search)
    this.campaign = CAMPAIGN_MAPPING[urlParams.get('campaign')] || '(unknown)'
    this.newsletterUnsubscribeLink = urlParams.get('unsubscribe_link')
  }
}

</script>
