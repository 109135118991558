<template>
  <landing-scaffold class="managed-page">
    <section class="hero hero-text overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-5 col-md-12">
            <h1 v-html="$t('hero.h1')" />
            <div class="hero-text__subtitle" v-html="$t('hero.subtitle')" />
            <div class="hero-text__btns-containers">
              <div class="d-flex">
                <a class="btn btn-primary btn-green mr-2" href="#costs" v-text="$t('cta.get_offer')" />
              </div>
            </div>
          </div>
          <div class="col-7 col-md-12 align-center d-flex justify-end position-relative">
            <img src="@/assets/landing/managed-hero.svg" alt="Managed services hero image" class="managed-page__hero-img">
          </div>
          <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute hide-md">
        </div>
      </div>
    </section>

    <section id="how-it-works">
      <div class="container">
        <div class="columns">
          <div class="col-12 section-header text-center">
            <h2 v-html="$t('process.title')" />
            <div class="subtitle" v-html="$t('process.subtitle')" />
          </div>
        </div>
      </div>
      <div ref="steps" class="container steps position-relative">
        <div class="steps__progress" :style="`height: ${stepProgress}%`" />
        <div class="columns position-relative">
          <div class="column col-6 col-md-12 d-flex justify-end hide-md">
            <div class="steps__image-container">
              <img src="@/assets/landing/process-step-0.svg" alt="Step 0" style="height: 110px;">
            </div>
          </div>
          <div class="column col-6 col-md-12 d-flex flex-column justify-center">
            <div class="steps__content">
              <div class="steps__content__step">
                1
              </div>
              <div class="steps__content__tag" v-html="$t('process.steps.1.tag')" />
              <h5>{{ $t('process.steps.1.title') }}</h5>
              <div class="steps__content__text" v-html="$t('process.steps.1.description')" />
            </div>
          </div>
          <div
            ref="step-1"
            class="steps__indicator"
            :class="stepsActive[0] && 'steps__indicator--active'"
          />
        </div>

        <div class="columns position-relative">
          <div class="column col-6 col-md-12 d-flex flex-column justify-center">
            <div class="steps__content">
              <div class="steps__content__step steps__content__step--primary">
                2
              </div>
              <div class="steps__content__tag steps__content__tag--primary" v-html="$t('process.steps.2.tag')" />
              <h5>{{ $t('process.steps.2.title') }}</h5>
              <div class="steps__content__text" v-html="$t('process.steps.2.description')" />
            </div>
          </div>
          <div class="column col-6 col-md-12 hide-md">
            <div class="steps__image-container">
              <img src="@/assets/landing/process-step-1.svg" alt="Step 1">
            </div>
          </div>
          <div
            ref="step-2"
            class="steps__indicator"
            :class="stepsActive[1] && 'steps__indicator--active'"
          />
        </div>
        <div class="columns position-relative">
          <div class="column col-6 col-md-12 d-flex flex-column justify-center">
            <div class="steps__content">
              <div class="steps__content__step steps__content__step--primary">
                3
              </div>
              <div class="steps__content__tag steps__content__tag--primary" v-html="$t('process.steps.3.tag')" />
              <h5>{{ $t('process.steps.3.title') }}</h5>
              <div class="steps__content__text" v-html="$t('process.steps.3.description')" />
            </div>
          </div>
          <div class="column col-6 col-md-12 hide-md">
            <div class="steps__image-container">
              <img src="@/assets/landing/process-step-2.svg" alt="step 2">
            </div>
          </div>
          <div
            ref="step-3"
            class="steps__indicator"
            :class="stepsActive[2] && 'steps__indicator--active'"
          />
        </div>
        <div class="columns position-relative">
          <div class="column col-6 col-md-12 d-flex justify-end hide-md">
            <div class="steps__image-container">
              <img src="@/assets/landing/process-step-3.svg" alt="Step 3">
            </div>
          </div>
          <div class="column col-6 col-md-12 d-flex flex-column justify-center">
            <div class="steps__content">
              <div class="steps__content__step">
                4
              </div>
              <div class="steps__content__tag" v-html="$t('process.steps.4.tag')" />
              <h5>{{ $t('process.steps.4.title') }}</h5>
              <div class="steps__content__text" v-html="$t('process.steps.4.description')" />
            </div>
          </div>
          <div
            ref="step-4"
            class="steps__indicator"
            :class="stepsActive[3] && 'steps__indicator--active'"
          />
        </div>
      </div>
    </section>

    <section class="section-testimonials" id="testimonial">
      <div class="container">
        <div class="columns justify-center">
          <div class="column col-9 col-md-12">
            <div class="testimonial d-flex">
              <img src="@/assets/landing/testimonial_selina_large.jpg" alt="Selina" class="hide-md">
              <div class="testimonial-author d-flex flex-column justify-center">
                <p>Caplena’s intelligent algorithm has increased our efficiency with both tracking and ad hoc studies</p>
                <div class="d-flex align-center">
                  <img src="@/assets/landing/testimonial_selina.jpg" alt="Selina" class="hide-lg-and-up">
                  <div class="subtitle">
                    <span>Selina Pietsch</span>
                    Analyst at <a target="_blank" href="https://www.factworks.com/">Factworks</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features color-grey">
      <div class="container">
        <div class="columns">
          <template v-for="ft, idx in $ta('features')">
            <div :key="idx" class="column col-4 col-md-12">
              <img
                :src="getFeatureIconPath(idx)"
                class="feature__icon"
                :alt="`Feature icon ${idx + 1}`"
              >
              <h5 v-html="ft.title" />
              <p v-html="ft.description" />
            </div>
          </template>
        </div>
      </div>
    </section>

    <section class="section-calculator blue" id="costs">
      <div class="container">
        <div class="columns">
          <div class="col-12 section-header text-center">
            <h2 v-html="$t('cta.get_offer')" />
            <div class="subtitle" v-html="$t('pricing.subtitle')" />
          </div>
        </div>
        <div class="columns calculator">
          <div class="column col-3 col-md-0" />
          <div class="column col-6 col-md-12 calculator__form">
            <div class="columns">
              <div class="column col-12 form-group">
                <div class="border-form">
                  <label class="calculator__form__label">
                    {{ $t('pricing.n_respondents') }}
                    <helptip>
                      {{ $t('pricing.n_respondents_helptip') }}
                    </helptip>
                  </label>
                  <div class="calculator__form__value">
                    {{ nRespondents }}
                  </div>
                  <vue-slider
                    v-model="quote.respondents_raw"
                    :min="1"
                    :max="nRespondentSteps"
                    :step="1"
                    :tooltip="'none'"
                    class="calculator__form__slider"
                  />
                </div>
              </div>
              <div class="column col-12 form-group">
                <label class="calculator__form__label">
                  {{ $t('pricing.perc_replied') }}
                  <helptip>
                    {{ $t('pricing.perc_replied_helptip') }}
                  </helptip>
                </label>
                <div class="calculator__form__value">
                  {{ quote.response_rate }}%
                </div>
                <vue-slider
                  v-model="quote.response_rate"
                  :min="5"
                  :max="100"
                  :step="5"
                  :tooltip="'none'"
                  class="calculator__form__slider"
                />
              </div>
              <div class="column col-12 form-group">
                <label class="calculator__form__label">
                  {{ $t('pricing.n_questions') }}
                </label>
                <div class="calculator__form__value">
                  {{ quote.questions }}
                </div>
                <vue-slider
                  v-model="quote.questions"
                  :min="1"
                  :max="10"
                  :step="1"
                  :tooltip="'none'"
                  class="calculator__form__slider"
                />
              </div>
              <div class="column col-12 form-group">
                <input type="file" name="file" ref="upload" @change="fileSelected">
                <div class="w-100 calculator__form__upload flex-center-between-row">
                  <label
                    id="fileList"
                    class="calculator__form__upload__label"
                    :class="quote.file && 'calculator__form__upload__label--active' "
                  >
                    {{ quote.file ? quote.file.name : $t('pricing.upload.title') }}
                  </label>
                  <a href="#"
                     @click.prevent="selectFile"
                     class="btn btn-rounded btn-primary calculator__form__upload__btn mr-0"
                     v-text="$t('pricing.upload.select_file')"
                  />
                </div>
              </div>
              <div class="column col-12">
                <textarea class="calculator__form__comments w-100" placeholder="Add a comment..." />
                <a
                  class="btn btn-primary btn-green calculator__results__submit mb-2"
                  v-if="!status.success"
                  :disabled="!quote.file"
                  @click="requestOffer"
                  v-text="$t('pricing.result.submit')"
                />
                <div
                  v-if="status.error === 'file'"
                  v-text="$t(`pricing.error.${status.error}`)"
                  class="mb-2 text-danger text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--========== Submit Request Popup ==========-->
    <transition name="fade">
      <div class="overlay" v-if="status.modal" @click="closeSubmitDialog" />
    </transition>
    <transition name="fade">
      <div class="modal-input" v-if="status.modal">
        <button
          class="modal-input__close"
          title="Close (Esc)"
          type="button"
          @click="closeSubmitDialog"
        >
          &#215;
        </button>
        <div v-if="!status.success">
          <form @submit.prevent="submit">
            <h5 class="text-center pb-2">
              {{ $t('pricing.submit.intro') }}
            </h5>
            <label class="font-weight-bold text-sm">{{ $t('pricing.submit.name') }}</label>
            <input
              v-model="quote.name"
              ref="quote-name"
              type="text"
              :class="{ 'has-error': status.error === 'name_email' }"
              :placeholder="$t('pricing.submit.name')"
              required
            >
            <label class="font-weight-bold text-sm">{{ $t('pricing.submit.email') }}</label>
            <input
              v-model="quote.email"
              type="email"
              :class="{ 'has-error': status.error === 'name_email' }"
              :placeholder="$t('pricing.submit.email')"
              required
            >
            <label class="font-weight-bold text-sm">{{ $t('pricing.submit.phone') }}</label>
            <input
              v-model="quote.phone"
              type="text"
              class="mb-2"
              :placeholder="$t('pricing.submit.phone')"
            >
            <button
              type="submit"
              class="btn btn-primary modal-input__submit modal-input__submit--offset"
              :disabled="status.loading"
              :class="{ shake: status.error && !status.loading }"
            >
              <span v-if="!status.loading">{{ $t('pricing.submit.btn') }}</span>
              <span v-else>{{ $t('pricing.submit.loading') }}</span>
            </button>
            <div
              class="text-xs text-danger mt-1"
              v-if="status.error === 'name_email' || status.error === 'request'"
              v-text="$t(`pricing.error.${status.error}`)"
            />
          </form>
        </div>
        <div v-else-if="status.success">
          <div v-text="$t('pricing.submit.success')" />
        </div>
      </div>
    </transition>
  </landing-scaffold>
</template>
<script>

import LandingScaffold from '@/pages/LandingScaffold'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

// The price per verbatim for different volume thresholds
const VERBATIM_PRICE = [
  { thresh: 0, price: 0.3 },
  { thresh: 500, price: 0.27 },
  { thresh: 750, price: 0.23 },
  { thresh: 1000, price: 0.2 },
  { thresh: 2500, price: 0.18 },
  { thresh: 5000, price: 0.16 },
  { thresh: 10000, price: 0.15 },
  { thresh: 20000, price: 0.14 },
  { thresh: 50000, price: 0.13 },
  { thresh: 65000, price: 0.12 },
  { thresh: 80000, price: 0.11 },
  { thresh: 100000, price: 0.1 }
]

// Number of verbatims that can be coded by hour, depending on volume
// The higher rate is achieved by having a larger fraction of the
// responses being coded automatically (not reviewed)
const VERBATIMS_PER_HOUR = [
  { thresh: 0, rate: 125 },
  { thresh: 300, rate: 175 },
  { thresh: 600, rate: 200 },
  { thresh: 1000, rate: 300 },
  { thresh: 2000, rate: 400 },
  { thresh: 4000, rate: 600 },
  { thresh: 5000, rate: 700 },
  { thresh: 10000, rate: 800 },
  { thresh: 15000, rate: 1000 },
  { thresh: 20000, rate: 1300 },
  { thresh: 30000, rate: 1800 },
  { thresh: 40000, rate: 2200 },
  { thresh: 50000, rate: 2500 },
  { thresh: 75000, rate: 3000 },
  { thresh: 100000, rate: 3300 }
]

// The minimum fee for small volumes
const MINIMUM_FEE_PER_QUESTION = 150

const MAX_PRICE = 39000
const MAX_TIME = 14

export default {
  components: {
    LandingScaffold,
    VueSlider
  },

  data () {
    return {
      languageOptions: [],

      questionOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      quote: {
        respondents_raw: 8,
        response_rate: 75,
        questions: 1,
        file: null,
        name: '',
        email: '',
        phone: ''
      },

      stepsActive: [false, false, false, false],

      stepProgress: 0,

      status: {
        loading: false,
        error: false,
        success: false,
        modal: false
      }
    }
  },

  computed: {
    nRespondentSteps () {
      return 20 + 15 + 16 + 5
    },

    nRespondents () {
      let n = 0
      let sliderLeft = this.quote.respondents_raw
      // Up to 5k
      n += Math.min(sliderLeft, 20) * 250
      sliderLeft = Math.max(sliderLeft - 20, 0)
      // From 5k to 20k
      n += Math.min(sliderLeft, 15) * 1000
      sliderLeft = Math.max(sliderLeft - 15, 0)
      // From 20k to 100k
      n += Math.min(sliderLeft, 16) * 5000
      sliderLeft = Math.max(sliderLeft - 16, 0)
      // From 100k to 150k
      n += Math.min(sliderLeft, 5) * 10000
      sliderLeft = Math.max(sliderLeft - 5, 0)

      return n
    },

    /**
     * Number of non-empty responses per question
     * @return {Number}
     */
    realResponses () {
      return this.nRespondents * this.quote.response_rate * 0.01
    },

    /**
     * The base price for the given amount of verbatims per question
     * @return {Number}
     */
    baseResponsePrice () {
      return _.findLast(VERBATIM_PRICE, e => this.realResponses >= e.thresh).price
    },

    /**
     * The total price per verbatim per question (base + all surcharges)
     * @return {Number}
     */
    totalResponsePrice () {
      return Math.round((this.baseResponsePrice) * 100) / 100
    },

    /**
     * The total price per per question
     * @return {Number}
     */
    pricePerQuestion () {
      return Math.round(this.totalResponsePrice * this.realResponses * 100) / 100
    },

    /**
     * If the minimum fee applies, i.e. if the volume is too small
     * @return {Boolean}
     */
    minimumFeeApplies () {
      return this.pricePerQuestion < MINIMUM_FEE_PER_QUESTION
    },

    /**
     * The total price
     * @return {Number}
     */
    totalPrice () {
      return ((this.minimumFeeApplies ? MINIMUM_FEE_PER_QUESTION : this.pricePerQuestion) * this.quote.questions).toFixed(0)
    },

    /**
     * If the price is not displayed anymore, but a placeholder "on request" is shown in its stead
     * @return {Boolean}
     */
    priceOnRequest () {
      return this.totalPrice > MAX_PRICE
    },

    /**
     * The number of verbatims that can be coded by hour depending on the volume
     * @return {Number}
     */
    verbatimsPerHour () {
      let idx = 0
      let weightedRate = 0
      let verbatimsRemaining = this.realResponses
      let prevThresh = 0
      do {
        let verbatimsAtThisRate = (idx + 1) >= VERBATIMS_PER_HOUR.length
          ? verbatimsRemaining
          : Math.min(VERBATIMS_PER_HOUR[idx + 1].thresh - prevThresh, verbatimsRemaining)
        weightedRate += verbatimsAtThisRate * VERBATIMS_PER_HOUR[idx].rate
        verbatimsRemaining -= verbatimsAtThisRate
        prevThresh = (idx + 1) >= VERBATIMS_PER_HOUR.length ? undefined : VERBATIMS_PER_HOUR[idx + 1].thresh
        idx++
      } while (verbatimsRemaining > 0)
      return weightedRate / this.realResponses
      // return _.findLast(VERBATIMS_PER_HOUR, e => this.realResponses >= e.thresh).rate
    },

    /**
     * The estimated number of days it will take to complete the coding
     * @return {Number}
     */
    completionTime () {
      let hoursPerQuestion = this.realResponses / this.verbatimsPerHour
      let totalHours = hoursPerQuestion * Math.sqrt(this.quote.questions)
      return Math.max(Math.round(totalHours / 6), 2)
    },

    /**
     * If the completion time is not displayed anymore, but a placeholder "on request" is shown in its stead
     * @return {Boolean}
     */
    completionTimeOnRequest () {
      return this.completionTime > MAX_TIME
    }
  },

  watch: {
    'status.modal' () {
      document.documentElement.style.overflow = this.status.modal ? 'hidden' : 'auto'
    }
  },

  mounted () {
    window.addEventListener('scroll', this.handleStepsScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleStepsScroll)
  },

  methods: {
    handleStepsScroll () {
      this.$set(this.stepsActive, 0, this.$refs['step-1'].getBoundingClientRect().top < window.innerHeight / 2)
      this.$set(this.stepsActive, 1, this.$refs['step-2'].getBoundingClientRect().top < window.innerHeight / 2)
      this.$set(this.stepsActive, 2, this.$refs['step-3'].getBoundingClientRect().top < window.innerHeight / 2)
      this.$set(this.stepsActive, 3, this.$refs['step-4'].getBoundingClientRect().top < window.innerHeight / 2)

      let percentageOfNextStepCompleted
      const lastCompletedStepIdx = _.findLastIndex(this.stepsActive, val => val)

      if (lastCompletedStepIdx < 0) {
        this.stepProgress = 0
      } else {
        const stepHeightInPx = this.$refs['step-2'].getBoundingClientRect().top - this.$refs['step-1'].getBoundingClientRect().top
        const maxPercentageHeight = 75
        const stepHeight = maxPercentageHeight / this.stepsActive.length
        const previousStepProgress = (lastCompletedStepIdx * stepHeight) / maxPercentageHeight * 100
        percentageOfNextStepCompleted = Math.abs(this.$refs[`step-${lastCompletedStepIdx + 1}`].getBoundingClientRect().top - window.innerHeight / 2) / stepHeightInPx

        const nextStepProgress = previousStepProgress + stepHeight * (percentageOfNextStepCompleted / maxPercentageHeight) * 100

        if (nextStepProgress > maxPercentageHeight) {
          this.stepProgress = maxPercentageHeight
        } else {
          this.stepProgress = nextStepProgress
        }
      }
    },

    getFeatureIconPath (idx) {
      return require(`@/assets/landing/feature-icon-${Number(idx) + 1}.svg`)
    },

    /**
     * Manually trigger the file opening dialog
     */
    selectFile () {
      this.$refs.upload.click()
    },

    /**
     * Handler when file input is changed
     * Check if valid file was selected. If yes, store its metadata on the vue instance
     * @param  {Object} $evt Event object
     */
    fileSelected ($evt) {
      const files = $evt.target.files || $evt.dataTransfer.files
      if (files && files.length > 0) this.quote.file = files[0]
      else this.quote.file = null
    },

    /**
     * Check if all fields of the quote calculator itself are filled. If yes, open the modal
     * where the user needs to enter his contact details
     */
    requestOffer () {
      if (this.quote.file === null) this.status.error = 'file'
      else {
        this.status.error = ''
        this.status.modal = true
        setTimeout(() => { this.$refs['quote-name'].focus() }, 200)
      }
    },

    /**
     * Submit the managed coding offer request
     */
    submit () {
      if (!this.quote.name || !this.quote.email) this.status.error = 'name_email'
      else {
        this.status.loading = true
        let data = new FormData()

        _.each(this.quote, (val, key) => data.append(key, val))
        data.append('respondents', this.nRespondents)
        data.append('cost', this.totalPrice)
        data.append('eta', this.completionTime)

        this.$api.post('/api/managed-offer-request', data, {
          timeout: 400000
        }).then((res) => {
          this.status.error = ''
          this.status.success = true
          // this.status.modal = false
          this.status.loading = false
        }).catch(err => {
          this.status.success = false
          this.status.error = 'request'
          this.status.loading = false
          console.error(err)
        })
      }
    },

    /**
     * Close the submit offer dialog (ignore if we're currently loading)
     */
    closeSubmitDialog () {
      if (this.status.loading) return

      this.status.modal = false
    }
  }
}

</script>

<style lang=scss>
  @import "vue-select/src/scss/vue-select.scss";
</style>

<i18n locale='en' src='@/i18n/en/landing/Managed.json' />
<i18n locale='de' src='@/i18n/de/landing/Managed.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />