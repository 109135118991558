<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/cx.png"
              class="case-study__logo"
              alt="CX.AI"
              style="height: 30px;"
            >
            <h2 class="case-study__title">
              An insight company doubles its revenue, by tapping into Caplena’s API.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <!-- TODO: CHANGE THIS INTO PROPER IMAGE -->
              <img class="mr-2" src="@/assets/landing/testimonial_frank.jpg" alt="Frank">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Frank Buckler
                </div>
                <div class="case-study__person__position">
                  CEO & Founder @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.success-drivers.com/"
                  >
                    Success Drivers
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              <div>
                Success Drivers is an innovative insights consulting firm. It provides bespoke, cutting-edge solutions for decision makers and their teams.
              </div>
              <div>
                One of its strongest and most impactful products is CX.ai, a powerful text-analytics suite, dashboard and simulation tool. At its core CX.ai is fed with pairs of open-ended verbatims and their NPS score to produce actionable insights into which topics are relevant to the given business question.
              </div>
            </div>
            <img
              src="@/assets/landing/case-studies/cx/hero.jpg"
              class="case-study__image"
              alt="Cx-ai"
            >
            <label class="case-study__image-text" />
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Frank Buckler (CEO of Success Drivers) was always aware that in order to achieve compelling and insightful results with CX.ai, the tool needed a strong and reliable partner. The main challenge was to find a best-in-class survey-coding platform that could easily be accessed through an API.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              Frank and his team, after evaluating different supervised NLP providers, chose Caplena to become this crucial component.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Frank, which other software did you consider before choosing Caplena?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Frank (Success Drivers)
                </div>
                <div class="case-study__conversation__answer__text">
                  We tried different Text-Analytics tools such as Codeit, Thematic and Gavagai.
                </div>
              </div>
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Frank, why did you choose Caplena to power your CX.AI product suite?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Frank (Success Drivers)
                </div>
                <div class="case-study__conversation__answer__text">
                  After evaluating a number of commercial and open-source solutions we found Caplena, which outperformed all alternatives by a mile. We now use Caplena to perform insightful and automated key driver analysis on NPS surveys for our clients and can wholly recommend it.
                </div>
              </div>
            </div>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              CX.ai taps into Caplena’s natural language processing API service and then applies causal machine learning algorithms to uncover hidden, incredibly granular insights that can predictably improve the customer experience. Its wide adoptions and popularity heavily contribute to the growth of Success Drivers, more precisely it led to doubling the revenue.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  What are the main benefits using Caplena’s API service?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Frank (Success Drivers)
                </div>
                <div class="case-study__conversation__answer__text">
                  The main benefit is that we received the highest explanation power in our driver-analysis with Caplena, compared to other Text Analysis providers. Combined with a very high API service availability, we are able to serve our customers smoothly and with the largest possible value-add. And of course, Caplena’s hands-on mentality and extremely quick response times are highly appreciated.
                </div>
              </div>
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  What would you recommend to other entrepreneurs who are trying to build products that rely on 3rd party solutions?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Frank (Success Drivers)
                </div>
                <div class="case-study__conversation__answer__text">
                  Don’t waste time by trying to come up with the perfect setup. Instead, iterate. Start with manual data transfers from and to your 3rd party provider and then automate step by step.
                </div>
              </div>
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Can we catch a glimpse of CX.ai?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Frank (Success Drivers)
                </div>
                <div class="case-study__conversation__answer__text">
                  Sure. Here are some screenshots from our CX.ai dashboard.
                </div>
              </div>

              <img
                src="@/assets/landing/case-studies/cx/1.jpg"
                class="case-study__image"
                alt="Cx-ai"
              >
              <img
                src="@/assets/landing/case-studies/cx/2.jpg"
                class="case-study__image mt-25"
                alt="Cx-ai"
              >
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                CX
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Success Drivers is an innovative insights consulting firm. It provides bespoke, cutting-edge solutions for decision makers and their teams.
                One of its strongest and most impactful products is CX.ai, a powerful text-analytics suite, dashboard and simulation tool. At its core CX.ai is fed with pairs of open-ended verbatims and their NPS score to produce actionable insights into which topics are relevant to the given business question.
              </div>
              <div class="case-study__company__subtitle">
                Users on Caplena
              </div>
              <div class="case-study__company__text">
                5
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                June 2018
              </div>
              <div class="case-study__company__subtitle">
                Verbatims analyzed
              </div>
              <div class="case-study__company__text">
                2.6M
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                English, German, Spanish, French, Japanese and 64 more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />