<template>
  <div class="col-12 order-md-7">
    <div class="features__banner d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <img
          class="features__banner__image mr-2"
          src="@/assets/landing/features-banner.svg"
          alt="Features"
        >
        <div class="ml-2">
          <div class="features__banner__title">
            {{ $t('features_banner.title') }}
          </div>
          <div class="features__banner__text">
            {{ $t('features_banner.text') }}
          </div>
        </div>
      </div>
      <a
        :href="`/${$i18n.locale}/features/`"
        class="btn btn-primary btn-green"
      >
        {{ $t('features_banner.btn') }}
        <arrow-right :size="19" />
      </a>
    </div>
  </div>
</template>

<script>

import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'

export default {
  components: {
    ArrowRight
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}

</script>

<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />