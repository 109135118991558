<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/case-studies/flixbus/logo.svg?data"
              class="case-study__logo"
              style="height: 25px;"
              alt="flixbus"
            >
            <h2 class="case-study__title">
              With Caplena as a guiding partner, FlixBus navigated the
              complexities of passenger feedback, transforming insights into actionable strategies for tangible
              improvements.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img
                class="mr-2"
                src="@/assets/landing/case-studies/flixbus/tal.jpg"
                alt="Tal"
                style="object-fit: cover;"
              >
              <div class="ml-2">
                <div class="case-study__person__name">
                  Tal Schechter
                </div>
                <div class="case-study__person__position">
                  Head of Operations Tools & Systems
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.flixbus.com/"
                  >
                    FlixBus
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              Amid the evolving global mobility landscape, FlixBus shines as a Smart, Green, and Accessible
              travel icon. With a vision of value-driven journeys worldwide, FlixBus leads intercity
              transportation. This case study reveals how Caplena's AI powered text analytics uplifted
              operations and passenger contentment at Flix.
              <br>
              <br>
              Fueled by a mission of Smart and Green Mobility, FlixBus operates across continents, with 5500+
              destinations serving 60+ million yearly. Beyond transport, FlixBus offers a path to world
              exploration, blending convenience, comfort, and sustainability.
            </div>
            <div
              class="position-relative hover w-100"
              @click="toggleVideo('https://www.youtube.com/embed/voRVVH3q0Gs?si=ZeKTaD9bdJnboNGi')"
            >
              <img
                src="@/assets/landing/case-studies/flixbus/hero.jpg"
                class="case-study__image"
                alt="England rugby"
                style="max-height: 400px; object-position: top;"
              >
              <button class="play play--subtle absolute-center flex-center-center hover">
                <play-icon :size="35" />
              </button>
            </div>
            <label class="case-study__image-text" />

            <h5 class="case-study__title">
              Analyzing the Voice of the Customer (VoC)
            </h5>
            <div class="case-study__text">
              FlixBus recognizes that there is no way to delivering true operational excellence without
              understanding passenger sentiments and preferences. The VoC survey conducted after a Flixbus
              journey is a crucial source of data for gaining valuable insights into passenger experiences,
              opinions, and expectations. However, three core challenges were faced:
            </div>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">1.</span> <b>High Volume: </b> With over 600,000 open-ended responses yearly, manual analysis was impractical and expensive.
                </div>
                <div class="mt-1">
                  <span class="mr-2">2.</span> <b>Diverse Languages: </b> Operating in 40+ countries necessitated multilingual analysis.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">3.</span> <b>Bias Elimination: </b> Accurate insights required an objective approach to avoid bias and questionnaire fatigue.
                </div>
              </div>
            </div>
            <img
              src="@/assets/landing/case-studies/flixbus/img-2.jpg"
              class="case-study__image"
              alt="Flixbus"
            >
            <h5 class="case-study__title">
              Solution: Caplena's Impactful Role
            </h5>
            <div class="case-study__text">
              FlixBus discovered a dynamic problem-solving partner in Caplena, an AI powered platform that
              not only tackled their challenges head-on but also elevated their entire analysis process. The
              pivotal strides taken by FlixBus with Caplena to harness the potential of their customer feedback data are as follows:
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">1.</span> <b>Topic Identification: </b> Caplena helps pinpoint & refine themes in an interactive fashion from a large volume of responses.
                </div>
                <div class="mt-1">
                  <span class="mr-2">2.</span> <b>Quality Assurance: </b> Caplena uses the F1 score to assess the quality of the AI’s topic
                  assignments. This helps improve the AI's understanding of industry-specific jargon and context
                  through human-annotated inputs.
                </div>
                <div class="mt-1">
                  <span class="mr-2">3.</span> <b>Sentiment Analysis: </b> Caplena reveals sentiments at the topic level, even when within the same
                  response. This advanced approach reveals emotional nuances that might otherwise go unnoticed,
                  painting a comprehensive picture of customer sentiments.
                </div>
                <div class="mt-1">
                  <span class="mr-2">4.</span> <b>Dashboarding & Reporting: </b> Caplena furnishes real-time, interactive dashboards that empower
                  informed decision-making across the organization, ensuring transparency and accessibility.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">5.</span> <b>Insight Generation: </b> Caplena continuously runs advanced analyses such as correlations and
                  driver analysis on the data, leading to more profound insights and proactive enhancements.
                </div>
              </div>
            </div>

            <div class="case-study__quote">
              Caplena is our invaluable partner in comprehending
              the voices of our passengers across the globe. With the
              capacity to manage vast volumes of open-ended
              responses in multiple languages, it auto-categorizes
              topics, providing insights into aspects like driver
              behavior, punctuality, and hygiene, while maintaining
              neutrality and objectivity. This empowers us to pinpoint
              areas for improvement without biases, ultimately
              enhancing our operations and customer experiences.
            </div>

            <h5 class="case-study__title">
              Transformational Results
            </h5>

            <div class="case-study__text">
              When FlixBus acquired Greyhound, the largest provider of intercity bus transportation in North
              America, a significant challenge emerged—harmonizing the feedback analysis process.
              Greyhound alone contributed 100K comments for analysis on top of the 500K comments from
              FlixBus. FlixBus aimed for a globally scalable solution to accommodate its expanded operations.
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">*</span> <b>Caplena's Integration: </b> With Caplena's assistance, FlixBus successfully integrated Greyhound's
                  systems and streamlined its feedback analysis processes.
                </div>
                <div class="mt-1">
                  <span class="mr-2">*</span> <b>Uncovering Trouble Areas: </b> Caplena swiftly highlighted areas for improvement such as driver
                  helpfulness or communication of delays.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">*</span> <b>Strategic Improvement: </b> FlixBus immediately acted on the insights, leading to substantial
                  improvements, including a 38% increase in punctuality, 36% in general cleanliness, and a 52%
                  boost in communication of delays, all within four months.
                </div>
              </div>
            </div>

            <div class="case-study__text">
              The impact of FlixBus's dedication to passenger experience was evident in the stabilizing NPS
              scores, which rose to match the previous year's figures.
            </div>

            <img
              src="@/assets/landing/case-studies/flixbus/img-1.jpg"
              class="case-study__image mb-2"
              alt="Flixbus"
            >
            <label class="case-study__image-text">
              This chart displays a four month reduction in negative sentiment comments
            </label>

            <h5 class="case-study__title mt-2">
              Conclusion
            </h5>
            <div class="case-study__text">
              FlixBus's journey towards operational excellence embodies the essence of its commitment to
              Smart, Green, and Accessible travel. With Caplena as a guiding partner, FlixBus navigated the
              complexities of passenger feedback, transforming insights into actionable strategies for tangible
              improvements. The case of FlixBus serves as a testament to the power of data-driven decisionmaking
              in redefining the passenger experience and shaping the future of intercity transportation.
              For more information on how Caplena can empower your organization's journey towards
              excellence, book a demo.
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                FlixBus
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Fueled by a mission of Smart and Green Mobility, FlixBus operates across continents, with 5500+ destinations serving 60+ million yearly. Beyond transport, FlixBus offers a path to world exploration, blending convenience, comfort, and sustainability.
              </div>
              <div class="case-study__company__subtitle">
                Projects on Caplena
              </div>
              <div class="case-study__company__text">
                Global VoC survey
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                October 2022
              </div>
              <div class="case-study__company__subtitle">
                Text comments analyzed
              </div>
              <div class="case-study__company__text">
                1.5M+
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
    <transition name="fade">
      <div class="overlay" v-if="showVideo" @click="toggleVideo" />
    </transition>
    <transition name="fade">
      <div v-if="!!showVideo" id="video" class="modal-input modal-input--video" style="height: 55vh;">
        <iframe
          width="100%"
          height="100%"
          :src="`${showVideo}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        />
      </div>
    </transition>
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'
import PlayIcon from 'vue-material-design-icons/Play.vue'

export default {
  components: {
    LandingScaffold,
    Trial,
    PlayIcon
  },

  data () {
    return {
      showVideo: false
    }
  },
  computed: {},
  mounted () {},
  methods: {
    toggleVideo (link) {
      if (this.showVideo) this.showVideo = false
      else this.showVideo = link
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />
