<template>
  <div class="helptip-landing tooltip tooltip-manual">
    <help-circle-icon
      v-if="isIcon"
      :size="size"
      title=""
    />
    <slot v-else name="icon" />
    <span class="manual-content" :style="{ left: xOffset }">
      <slot />
      <div class="manual-content__footnote" :style="{ left: xOffsetFootnote }" />
    </span>
  </div>
</template>

<script>

import HelpCircleIcon from 'vue-material-design-icons/InformationOutline.vue'

export default {
  components: {
    HelpCircleIcon
  },
  props: {
    xOffset: { type: String, default: '' },
    xOffsetFootnote: { type: String, default: '' },
    isIcon: { type: Boolean, default: true },
    size: { type: Number, default: 20 }
  }
}

</script>