<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/case-studies/rugby/logo.svg?data"
              class="case-study__logo"
              style="height: 50px;"
              alt="England rugby"
            >
            <h2 class="case-study__title">
              England Rugby use Caplena for their large-scale fan and player surveys understand the sentiment and topics that people are expressing in their long-form comments.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img
                class="mr-2"
                src="@/assets/landing/testimonial_tory.jpg"
                alt="Tory"
                style="object-position: top;"
              >
              <div class="ml-2">
                <div class="case-study__person__name">
                  Tory Clarkson
                </div>
                <div class="case-study__person__position">
                  Insights Manager @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.englandrugby.com/"
                  >
                    England Rugby
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              The Rugby Football Union is the national governing body for grassroots and professional rugby in England, with 2,000 autonomous rugby clubs in its membership. England Rugby recently won the bid to host the 2025 women's World Cup and want to use this opportunity to increase the number of people following rugby as a sport and to increase women participation, in particular. Tory Clarkson is the Insights Manager at England Rugby, who focuses on  the community game and the women's game, from the grassroot players to the professionals.
            </div>
            <div
              class="position-relative hover w-100"
              @click="toggleVideo('https://www.youtube.com/embed/DoZlKQMrhC0')"
            >
              <img
                src="@/assets/landing/case-studies/rugby/thumbnail.jpg"
                class="case-study__image"
                alt="England rugby"
                style="max-height: 400px; object-position: top;"
              >
              <button class="play play--subtle absolute-center flex-center-center hover">
                <play-icon :size="35" />
              </button>
            </div>
            <label class="case-study__image-text" />

            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Each year, England Rugby prepares a national rugby survey for approximately 7,000 players and volunteers. The Union looks to understand their member's experiences, to measure KPIs and to uncover challenges and opportunities that member's face. The annual survey takes about 10 minutes to complete and consists of various styled questions, including multiple-choice and open-ended questions. In the past, the issue for England Rugby was that this process was analyzed manually, which was too time-consuming and resulted in being a wasteful activity for both the surveyor and the participant as the vast amount of data could not be analyzed effectively.
            </div>
            <div class="case-study__quote">
              Rugby as a sport is full of jargon so some of our terms aren't recognizable at first glance to a computer. We can chuck them into this [Caplena] platform and just make little tweaks so that it picks up things like tries, points, conversions, COVID, and rugby and it does all that seamlessly. That's a real advantage for us.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              Understanding the importance of member survey responses, in early 2022, England Rugby invested in Caplena as their feedback analysis platform. Since its first survey, Caplena's AI has become faster and more accurate at categorizing the feedback as it continuously learns and adapts to the rugby jargon and the unique target group.
            </div>
            <div class="case-study__quote">
              One of the advantages of using Caplena is that we're able to automate a lot of our data processing but also have a level of manual quality control.
            </div>

            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              With Caplena, England Rugby can now understand its members better. Instead of wasting resources on manual analysis, Tory and her team can focus on how to address the wants and needs of its members. Without addressing input from its members, England Rugby would struggle to retain and attract new members to the organization. By listening to its members effectively and implementing the feedback analysis in their strategy, Caplena enables England Rugby to improve as an organization overall.
            </div>
            <div class="case-study__quote">
              We're really proud to say that we've won the bid to host the 2025 women's World Cup. A huge amount of data and insights went into that bid, we want to use that World Cup to grow the number of people following rugby and women's rugby, grow the number of women following rugby, and grow the number of women and girls playing rugby as well, so we've used data from our ticketing data, survey data, CRM data to understand the motivators, the barriers and the potential market size for women's rugby in England and how we can use that information to most effectively host this tournament
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                England Rugby
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                The Rugby Football Union (RFU) annually conducts a survey of 7,000 players and volunteers to gather insights. The 10-minute survey features multiple-choice and open-ended questions, and the manual analysis process was previously slow and ineffective. But, Caplena came to the rescue!
              </div>
              <div class="case-study__company__subtitle">
                Projects on Caplena
              </div>
              <div class="case-study__company__text">
                9
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                Nov 2021
              </div>
              <div class="case-study__company__subtitle">
                Text comments analyzed
              </div>
              <div class="case-study__company__text">
                90'000
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
    <transition name="fade">
      <div class="overlay" v-if="showVideo" @click="toggleVideo" />
    </transition>
    <transition name="fade">
      <div v-if="!!showVideo" id="video" class="modal-input modal-input--video" style="height: 55vh;">
        <iframe
          width="100%"
          height="100%"
          :src="`${showVideo}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        />
      </div>
    </transition>
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'
import PlayIcon from 'vue-material-design-icons/Play.vue'

export default {
  components: {
    LandingScaffold,
    Trial,
    PlayIcon
  },

  data () {
    return {
      showVideo: false
    }
  },
  computed: {},
  mounted () {},
  methods: {
    toggleVideo (link) {
      if (this.showVideo) this.showVideo = false
      else this.showVideo = link
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />
