<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/miro.svg"
              class="case-study__logo"
              alt="Miro Logo"
            >
            <h2 class="case-study__title">
              Caplena centralizes all of Miro's user feedback from CX collection systems, review platforms and in-house data warehouse systems into a single platform.
            </h2>

            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/case-studies/miro/lisa.jpg" alt="Lisa">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Lisa Knowles
                </div>
                <div class="case-study__person__position">
                  User Insight Analytics Manager @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.miro.com/"
                  >
                    Miro
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Miro, a thriving collaborative whiteboarding platform with a user base of almost 60 million, is highly acclaimed. As a product-led company, Miro understands the importance of timely feature development to stay ahead of the competition. To achieve this, they have recognized the need to streamline their process for analyzing user feedback in order to plan their strategic roadmap effectively. Their goal is to establish a systematic connection between their business objectives, particularly their focus on "happy customers," and user feedback. With feedback pouring in from 10K+ text responses from 12 different data sources, Miro faces the challenge of harnessing this wealth of information. Their main objective is to unlock valuable insights, identify emerging trends, establish a clear categorization system, and efficiently distribute relevant data to their dedicated teams for further analysis. This ambitious undertaking demonstrates Miro's commitment to enhancing their platform and ensuring a seamless user experience. With the help of Caplena's comprehensive platform, Miro can effectively address the challenge of managing the large volume of feedback received from various sources.
            </div>
            <div
              class="position-relative hover w-100"
              @click="toggleVideo('https://www.youtube.com/embed/vRX4wLM_acU')"
            >
              <img
                src="@/assets/landing/case-studies/miro/lisa-video.png"
                class="case-study__image w-100"
                alt="Miro's Lisa"
                style="object-position: top; height: auto; margin-bottom: 20px;"
              >
              <button class="play play--subtle absolute-center flex-center-center hover">
                <play-icon :size="35" />
              </button>
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <h5 class="mr-auto ml-auto text-center" style="font-size: 17px;">
              Centralized User Feedback Engine
            </h5>
            <div class="case-study__text">
              Caplena centralizes all of Miro's user feedback from CX collection systems, review platforms and in-house data warehouse systems into a single platform. Caplena also offers direct integrations to some of these platforms, to further integrate and automate the workflow. This powerful data aggregation capability guarantees that no feedback is overlooked, resulting in a streamlined feedback management process.
            </div>
            <img
              src="@/assets/landing/case-studies/miro/engine.jpg"
              class="case-study__image"
              alt="Miro"
            >
            <label class="case-study__image-text" />

            <h5 class="mr-auto ml-auto text-center" style="font-size: 17px;">
              Customizable Topic Collection and AI Quality Score
            </h5>
            <div class="case-study__text">
              What truly stands out to Miro is Caplena's customizable topic collection, which is neatly packaged in an intuitive user interface. Miro's product is complex, requiring the ability to create custom tags and being able to fine-tune the system based on contextual understanding rather than relying solely on keywords. Caplena's flexibility in tailoring the categorisation process to Miro's unique requirements plays a crucial role in accurately and effectively comprehending user feedback. Additionally, Caplena provides transparent AI quality scores, also known as F1 Score, ensuring a reliable and accurate analysis.
            </div>

            <h5 class="mr-auto ml-auto text-center" style="font-size: 17px;">
              Topic Collection and AI Quality Score (F1 Score)
            </h5>
            <div class="case-study__text">
              Through the dynamic and interactive topic detection process, Miro identified and defined over 100 company-specific themes within categories as their topic collection.
            </div>

            <div class="d-flex w-100">
              <img
                src="@/assets/landing/case-studies/miro/collection-1.png"
                class="case-study__image mr-1"
                alt="Miro"
                style="object-fit: contain; width: 50%;"
              >
              <img
                src="@/assets/landing/case-studies/miro/collection-2.jpg"
                class="case-study__image ml-1"
                alt="Miro"
                style="object-fit: contain; width: 50%;"
              >
            </div>

            <div class="case-study__text" />
            <div class="case-study__quote">
              What truly stands out for us with Caplena is the customizable topic collection and the user interface that allows us to fine-tune the coding process. It's incredibly easy to use and update the data on a regular basis, as we continually update and improve our product. This ensures that we always stay at the forefront of providing the best user experience and cutting-edge features to our customers.
            </div>

            <h5 class="mr-auto ml-auto text-center" style="font-size: 17px;">
              Interactive Dashboards
            </h5>
            <div class="case-study__text">
              Caplena's interactive dashboards are invaluable for gaining actionable insights from collected feedback at Miro. They allow users to explore feedback categories, segment data based on various criteria, and generate reports for important stakeholders. Additionally, the dashboards provide real-time querying capabilities, enabling interactive exploration of the results. This combination of features empowers users to delve deeper into specific trends and achieve a comprehensive understanding of the feedback.
            </div>
            <img
              src="@/assets/landing/case-studies/miro/dashboards.jpg"
              class="case-study__image"
              alt="Miro"
            >
            <label class="case-study__image-text">
              The insights derived from the analysis are effectively shared with Miro's management team through comprehensive dashboards, aiding in the strategic shaping of Miro's roadmap.
            </label>

            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              Caplena's solution delivers remarkable results for Miro. The ease of use and the capability to create a custom topic collection are particularly impactful. The insights team at Miro leverages Caplena's capabilities to define over 100 Miro-specific themes to-date, which are then quantified into meaningful categories. This approach provides a holistic picture of customer feedback and enables Miro's management to make informed decisions to improve customer satisfaction.
            </div>
            <div class="case-study__text">
              Miro has implemented a structured process to bring the insights back to the business, comprised of four steps:
              <div class="case-study__conversation mb-0">
                <div class="case-study__text">
                  <div class="mt-2">
                    <span class="mr-2">1.</span> <b>Yearly Strategic Insights Report: </b> These reports provide a comprehensive overview of strategic insights and are shared exclusively with top-level executives. The report is carefully prepared, extracting key insights and presenting them in a concise and visually appealing format.
                  </div>
                  <div class="mt-1">
                    <span class="mr-2">2.</span> <b>Quartal Reports: </b> These reports focus on addressing customer feedback related to bugs and feature requests. They are also prepared meticulously, but in a more standardized format. The reports are shared specifically with the product teams responsible for addressing these issues.
                  </div>
                  <div class="mb-2 mt-1">
                    <span class="mr-2">3. </span> <b>Monthly Trends and Pulse Checks: </b> To ensure regular monitoring of performance and gather insights, Miro utilizes dashboards. These dashboards allow teams from various departments and levels of the organization to access and segment information based on their respective areas of expertise.
                  </div>
                  <div class="mb-2 mt-1">
                    <span class="mr-2">4. </span> <b>Live Dashboards: </b> Product managers and product experience managers have access to live dashboards that enable them to retrieve insights on a daily basis. These dashboards serve as a resource for pulling out valuable information without the direct involvement of the insights team.
                  </div>
                </div>
              </div>
              This feedback process demonstrates a combination of push and pull approaches. On one hand, detailed reports created by the insights team are pushed to other segments of the company. On the other hand, there are also ongoing resources available where individuals in different hierarchies can independently access insights whenever needed.
            </div>
            <div class="case-study__text">
              <div class="case-study__conversation">
                One notable real-life example of how this feedback process successfully identified and addressed a product issue occurred during the quarterly reports. Through the driver analysis conducted by Miro's insights team, the product teams were able to take immediate action. They identified app performance as a significant driver in platform experience and further examination revealed a correlation between performance and heavy use of the product. This valuable insight prompted Miro to quickly address the issue and make necessary improvements to improve the user experience.
                <br><br>
                Insights like these are invaluable for Miro as they enable the effective refinement of their roadmap, prioritizing improvements that directly address their customers' most pressing concerns. By leveraging the feedback process, Miro can continuously improve their product and enhance customer satisfaction.
              </div>
              Caplena's seamless integration into Miro's feedback analysis workflow allows for easy updates to the data as the product evolves. Whether it requires adding additional tags or combining existing ones, Miro finds it effortless to update the system and ensure that the feedback analysis remains accurate and up to date. This enables Miro to strategically prioritize the right features, which is crucial for a product-led company.
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Miro
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Miro.com is a versatile whiteboarding platform that goes beyond brainstorming. It facilitates collaboration for tasks like workshopping, strategy mapping, UX research, product development, customer discovery, and process visualization. With its intuitive interface, Miro empowers teams to collaborate seamlessly and drive innovation effectively.
              </div>
              <div class="case-study__company__subtitle">
                Users on Caplena
              </div>
              <div class="case-study__company__text">
                67
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                2022-04-21
              </div>
              <div class="case-study__company__subtitle">
                Verbatims analyzed
              </div>
              <div class="case-study__company__text">
                300k
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                50+ languages
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
    <transition name="fade">
      <div class="overlay" v-if="showVideo" @click="toggleVideo" />
    </transition>
    <transition name="fade">
      <div v-if="!!showVideo" id="video" class="modal-input modal-input--video" style="height: 55vh;">
        <iframe
          width="100%"
          height="100%"
          :src="`${showVideo}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        />
      </div>
    </transition>
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'
import PlayIcon from 'vue-material-design-icons/Play.vue'

export default {
  components: {
    LandingScaffold,
    Trial,
    PlayIcon
  },

  data () {
    return {
      showVideo: false
    }
  },
  computed: {},
  mounted () {},
  methods: {
    toggleVideo (link) {
      if (this.showVideo) this.showVideo = false
      else this.showVideo = link
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />