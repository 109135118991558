<template>
  <landing-scaffold class="sales-page consultants-page">
    <section class="hero hero-text overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-5 col-md-12">
            <h1 v-html="$t('hero.h1')" />
            <div class="hero-text__subtitle" v-html="$t('hero.subtitle')" />
            <div class="hero-text__btns-containers">
              <div class="d-flex">
                <a class="btn btn-primary btn-green mr-2" :href="`/${$i18n.locale}/register/`" v-text="$t('cta.book_demo')" />
              </div>
              <div class="d-flex">
                <div class="d-flex align-center check-text">
                  <check :size="17" />
                  {{ $t('trial.trial') }}
                </div>
                <div class="d-flex align-center check-text">
                  <check :size="17" />
                  {{ $t('trial.no_credit_card') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 col-md-0" />
          <div class="col-6 col-md-12 align-center d-flex justify-center position-relative">
            <!-- <img src="@/assets/landing/empower-consultants.svg" alt="Empower consultants hero" class="w-100"> -->
            <iframe
              v-if="!isPrerendering"
              loading="lazy"
              class="sales-page__video"
              src="https://www.youtube-nocookie.com/embed/7mS3BUc9dQI"
              title="Caplena for consultants"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute hide-md">
        </div>
      </div>
    </section>
    <section class="section-testimonials pt-0 light-blue">
      <div class="container">
        <div class="text-center">
          <div class="column col-12 text-grey text-uppercase logo-text">
            {{ $t('hero.trusted') }}
          </div>
          <div class="logos d-flex flex-wrap">
            <a :href="`/en/case-studies/factworks`" class="logo-holder logo-holder--link">
              <img src="@/assets/landing/customer_logos/factworks_dark.svg?data" alt="Factworks" style="height: 30px;">
            </a>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/swisscom.svg?data" alt="Swisscom" style="height: 50px;">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/ebay_dark.svg?data" style="height: 30px;" alt="Ebay">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/dhl.svg?data" alt="DHL" style="height: 20px;">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/link.svg" style="height: 22px;" alt="Link institut">
            </div>
            <div class="logo-holder">
              <img src="@/assets/landing/customer_logos/miele.svg?data" style="height: 35px;" alt="Miele">
            </div>
          </div>
        </div>
        <div class="testimonial column col-11 ml-auto mr-auto">
          <div class="d-flex">
            <img
              src="@/assets/landing/case-studies/cx/hero.jpg"
              class="testimonial__image"
              alt="Cx-Ai"
            >
            <div>
              <img
                src="@/assets/landing/customer_logos/cx.png"
                class="testimonial__logo"
                alt="CX Logo"
              >
              <div class="testimonial__text">
                {{ $t('testimonial.text') }}
              </div>
              <div class="d-flex align-center testimonial-author">
                <div class="img-holder flex-center-center">
                  <img src="@/assets/landing/testimonial_frank.jpg" alt="Pia">
                </div>
                <div class="subtitle">
                  <span>Frank Buckler</span>
                  CEO & FOUNDER @ <label>SUCCESS DRIVERS</label>
                </div>
              </div>
              <div class="testimonial__stats">
                <div class="testimonial__stats__stat-container">
                  <div class="testimonial__stats__stat">
                    100%
                  </div>
                  <div class="testimonial__stats__label">
                    {{ $t('testimonial.stat_1') }}
                  </div>
                </div>
                <div class="testimonial__stats__stat-container">
                  <div class="testimonial__stats__stat">
                    69
                  </div>
                  <div class="testimonial__stats__label">
                    {{ $t('testimonial.stat_2') }}
                  </div>
                </div>
                <div class="testimonial__stats__stat-container">
                  <div class="testimonial__stats__stat">
                    2.6M
                  </div>
                  <div class="testimonial__stats__label">
                    {{ $t('testimonial.stat_3') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-phone pb-0 position-relative">
      <div class="container d-flex flex-column align-center justify-center">
        <h2>{{ $t('phone.title') }}</h2>
        <div class="section-phone__subtitle">
          {{ $t('phone.subtitle') }}
        </div>
        <div class="d-flex justify-center align-center section-phone__match section-phone__match--consultants">
          <div class="d-flex flex-column justify-center align-center">
            <img
              src="@/assets/landing/match-logo.svg"
              alt="Caplena match logo"
              class="section-phone__match__image"
            >
            <div class="section-phone__match__entity">
              Caplena
            </div>
          </div>
          <div class="d-flex flex-column justify-center align-center">
            <img
              src="@/assets/landing/consultant.svg"
              alt="Market researchers match image"
              class="section-phone__match__image"
            >
            <div class="section-phone__match__entity section-phone__match__entity--cs">
              {{ $t('phone.match') }}
            </div>
          </div>
        </div>
        <img
          v-if="$i18n.locale === 'en'"
          src="@/assets/landing/consulting-phone-en.svg"
          alt="Phone conversation"
          class="section-phone__image"
        >
        <img
          v-if="$i18n.locale === 'de'"
          src="@/assets/landing/consulting-phone-de.svg"
          alt="Phone conversation"
          class="section-phone__image"
        >
      </div>
      <img
        src="@/assets/landing/sales-arrow.png"
        alt="pointing-arrow"
        class="why-arrow"
        style="left: 20%; bottom: -13%; top: auto; transform: scaleX(-1);"
      >
    </section>
    <section class="section-for-you position-relative">
      <div class="container">
        <h2>{{ $t('for_you.title') }}</h2>
        <div class="columns for-you-list">
          <div class="col-6 col-md-12 d-flex align-center">
            <img src="@/assets/landing/for-consultants.svg" alt="consultant" class="w-100">
          </div>
          <div class="col-6 col-md-12">
            <div
              v-for="(item, index) in $t('for_you.list')"
              :key="index"
              class="for-you-list__item d-flex"
            >
              <list-check class="for-you-list__item__check mt-2" :size="24" />
              <div>
                <div class="for-you-list__item__text" v-html="item.text" />
                <div v-if="item.points" class="for-you-list__item__points" v-html="item.points" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="@/assets/landing/sales-arrow.png"
        alt="pointing-arrow"
        class="why-arrow"
        style="left: 30%; bottom: -13%; top: auto; transform: scaleX(-1);"
      >
    </section>
    <section class="section-why-it-works position-relative">
      <div class="container">
        <h2 class="text-center" v-html=" $t('why_works.title')" />
        <div class="why-text text-center" v-html="$t('why_works.text')" />
        <h2 class="text-center" v-html=" $t('why_works.subtitle')" />
      </div>
      <img src="@/assets/landing/clouds.svg" alt="clouds image" class="why-clouds">
    </section>
    <section class="section-take-aways">
      <div class="container">
        <div class="columns take-aways-container">
          <div class="col-5 col-md-12 take-away-links">
            <div
              v-for="(linkItem, index) in $t('take_aways.list')"
              :key="index"
              class="take-away-link"
              :class="takeAwayActiveIndex === Number(index) && 'take-away-link--active'"
              @click="setTakeAwayActiveIndex(Number(index))"
            >
              <div class="take-away-link__label" v-html="linkItem.link.label" />
              <div class="take-away-link__title" v-html="linkItem.link.title" />
              <div class="take-away-link__text" v-html="linkItem.link.text" />
            </div>
          </div>
          <div class="col-7 col-md-12 take-away-container">
            <div
              class="take-away take-away--1"
              :class="takeAwayActiveIndex === 0 && 'take-away--active'"
            >
              <div class="columns container h-100">
                <div class="d-flex w-100">
                  <div class="col-5 take-away__cell take-away__cell--first">
                    <div class="take-away__label" v-html="$t('take_aways.list[0].card.column_1.label')" />
                  </div>
                  <div class="col-7 take-away__cell">
                    <div class="take-away__label" v-html="$t('take_aways.list[0].card.column_2.label')" />
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="col-5 take-away__cell take-away__cell--first align-center">
                    <img
                      src="@/assets/landing/ma-analysts.svg"
                      class="take-away__image"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[0].card.column_1.analysts')" />
                  </div>
                  <div class="col-7 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[0].card.column_2.analysts')" />
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="col-5 take-away__cell take-away__cell--first align-center">
                    <img
                      src="@/assets/landing/strategy-consultants.svg"
                      class="take-away__image"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[0].card.column_1.consultants')" />
                  </div>
                  <div class="col-7 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[0].card.column_2.consultants')" />
                  </div>
                </div>
                <div class="d-flex w-100">
                  <div class="col-5 take-away__cell take-away__cell--first align-center">
                    <img
                      src="@/assets/landing/insight-teams.svg"
                      class="take-away__image"
                      alt="research buyers image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[0].card.column_1.teams')" />
                  </div>
                  <div class="col-7 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[0].card.column_2.teams')" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="take-away take-away--2"
              :class="takeAwayActiveIndex === 1 && 'take-away--active'"
            >
              <div class="columns container">
                <div class="d-flex w-100">
                  <div class="col-3 take-away__cell take-away__cell--first" />
                  <div class="col-4 take-away__cell take-away__cell--second">
                    <div class="take-away__label" v-html="$t('take_aways.list[1].card.column_2.label')" />
                  </div>
                  <div class="col-5 take-away__cell">
                    <div class="take-away__label" v-html="$t('take_aways.list[1].card.column_3.label')" />
                  </div>
                </div>

                <div class="d-flex w-100">
                  <div class="col-3 take-away__cell take-away__cell--first">
                    <img
                      src="@/assets/landing/pull-reviews.svg"
                      class="take-away__image"
                      alt="pull reviews image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[1].card.column_1.reviews')" />
                  </div>
                  <div class="col-4 take-away__cell take-away__cell--second">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_2.reviews')" />
                  </div>
                  <div class="col-5 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_3.reviews')" />
                  </div>
                </div>

                <div class="d-flex w-100">
                  <div class="col-3 take-away__cell take-away__cell--first">
                    <img
                      src="@/assets/landing/extract-results.svg"
                      class="take-away__image"
                      alt="extract results image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[1].card.column_1.results')" />
                  </div>
                  <div class="col-4 take-away__cell take-away__cell--second">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_2.results')" />
                  </div>
                  <div class="col-5 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_3.results')" />
                  </div>
                </div>

                <div class="d-flex w-100">
                  <div class="col-3 take-away__cell take-away__cell--first">
                    <img
                      src="@/assets/landing/generate-insights.svg"
                      class="take-away__image"
                      alt="generate insights image"
                    >
                    <div class="take-away__text take-away__text--large" v-html="$t('take_aways.list[1].card.column_1.insights')" />
                  </div>
                  <div class="col-4 take-away__cell take-away__cell--second">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_2.insights')" />
                  </div>
                  <div class="col-5 take-away__cell">
                    <ol class="take-away__text" v-html="$t('take_aways.list[1].card.column_3.insights')" />
                  </div>
                </div>
              </div>
              <div class="take-away__footnotes" v-html="$t('take_aways.list[1].card.footnotes')" />
            </div>
            <div
              class="take-away take-away--3 d-flex justify-content-center align-center"
              :class="takeAwayActiveIndex === 2 && 'take-away--active'"
            >
              <div class="columns container">
                <div class="col-6 justify-center align-center d-flex flex-column">
                  <div class="take-away__circle take-away__circle--1">
                    <img src="@/assets/landing/consult-circle-1.svg?data" alt="Better insights">
                    <div class="take-away__circle__text" v-html="$t('take_aways.list[2].card.circle_1')" />
                  </div>
                  <img
                    src="@/assets/landing/consult-arrows.svg?data"
                    class="take-away__arrows"
                    alt="Circle arrows"
                  >
                  <div class="take-away__circle take-away__circle--2">
                    <img src="@/assets/landing/consult-circle-2.svg?data" alt="Stronger advice">
                    <div class="take-away__circle__text" v-html="$t('take_aways.list[2].card.circle_2')" />
                  </div>
                  <img
                    src="@/assets/landing/consult-arrows.svg?data"
                    class="take-away__arrows"
                    alt="Circle arrows"
                  >
                  <div class="take-away__circle take-away__circle--3">
                    <img src="@/assets/landing/consult-circle-3.svg?data" alt="More opportunities">
                    <div class="take-away__circle__text" v-html="$t('take_aways.list[2].card.circle_3')" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="take-away__circle-text">
                    <ol class="take-away__text" v-html="$t('take_aways.list[2].card.circle_1_text')" />
                  </div>
                  <div class="take-away__circle-text">
                    <ol class="take-away__text" v-html="$t('take_aways.list[2].card.circle_2_text')" />
                  </div>
                  <div class="take-away__circle-text pb-0">
                    <ol class="take-away__text" v-html="$t('take_aways.list[2].card.circle_3_text')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <comparison-table />
    <section class="section-cases case-studies-container">
      <h3 class="text-center">
        {{ $t('cases.title') }}
      </h3>
      <div class="container">
        <div class="columns case-study-list">
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/factworks`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/factworks.svg"
                    class="case-study__logo"
                    alt="Factworks Logo"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.factworks') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/joyn`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/joyn_dark.svg"
                    class="case-study__logo"
                    alt="Joyn"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.joyn') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-4 col-md-12 case-study-container">
            <a
              :href="`/${$i18n.locale}/case-studies/link`"
              class="case-study w-100 h-100 d-flex flex-column"
            >
              <div class="d-flex flex-column case-study__content-container">
                <div>
                  <img
                    src="@/assets/landing/customer_logos/link_dark.svg"
                    class="case-study__logo"
                    alt="Link Institut"
                    style="height: 20px;"
                  >
                  <div class="case-study__text">
                    {{ $t('use_cases.link') }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="section-use-cases">
      <div class="container">
        <h3 class="text-center">
          {{ $t('use_cases.title') }}
        </h3>
        <div class="columns">
          <div class="column col-4 col-md-12 use-cases-container">
            <a
              class="use-case-item d-flex align-center"
              :href="`/${$i18n.locale}/use-cases/review-analysis`"
            >
              <div>
                <div class="use-case-item__title d-flex align-center">
                  {{ $t('review.title') }}
                  <arrow-right class="use-case-item__arrow" :size="16" />
                </div>
                <div class="use-case-item__text">
                  {{ $t('review.subtitle') }}
                </div>
              </div>
            </a>
          </div>
          <div class="column col-4 col-md-12 use-cases-container">
            <a
              :href="`/${$i18n.locale}/use-cases/transactional-cx`"
              class="use-case-item d-flex align-center"
            >
              <div>
                <div class="use-case-item__title d-flex align-center">
                  {{ $t('cx.title') }}
                  <arrow-right class="use-case-item__arrow" :size="16" />
                </div>
                <div class="use-case-item__text">
                  {{ $t('cx.subtitle') }}
                </div>
              </div>
            </a>
          </div>
          <div class="column col-4 col-md-12 use-cases-container">
            <a
              :href="`/${$i18n.locale}/use-cases/survey`"
              class="use-case-item d-flex align-center"
            >
              <div>
                <div class="use-case-item__title d-flex align-center w-100 justify-content-between">
                  {{ $t('survey.title') }}
                  <arrow-right class="use-case-item__arrow" :size="16" />
                </div>
                <div class="use-case-item__text">
                  {{ $t('survey.subtitle') }}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="faq">
      <h3 class="text-center">
        {{ $t('faq.title') }}
      </h3>
      <div class="container">
        <div v-for="(question, index) in $t('faq.questions')" :key="index">
          <faq-item
            :title="question.title"
            :answer="question.answer"
          />
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>
<script>

import LandingScaffold from '@/pages/LandingScaffold'
import FaqItem from '@/landing/FaqItem'
import Check from 'vue-material-design-icons/Check.vue'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import Trial from '@/landing/Trial'
import ListCheck from 'vue-material-design-icons/CheckboxMarkedCircleOutline.vue'
import ComparisonTable from '@/landing/ComparisonTable.vue'
import BrowserType from '@/mixins/browserType'

export default {
  components: {
    LandingScaffold,
    Check,
    Trial,
    ArrowRight,
    FaqItem,
    ListCheck,
    ComparisonTable
  },

  mixins: [BrowserType],

  data () {
    return {
      takeAwayActiveIndex: 0,
      takeAwayClicked: false,
      takeAwayInterval: null
    }
  },

  watch: {
    'takeAwayClicked' (clicked) {
      if (clicked) {
        clearInterval(this.takeAwayInterval)
      }
    }
  },

  created () {
    this.animateTakeAways()
  },

  beforeDestroy () {
    this.clearInterval(this.takeAwayInterval)
  },

  methods: {
    /**
     * Change the active take away tab every x seconds until the user clicks on one.
     */
    animateTakeAways () {
      this.takeAwayInterval = setInterval(() => {
        if (this.takeAwayActiveIndex < 2) {
          this.takeAwayActiveIndex += 1
        } else {
          this.takeAwayActiveIndex = 0
        }
      }, 2500)
    },

    /**
     * Sets the currently active take-away tab
     */
    setTakeAwayActiveIndex (idx) {
      this.takeAwayActiveIndex = idx
      this.takeAwayClicked = true
    }
  }
}

</script>

<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />
<i18n locale='en' src='@/i18n/en/landing/Index.json' />
<i18n locale='de' src='@/i18n/de/landing/Index.json' />
<i18n locale='en' src='@/i18n/en/landing/UseCases.json' />
<i18n locale='de' src='@/i18n/de/landing/UseCases.json' />
<i18n locale='en' src='@/i18n/en/landing/Consultants.json' />
<i18n locale='de' src='@/i18n/de/landing/Consultants.json' />