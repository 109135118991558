<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/ebay.svg"
              class="case-study__logo"
              alt="ebay"
            >
            <h2 class="case-study__title">
              Since 2020, Thomas and his team are using Caplena to analyze their large-scale CX and relationship-health monitoring studies.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/testimonial_thomas_walker.jpg" alt="Thomas">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Thomas Walker
                </div>
                <div class="case-study__person__position">
                  Senior Director Market Research @ <span>Ebay</span>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              eBay, one of the world’s leading e-commerce players, is constantly thriving to satisfy both sellers and buyers trading on their platform. Understanding their different needs is key for eBay’s success and is thus a primary objective for Thomas Walker, Senior Director of eBay Global Customer Insights. Thomas is head of a team of x market research specialists who work tirelessly to understand customers and bring user feedback into every part of the eBay organization.
            </div>
            <img
              src="@/assets/landing/case-studies/ebay/hero.jpg"
              class="case-study__image"
              alt="Ebay"
            >
            <label class="case-study__image-text" />

            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Thomas, tell me one thing you do differently than other insights teams?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Thomas (eBay)
                </div>
                <div class="case-study__conversation__answer__text">
                  We love to work with open-ends. By writing free text instead of clicking on check-boxers, customers tell us exactly what they want to tell us – opinions that resonate with them, that they feel and experience. Through AI-enhanced platforms that utilize open-ended questions, customers are in the driving seat.
                </div>
              </div>
            </div>

            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              eBay is collecting significant amounts of open-ended responses totaling more than 2 million responses last year. Manually sifting through hundreds of thousands of responses is simply not possible and distracts from the important goals of the Global Insights Team: Gathering and distributing market intelligence across the organization. They needed an automated way to efficiently analyze these open-ends every month, which still produces output comparable to manual coding in terms of accuracy and interpretability.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              Since 2020, Thomas and his team are using Caplena to analyze their large-scale CX and relationship-health monitoring studies.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Thomas, what was the impact of the new approach?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Thomas (eBay)
                </div>
                <div class="case-study__conversation__answer__text">
                  Marketers very often talk about being disruptive but then pull back from the precipice. This time we really took that leap. This approach disrupted our thinking, planning and processes but it provided nuggets of truth that conventional quantitative closed-end surveys never would.
                </div>
              </div>
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Thomas, why Caplena?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Thomas (eBay)
                </div>
                <div class="case-study__conversation__answer__text">
                  We love that we can quickly organize our massive open-ends data into meaningful themes for our business. Caplena helps us deliver insights to all our stakeholders from product marketing to the CEO.
                </div>
              </div>
            </div>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              By leveraging powerful AI text-analytics, eBay is able to:
              <div>
                Quantify issues and praise across > 2M verbatims per year
              </div>
              <div>
                Identify trends by month-to-month or quarter-to-quarter comparison
              </div>
              <div>
                Dive into countries and customer segments with a total of more than 40 dashboards empowering other functions at eBay to understand and act on user feedback
              </div>
              <div>
                Ingest insights generated by Caplena into other systems
              </div>
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Thomas, what’s the benefit of all this?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Thomas (eBay)
                </div>
                <div class="case-study__conversation__answer__text">
                  <div>
                    Our AI-fueled insights process powered by Caplena allows us to track the emotions people felt when they were on the site or were considering using it. And that emotional analysis will help us to make the right kind of changes.
                  </div>
                  <div>
                    Not only are those conclusions more meaningful because they’ve originated from our audience – rather than us prompting them – but we’ve reached them faster. We’ve become more knowledgeable, more agile and more connected because of the way we’ve transformed our data analysis process.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                eBay
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                eBay, one of the world’s leading e-commerce players, is constantly thriving to satisfy both sellers and buyers trading on their platform. Understanding their different needs is key for eBay’s success and is thus a primary objective for Thomas Walker, Senior Director of eBay Global Customer Insights. Thomas is head of a team of x market research specialists who work tirelessly to understand customers and bring user feedback into every part of the eBay organization.
              </div>
              <div class="case-study__company__subtitle">
                Users on Caplena
              </div>
              <div class="case-study__company__text">
                82
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                September 2019
              </div>
              <div class="case-study__company__subtitle">
                Verbatims analyzed
              </div>
              <div class="case-study__company__text">
                13M+
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                English, German, French, Spanish, Italian and 65 more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />