<template>
  <landing-scaffold class="register-page">
    <template v-slot:default="{openLogin}">
      <div class="form-page">
        <div class="columns">
          <div class="column col-6 col-md-12 d-flex flex-column justify-center position-relative">
            <div class="top-menu navbar form-page__nav d-flex justify-content-between align-items-center">
              <a class="navbar__brand" :href="`/${$i18n.locale}/`">
                <img src="@/assets/landing/logo.svg" alt="logo">
              </a>

              <div class="d-flex align-center hide-md">
                <div class="form-page__nav__text">
                  {{ $t('menu.login_question') }}
                </div>
                <div
                  class="btn btn-link btn-in-nav pr-0"
                  @click="openLogin()"
                >
                  {{ $t('menu.login') }}
                </div>
              </div>
            </div>
            <div class="form-page__form-container">
              <h3 class="text-center" v-text="$t('register.title')" />
              <div class="text-center subtitle mb-2">
                {{ $t('register.subtitle') }}
              </div>
              <form class="form-page__form pt-2" @submit.prevent="doRegister">
                <!--========== Email ==========-->
                <div>
                  <label class="text-sm">{{ $t('register.email') }}</label>
                  <input
                    v-model="register.email"
                    tabindex="5"
                    type="text"
                    placeholder=""
                    :disabled="register.loading || register.success"
                    required
                    :class="{ 'has-error': register.failedResponse.email }"
                  >
                  <div
                    v-if="register.failedResponse.email"
                    class="text-xs text-danger mt-1"
                  >
                    {{ register.failedResponse.email[0] }}
                  </div>
                </div>

                <!--========== First name ==========-->
                <div>
                  <label class="text-sm">{{ $t('register.first_name') }}</label>
                  <input
                    v-model="register.first_name"
                    tabindex="6"
                    type="text"
                    placeholder=""
                    :disabled="register.loading || register.success"
                    required
                    :class="{ 'has-error': register.failedResponse.first_name }"
                  >
                  <div
                    v-if="register.failedResponse.first_name"
                    class="text-xs text-danger mt-1"
                  >
                    {{ register.failedResponse.first_name[0] }}
                  </div>
                </div>

                <!--========== Last name ==========-->
                <div>
                  <label class="text-sm">{{ $t('register.last_name') }}</label>
                  <input
                    v-model="register.last_name"
                    tabindex="7"
                    type="text"
                    placeholder=""
                    :disabled="register.loading || register.success"
                    :class="{ 'has-error': register.failedResponse.last_name }"
                  >
                  <div
                    v-if="register.failedResponse.last_name"
                    class="text-xs text-danger mt-1"
                  >
                    {{ register.failedResponse.last_name[0] }}
                  </div>
                </div>

                <!--========== Password ==========-->
                <div>
                  <label class="text-sm">{{ $t('register.password') }}</label>
                  <input
                    v-model="register.password"
                    tabindex="8"
                    placeholder=""
                    type="password"
                    :disabled="register.loading || register.success"
                    required
                    :class="{ 'has-error': register.failedResponse.password1 }"
                  >
                  <div
                    v-if="register.failedResponse.password1"
                    class="text-xs text-danger mt-1"
                  >
                    {{ register.failedResponse.password1[0] }}
                  </div>
                  <div class="text-xs text-grey mt-2">
                    {{ $t('register.password_tip') }}
                  </div>
                </div>

                <!--========== Terms and conditions ==========-->

                <div>
                  <div class="d-flex align-center mt-2 mb-2 pb-2 pt-2">
                    <input
                      v-model="register.agreeTac"
                      :disabled="register.loading || register.success"
                      tabindex="9"
                      class="mr-2 styled-checkbox"
                      type="checkbox"
                      id="accept-toc-pp"
                    >
                    <label class="mt-0 text-xs mb-0" for="accept-toc-pp">
                      {{ $t('register.accept.i_accept') }}
                      <a
                        :href="'/en/terms-and-conditions/'"
                        target="_blank"
                        v-text="$t('register.accept.terms-and-conditions')"
                      />
                      {{ $t('register.accept.and') }}
                      <a
                        :href="'/en/privacy-policy/'"
                        target="_blank"
                        v-text="$t('register.accept.privacy-policy')"
                      />
                    </label>
                  </div>
                  <div
                    v-if="register.agreeTacFailed"
                    class="text-center text-danger"
                  >
                    {{ $t('register.accept.failed') }}
                  </div>
                </div>
                <!--========== Submit ==========-->
                <div class="text-center mt-2 pt-1">
                  <div
                    v-if="register.failedResponse.non_field_errors"
                    class="text-danger"
                  >
                    {{ register.failedResponse.non_field_errors[0] }}
                  </div>
                  <button
                    tabindex="10"
                    type="submit"
                    class="btn btn-primary w-100 mb-2 d-block mt-2"
                    :disabled="!register.agreeTac || !register.password.length || !register.last_name.length || !register.first_name.length || (register.loading || register.success)"
                    :class="{ 'shake': register.failed && !register.loading }"
                  >
                    <span v-if="register.loading" v-text="$t('register.loading')" />
                    <span v-else-if="register.success" v-text="$t('register.success')" />
                    <span v-else v-text="$t('register.button')" />
                  </button>
                  <div class="text-grey pt-2 mb-1">
                    {{ $t('register.capabilities') }}
                  </div>
                  <a
                    class="font-weight-bold text-green"
                    target="_blank"
                    :href="MEETING_LINK"
                    rel="noopener"
                  >
                    {{ $t('register.request_demo') }}
                  </a>
                </div>
              </form>
            </div>

            <!--========== Success Popup ==========-->
            <transition name="fade">
              <div
                class="overlay" v-if="register.successModal"
                @click="register.successModal = false"
              />
            </transition>
            <transition name="fade">
              <div id="successModal" v-if="register.successModal" class="modal-input">
                <button class="modal-input__close" title="Close (Esc)" type="button"
                        @click="register.successModal = false"
                >
                  &#215;
                </button>

                <div
                  v-html="$t('register.activation_sent', { email: register.email })"
                  class="text-center"
                />
              </div>
            </transition>
          </div>
          <register-right-banner />
        </div>
      </div>
    </template>
  </landing-scaffold>
</template>

<script>

import LandingScaffold from '@/pages/LandingScaffold'
import RegisterRightBanner from './RegisterRightBanner.vue'
import { MEETING_LINK } from '@/settings/constants'
import Dealfront from '@/mixins/dealfront.js'

export default {
  components: {
    LandingScaffold,
    RegisterRightBanner
  },

  mixins: [Dealfront],

  data () {
    return {
      register: {
        loading: false,
        first_name: '',
        email: '',
        password: '',
        password1: '',
        password2: '',
        agreeTac: false,
        agreeTacFailed: false,
        signup_reason: '',
        failed: false,
        failedResponse: { },
        success: false,
        successModal: false
      },

      MEETING_LINK
    }
  },

  head: {
    meta: [
      {
        name: 'robots',
        content: 'noindex'
      }
    ]
  },

  computed: {
  },

  created () {
  },

  mounted () {
  },

  methods: {
    /**
     * Try registering the user with the given data
     */
    doRegister () {
      if (this.register.loading) return
      if (!this.register.agreeTac) {
        this.register.agreeTacFailed = true
        return
      }
      this.register.agreeTacFailed = false
      this.register.loading = true
      this.register.failed = false
      this.$set(this.register, 'failedResponse', {})
      this.$api.post('/api/auth/registration', {
        ...this.register,
        password1: this.register.password,
        password2: this.register.password
      }, { dontReport: [400] }).then(res => {
        this.register.loading = false
        if (res.status === 201) {
          this.register.success = true
          this.register.successModal = true
          this.register.failed = false
          this.identifyDealfront(res.data.user_id)
        } else {
          this.register.failedResponse = res.data
          this.register.failed = true
        }
      }).catch(err => {
        this.register.loading = false
        this.register.failed = true
        this.register.failedResponse = { non_field_errors: [this.$t('register.error')] }
        console.error(err)
      })
    }
  }
}

</script>

<style lang=scss>
  @import '~@/css/landing/landing.scss';
</style>

<i18n locale='en' src='@/i18n/en/landing/Register.json' />
<i18n locale='de' src='@/i18n/de/landing/Register.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />