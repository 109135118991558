<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/iwd.svg"
              class="case-study__logo"
              alt="IWD Market Research"
              style="height: 65px;"
            >
            <h2 class="case-study__title">
              How to grow with a brand-new AI-based service while strengthening image as an innovative agency.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/testimonial_eleonora.jpg" alt="Eleonora">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Eleonora Paul
                </div>
                <div class="case-study__person__position">
                  Business Analyst @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.iwd-marketresearch.com/"
                  >
                    IWD Market research
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              <div>
                IWD market research is a midsized agency, helping Retail clients with market research in more than 26 countries across Europe and the USA.
              </div>
              <div>
                Eleonora Paul, Business Analyst at IWD market research, and her team created a new service called Speech-to-Text at POS (point of sale) for retailers. It involves gathering customer statements at the POS with an enhanced smartphone, transcribing audio with speech-to-text algorithms and coding resulting text statements with Caplena to generate comprehensive and actionable insights. What started as a proof-of-concept is now an established service being delivered to retailers of all sizes.
              </div>
            </div>
            <img
              src="@/assets/landing/case-studies/iwd/hero.jpg"
              class="case-study__image"
              alt="IWD Market research"
            >
            <label class="case-study__image-text" />
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Designing the process of the new service was straight-forward. But finding suitable software partners that can deliver on each step of the process was hard. The main challenge was to find services that can conveniently be integrated into our novel offering and can match the quality expectations of our customers.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              For the Coding part of the process, IWD market research chose Caplena.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Eleonora, what was most important for you when looking for a solution for the Coding part of your new service?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Eleonora (IWD Market Research)
                </div>
                <div class="case-study__conversation__answer__text">
                  We were looking for a platform that runs state-of-the-art machine learning software and still allows us to keep control over the coding behavior at all times. Most other tools act as a black box in this regard. Also, it was critical to us that the tool can be integrated into our workflow using a modern and easy-to-use API.
                </div>
              </div>
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Eleonora, which other software did you consider before choosing Caplena?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Eleonora (IWD Market Research)
                </div>
                <div class="case-study__conversation__answer__text">
                  We tried different coding providers, such as Ascribe or Codeit.
                </div>
              </div>
            </div>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              Since the first proof-of-concept, more than two dozen clients are making use of this new service regularly. Moreover, IWD was able to fortify their position as an innovative agency in their niche.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  What are the main benefits of using Caplena as AI coding provider?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Eleonora (IWD Market Research)
                </div>
                <div class="case-study__conversation__answer__text">
                  Due to the possibility to train the AI within our retail niche by coding a few responses, we’re able to achieve a very high coding quality. Most importantly, we’re able to guarantee to our clients a consistent coding, independent of environmental influences or human weaknesses.
                </div>
              </div>
            </div>
            <div class="case-study__text">
              With this new service, IWD’s client benefit not only from more comprehensive insights but also from a more authentic storytelling, as analysts are able to tap into the original statements at any time and embed the raw statements into the story. And even better: Customers who were taking part in the research love the new approach and show a very high willingness to participate of more than 85%.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  What would you recommend to other agencies who are trying to build services that rely on 3rd party solutions?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Eleonora (IWD Market Research)
                </div>
                <div class="case-study__conversation__answer__text">
                  Get familiar with new tools – the world is moving fast. Better to try and learn while you’re not under pressure, then to wait until you have to make a change.
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                IWD Market Research
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                IWD market research is a midsized agency, helping Retail clients with market research in more than 26 countries across Europe and the USA.
              </div>
              <div class="case-study__company__subtitle">
                Users on Caplena
              </div>
              <div class="case-study__company__text">
                3
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                February 2019
              </div>
              <div class="case-study__company__subtitle">
                Verbatims analyzed
              </div>
              <div class="case-study__company__text">
                1.1M
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                German, English, Italian, French and 3 more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />