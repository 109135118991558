<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/dr-schar_color.svg"
              class="case-study__logo"
              alt="Factworks Logo"
            >
            <h2 class="case-study__title">
              Through Caplena Dr. Schär was able to enhance the skills of their team, amplifying their role as enablers and advisors.
            </h2>
            <!-- <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/testimonial_pia.jpg" alt="Pia">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Pia Helen Kampf
                </div>
                <div class="case-study__person__position">
                  Research Consultant @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.factworks.com/"
                  >
                    Factworks
                  </a>
                </div>
              </div>
            </div> -->
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              Dr. Schär is a food producer developing products for people with special nutritional needs. Headquartered in South Tyrol, they are present in <b>11 countries</b> with a total of <b>18 locations</b>. Their Consumer and Market Insights Team handles a variety of studies to continually improve their products and cater to their consumers' needs 🌽.
            </div>
            <img
              src="@/assets/landing/case-studies/dr-schar/hero.png"
              class="case-study__image"
              alt="Factworks"
            >
            <label class="case-study__image-text" />
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Dr. Schär's Consumer & Market Insights team juggles many projects, but there are two main categories of projects that quickly become overwhelming.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mb-2 d-flex">
                  <b class="d-flex h-100 mr-2">1.</b>
                  <div>
                    Core Projects, such as the <b>NPS tracker</b>. Every year, <b>250K</b> consumers are surveyed with <b>20'000</b> people responding to open-ended questions about Dr. Schär’s produce in six of their core markets.
                  </div>
                </div>
              </div>
              <div class="case-study__text">
                <div class="d-flex">
                  <b class="d-flex h-100 mr-2">2.</b>
                  <div>
                    Projects initiated by other departments such as a <b>claim tests</b>. A typical example of this is a survey with four specific open-ended questions, with around <b>1000</b> people participating.
                  </div>
                </div>
              </div>
            </div>
            <div class="case-study__text">
              The insights team would have needed several more full-time jobs to evaluate all these responses by hand. It would have been <b>tedious, time-consuming</b>, and <b>error-prone</b> to analyze this sheer volume of open-ended answers. As part of Dr Schär's long-term strategy, the insight team needed to increase their focus and enhance their skills to become consultants and enablers for other teams. There was also the issue of being overwhelmed with the sheer volume of data they had to process. The team decided to look for a tool that would help them to accomplish their goal, and came across...
              <div class="text-center">
                ✨ Caplena! ✨
              </div>
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              Through Caplena Dr. Schär was able to enhance the skills of their team, amplifying their role as enablers and advisors. Whenever a product, marketing - or customer support team - has special requests, they assist them with:
            </div>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">✅</span> <b>Setting up</b> the appropriate survey
                </div>
                <div>
                  <span class="mr-2">✅</span> <b>Advising</b> on what type of analysis to run
                </div>
                <div class="mb-2">
                  <span class="mr-2">✅</span> <b>Providing</b> them with the appropriate tools to conduct the analysis
                </div>
              </div>
            </div>
            <div class="case-study__text">
              It's always them, who <b>fully conduct the analysis</b>, though.

              Using this approach benefits the insight teams themselves by reducing their workload, as well as the departments they consult by allowing them to work on their projects without external resources constricting them. <b>Self-service tools are crucial in such setups</b>, allowing insights teams to share expertise in a <b>structured and standardized way</b>. Using Caplena, the manual effort of the prior outlined processes of manual coding was <b>reduced by up to 90%!</b>
            </div>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              Having fully integrated Caplena into their processes, the Consumer and Market Insights Team at Dr. Schär now uses two channels for presenting their insights:
            </div>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mb-1">
                  <b>PUSH</b>
                </div>
                <div>
                  In PowerPoint reports, the team summarizes the <b>key findings</b> and outlines the <b>conclusions drawn</b> in an email 📋.
                </div>
              </div>
              <div class="case-study__text">
                <div class="mb-1">
                  <b>PULL</b>
                </div>
                <div>
                  Using <b>interactive dashboards</b>, you can see results in a more dynamic and flexible way. Users can apply <b>custom filters</b> here to identify interesting segments and even <b>view the verbatim responses behind a particular segment</b> on Caplena’s dashboards. This gives them a more qualitative insight into the customers voice 🚀.
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Dr. Schär
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Our story began in 1922 in South Tyrol, the heart of the Alps, with a vision to improve the lives of people with special nutritional needs. Ever since the company was founded, proximity to the consumer has been our guiding commitment. Our core competence combines the specific, complex requirements placed on nutrition with dedication and joie de vivre. Responsibility, progress and proximity are the values that give us our stability and reliability. We are a family-run company with a global reach, with 18 sites in 11 countries and more than 1,400 employees worldwide. Our products are available in more than 100 countries.
              </div>
              <div class="case-study__company__subtitle">
                Users on Caplena
              </div>
              <div class="case-study__company__text">
                9
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                September 2020
              </div>
              <div class="case-study__company__subtitle">
                Verbatims analyzed
              </div>
              <div class="case-study__company__text">
                140'000+
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                English, German, Italian, French, Spain and 10 more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />