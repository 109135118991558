<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/case-studies/lufthansa/logo.svg?data"
              class="case-study__logo"
              style="height: 25px;"
              alt="lufthansa"
            >
            <h2 class="case-study__title">
              Navigating the Skies of Customer Feedback
            </h2>
            <img
              src="@/assets/landing/case-studies/lufthansa/hero.jpg"
              class="case-study__image w-100"
              alt="Lufthansa"
              style="height: auto;"
            >
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <h5 class="case-study__title">
              Boosting Satisfaction with Open Feedback Insights
            </h5>

            <div class="case-study__text">
              Lufthansa's commitment to customer satisfaction is not just about the destination - but the journey. The airline has refined its method for collecting and deciphering customer feedback, where Caplena is used explicitly to decipher the 'why' behind Net Promoter Scores (NPS). Lufthansa extracts key themes from unstructured responses by delving into open-ended feedback with Caplena's text analysis capabilities. This partnership has deepened Lufthansa's insight into their customers' travel experience, allowing them to enhance and improve the travel journey of their customers proactively.
            </div>

            <h5 class="case-study__title">
              The Customer Journey Mosaic
            </h5>
            <div class="case-study__text">
              Lufthansa tracks its customer's journey at every stage. This begins with the initial excitement of booking a trip and extends through all touchpoints to the concluding act of collecting luggage. The airline's objective is to monitor each phase through the lens of the customer, ensuring any potential disruptions are smoothed out for a seamless experience. To make sure they hear their customers, Lufthansa collects feedback through:
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">*</span> E-mail invitations post-flight
                </div>
                <div class="mt-1">
                  <span class="mr-2">*</span> QR code invitations in the lounges
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">*</span> SMS invitations after contacting Customer Service
                </div>
              </div>
            </div>

            <h5 class="case-study__title">
              The Analytical Journey
            </h5>
            <b class="case-study__text text-green">
              1. Data Collection and Analysis
            </b>
            <div class="case-study__text">
              At the heart of this journey is the NPS score, accompanied by an open-ended question that invites travelers to express their opinions in their own, bias-free words. This invaluable feedback source is captured and categorized into three broader areas of the customer journey:
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">*</span> <b>Ground Operations: </b> Encompassing the vast array of services available before takeoff such as check-in or lounges.
                </div>
                <div class="mt-1">
                  <span class="mr-2">*</span> <b>In-Flight Service: </b> Covering every aspect of the experience aboard the aircraft like food & beverages service, Wi-Fi access, or inflight entertainment.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">*</span> <b>Brand Perception and Oversight: </b> They reflect the image aspects such as reliability or safety of the Lufthansa Group.
                </div>
              </div>
            </div>

            <b class="case-study__text text-green">
              2. The Flow of Feedback
            </b>
            <div class="case-study__text">
              Analyzing the open-ended feedback is complex due to the hugely diverse responses. All diverse sources of feedback converge at Qualtrics and get channeled into Lufthansa’s self-crafted Insights Hub. The process is fully automated, from the data collection, analysis, and reporting to internal stakeholders. Let's break down the tech stack flow into the following stages:
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">1.</span> <b>Data Collection: </b> Through Caplena's direct integration with Qualtrics, feedback from multiple channels is gathered, including open-ended responses to the main open question designed to capture the breadth of the customer experience, as well as closed quantitative questions. This means that as soon as Qualtrics receives a complete interview, it synchronizes the comments with Caplena, and the results become instantly visible.
                </div>
                <div class="mt-1">
                  <span class="mr-2">2.</span> <b>Caplena’s Open-Ended Analysis: </b> Caplena dissects the open-ended question, organizing the vast feedback into three categories. This classification provides clarity and structure, making the data actionable. Within the categories, there are multiple topic subsections. This approach categorizes more than 600K pieces of feedback a year into a fine-grained mesh of 600 specific topics. Caplena's built-in dashboarding solution then visualizes the results.

                  <img
                    src="@/assets/landing/case-studies/lufthansa/img-1.png"
                    class="case-study__image mt-2 pt-2"
                    alt="Lufthansa"
                  >
                </div>
                <div class="mt-1">
                  <span class="mr-2">3.</span> <b>Into the Insights Hub: </b> The processed data is visualized on the Caplena results dashboards, which, in turn, is interlinked into Lufthansa’s Insights Hub via iframe, where it sits alongside data from other sources. This creates a rich tapestry of both qualitative and quantitative insights.
                </div>
                <div class="mt-1">
                  <span class="mr-2">4.</span> <b>Managerial Insights: </b> Internal stakeholders can easily access this data and filter it based on their specific areas of responsibility. For instance, lounge managers would focus on feedback on lounge facilities and services within their geographical area to get actionable insights for their specific lounge.
                </div>
              </div>
            </div>

            <b class="case-study__text text-green">
              3. The Outcome
            </b>
            <div class="case-study__text">
              The Customer Insights Hub, created by Lufthansa's Customer Insights team, serves as a central platform for real-time access to customer information, feedback, and competition data. It facilitates efficient analysis of key themes impacting their Net Promoter Score (NPS), streamlining decision-making and prioritizing customer satisfaction in their operational strategy.
              <img
                src="@/assets/landing/case-studies/lufthansa/img-2.png"
                class="case-study__image mt-2 pt-2"
                alt="Lufthansa"
              >
              <img
                src="@/assets/landing/case-studies/lufthansa/img-3.png"
                class="case-study__image mt-2"
                alt="Lufthansa"
              >
            </div>

            <h5 class="case-study__title">
              Conclusion
            </h5>

            <div class="case-study__text">
              Lufthansa's strategic integration of Caplena into its open-ended data processing cycle means they get a deeper insight into specific customer satisfaction topics along the customer journey touchpoints, transforming how they analyze and act upon customer feedback. The key takeaways from this case study can be summarized as follows:
            </div>

            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">1.</span> <b>Customer-Centric Decision-Making: </b> Lufthansa's shift to a customer-focused approach is underpinned by the strategic use of open-ended feedback. Tools like Caplena convert this feedback data into actionable insights, leading to service enhancements and a personalized customer journey.
                </div>
                <div class="mt-1">
                  <span class="mr-2">2.</span> <b>Integrated Analytical Framework: </b> Integrating qualitative and quantitative feedback into Lufthansa's Insights Hub facilitates real-time analysis and informed decision-making for internal stakeholders, with Caplena's Qualtrics integration simplifying this process.
                </div>
                <div class="mt-1">
                  <span class="mr-2">3.</span> <b>Comprehensive Excellence and Satisfaction: </b> Lufthansa's commitment to operational excellence, measured by the Net Promoter Score, and the continuous analysis of the entire customer journey, results in a consistently superior travel experience from start to finish.
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Lufthansa
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Lufthansa is Germany's flag carrier and the second-largest airline in Europe when considering its subsidiaries, following Ryanair. It is one of the founding members of the world's largest airline alliance, Star Alliance, established in 1997.
              </div>
              <div class="case-study__company__subtitle">
                Topics tracked
              </div>
              <div class="case-study__company__text">
                600
              </div>
              <div class="case-study__company__subtitle">
                Text comments analyzed
              </div>
              <div class="case-study__company__text">
                3M+
              </div>
              <div class="case-study__company__subtitle">
                Data streams analyzed
              </div>
              <div class="case-study__company__text">
                Satisfaction surveys, ad-hoc studies and social media data
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {
    }
  },
  computed: {},
  mounted () {},
  methods: {
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />
