const DEALFRONT_REF = 'p1e024Bke0ZaGB6d'

export default {
  data () {
    return {
    }
  },

  methods: {
    loadDealfront () {
      if (this.nonUserMode) return

      window.ldfdr = function () {
        window.ldfdr._q = window.ldfdr._q || []
        window.ldfdr.push([].slice.call(arguments))
      }

      const fs = document.getElementsByTagName('script')[0]
      var cs = document.createElement('script')
      cs.src = `https://sc.lfeeder.com/lftracker_v1_${DEALFRONT_REF}.js`
      cs.async = 1
      fs.parentNode.insertBefore(cs, fs)
    },

    identifyDealfront (userID) {
      if (!window.ldfdr) return

      window.ldfdr.identify({
        email: `${userID}@caplena-user-id.com`
      })
    }
  }
}
