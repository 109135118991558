<template>
  <landing-scaffold class="pricing-page" ref="scaffold">
    <section class="hero-text hero-text--pricing overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="column col-12 text-center">
            <div class="d-flex flex-column justify-center align-center">
              <div class="mb-0 text-green" v-html="$t('hero.label')" />
              <h1 v-html="$t('hero.title')" />
              <div class="hero-text__subtitle" v-html="$t('hero.subtitle')" />
              <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
            </div>
            <div class="text-center">
              <div class="logos d-flex flex-wrap">
                <div class="logo-holder">
                  <img
                    src="@/assets/landing/customer_logos/ebay_dark.svg?data"
                    style="height: 30px;"
                    alt="Ebay"
                  >
                </div>
                <div
                  class="logo-holder logo-holder--link"
                >
                  <img
                    src="@/assets/landing/customer_logos/lufthansa.svg?data"
                    alt="Lufthansa"
                    style="height: 30px;"
                  >
                </div>
                <a
                  :href="`/en/case-studies/factworks`"
                  class="logo-holder logo-holder--link"
                >
                  <img
                    src="@/assets/landing/customer_logos/factworks_dark.svg?data"
                    alt="Factworks"
                    style="height: 30px;"
                  >
                </a>
                <div
                  class="logo-holder"
                >
                  <img
                    src="@/assets/landing/customer_logos/miele.svg?data"
                    style="height: 28px;"
                    alt="Miele"
                  >
                </div>
                <div class="logo-holder">
                  <img
                    src="@/assets/landing/customer_logos/dhl.svg?data"
                    alt="DHL"
                    style="height: 20px;"
                  >
                </div>
                <div class="logo-holder">
                  <img
                    src="@/assets/landing/customer_logos/swisscom.svg?data"
                    alt="Swisscom"
                    style="height: 50px;"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MOBILE COMPARISON TABLE -->

      <!-- TEAM PLAN  -->
      <div class="container hide-lg-and-up">
        <div class="columns">
          <div class="column col-12">
            <div class="pricing-page__table position">
              <div class="d-flex">
                <div class="pricing-page__table__plan col-12 d-flex flex-column">
                  <div class="d-flex flex-column align-start">
                    <div class="pricing-page__table__plan__title">
                      {{ $t('plans.team') }}
                    </div>
                    <div class="pricing-page__table__plan__label text-left">
                      {{ $t('plans.team_description') }}
                    </div>
                    <a
                      class="pricing-page__table__plan__btn btn btn-primary w-100"
                      :href="`/${$i18n.locale}/register/`"
                    >
                      {{ $t('call.book') }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.overview') }}
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.users') }}
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('up_to') }} 3
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('credits') }}
                    <helptip :size="16">
                      {{ $t('helptips.credits') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('up_to') }} 20k
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.topic_collections') }}
                    <helptip :size="16">
                      {{ $t('helptips.topic_collections') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  1
                </div>
              </div>
            </div>

            <div class="pricing-page__table position-relative">
              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.core') }}
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.coding') }}
                    <helptip :size="16">
                      {{ $t('helptips.coding') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.topic_detection') }}
                  </div>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.native_language_support')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-11 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.language_support') }}
                  </div>
                </div>
                <div class="col-1 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.visualizations_access')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.summary')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.scraping')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.api_access')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.support')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>
            </div>

            <div class="pricing-page__table position-relative">
              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.additional') }}
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.customer_success')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.live_trainings')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.historic_training')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.anonymization')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.white_labeling')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.advanced_dashboards')" />
                  <helptip :size="16">
                    {{ $t('helptips.advanced_dashboards') }}
                  </helptip>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.custom_anonymization')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-11 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.custom_integrations')" />
                </div>
                <div class="col-1 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- ENTERPRISE PLAN  -->
          <div class="column col-12">
            <div class="pricing-page__table position">
              <div class="d-flex">
                <div class="pricing-page__table__plan col-12 d-flex flex-column">
                  <div class="d-flex flex-column align-start">
                    <div class="pricing-page__table__plan__title">
                      {{ $t('plans.enterprise') }}
                    </div>
                    <div class="pricing-page__table__plan__label text-left">
                      {{ $t('plans.enterprise_description') }}
                    </div>
                    <a
                      class="pricing-page__table__plan__btn btn btn-primary w-100"
                      :href="`/${$i18n.locale}/register/`"
                    >
                      {{ $t('call.book') }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.overview') }}
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.users') }}
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('short_features.custom') }}
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('credits') }}
                    <helptip :size="16">
                      {{ $t('helptips.advanced_dashboards') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('short_features.custom') }}
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.topic_collections') }}
                    <helptip :size="16">
                      {{ $t('helptips.topic_collections') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('short_features.custom') }}
                </div>
              </div>
            </div>

            <div class="pricing-page__table position-relative">
              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.core') }}
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.coding') }}
                    <helptip :size="16">
                      {{ $t('helptips.coding') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.topic_detection') }}
                  </div>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.native_language_support')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-11 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.language_support') }}
                  </div>
                </div>
                <div class="col-1 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.visualizations_access')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.summary')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.scraping')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.api_access')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.support')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>
            </div>

            <div class="pricing-page__table position-relative">
              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.additional') }}
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.customer_success')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.live_trainings')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-9 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.historic_training')" />
                </div>
                <div class="col-3 pricing-page__table__row__col d-flex justify-end align-center">
                  3
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.anonymization')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.white_labeling')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.advanced_dashboards')" />
                  <helptip :size="16">
                    {{ $t('helptips.advanced_dashboards') }}
                  </helptip>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.custom_anonymization')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-9 d-flex">
                  <div class="pricing-page__table__row__title text-left" v-html="$t('features.custom_integrations')" />
                </div>
                <div class="col-3 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('add_on') }}
                </div>
              </div>
            </div>
          </div>

          <!-- AGENCY PLAN  -->
          <div class="column col-12">
            <div class="pricing-page__table position">
              <div class="d-flex">
                <div class="pricing-page__table__plan col-12 d-flex flex-column">
                  <div class="d-flex flex-column align-start">
                    <div class="pricing-page__table__plan__title">
                      {{ $t('plans.agency') }}
                    </div>
                    <div class="pricing-page__table__plan__label text-left">
                      {{ $t('plans.agency_description') }}
                    </div>
                    <a
                      class="pricing-page__table__plan__btn btn btn-primary w-100"
                      :href="`/${$i18n.locale}/register/`"
                    >
                      {{ $t('call.book') }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.overview') }}
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.users') }}
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('short_features.custom') }}
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('credits') }}
                    <helptip :size="16">
                      {{ $t('helptips.advanced_dashboards') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('short_features.custom') }}
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-6 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.topic_collections') }}
                    <helptip :size="16">
                      {{ $t('helptips.topic_collections') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-6 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>
            </div>

            <div class="pricing-page__table position-relative">
              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.core') }}
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.coding') }}
                    <helptip :size="16">
                      {{ $t('helptips.coding') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.topic_detection') }}
                  </div>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.native_language_support')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-11 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.language_support') }}
                  </div>
                </div>
                <div class="col-1 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.visualizations_access')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.summary')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.scraping')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.api_access')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.support')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>
            </div>

            <div class="pricing-page__table position-relative">
              <div class="pricing-page__table__row__title font-weight-bold mb-2 pb-2">
                {{ $t('plans.headers.additional') }}
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.customer_success')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.live_trainings')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-9 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.historic_training')" />
                </div>
                <div class="col-3 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('add_on') }}
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-9 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.anonymization')" />
                </div>
                <div class="col-3 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('add_on') }}
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-9 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('short_features.white_labeling')" />
                </div>
                <div class="col-3 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('add_on') }}
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.advanced_dashboards') }}
                    <helptip :size="16">
                      {{ $t('helptips.advanced_dashboards') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-10 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.custom_anonymization')" />
                </div>
                <div class="col-2 pricing-page__table__row__col d-flex justify-end align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-9 d-flex">
                  <div class="pricing-page__table__row__title text-left" v-html="$t('features.custom_integrations')" />
                </div>
                <div class="col-3 pricing-page__table__row__col d-flex justify-end align-center">
                  {{ $t('add_on') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- DESKTOP COMPARISON TABLE  -->
      <div class="container hide-md">
        <div class="columns">
          <div class="column col-12 position-relative">
            <div class="pricing-separator" />
            <div class="pricing-page__table position">
              <div class="d-flex">
                <div class="pricing-page__table__plan col-3 col-md-3" />
                <div class="pricing-page__table__plan col-3 col-md-12 d-flex flex-column">
                  <div class="d-flex flex-column justify-center">
                    <div class="pricing-page__table__plan__title">
                      {{ $t('plans.team') }}
                    </div>
                    <div class="pricing-page__table__plan__label">
                      {{ $t('plans.team_description') }}
                    </div>
                    <a
                      class="pricing-page__table__plan__btn btn btn-primary"
                      :href="`/${$i18n.locale}/register/`"
                    >
                      {{ $t('call.book') }}
                    </a>
                  </div>
                </div>
                <div class="pricing-page__table__plan col-3 col-md-12 d-flex flex-column">
                  <div class="d-flex flex-column justify-center">
                    <div class="pricing-page__table__plan__title">
                      {{ $t('plans.enterprise') }}
                    </div>
                    <div class="pricing-page__table__plan__label">
                      {{ $t('plans.enterprise_description') }}
                    </div>
                    <a
                      class="pricing-page__table__plan__btn btn btn-primary bg-green"
                      :href="`/${$i18n.locale}/register/`"
                    >
                      {{ $t('call.book') }}
                    </a>
                  </div>
                </div>
                <div class="pricing-page__table__plan col-3 col-md-12 d-flex flex-column">
                  <div class="d-flex flex-column justify-center">
                    <div class="pricing-page__table__plan__title">
                      {{ $t('plans.agency') }}
                    </div>
                    <div class="pricing-page__table__plan__label">
                      {{ $t('plans.agency_description') }}
                    </div>
                    <a
                      class="pricing-page__table__plan__btn btn btn-primary"
                      :href="`/${$i18n.locale}/register/`"
                    >
                      {{ $t('call.book') }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex">
                  <div class="pricing-page__table__row__title font-weight-bold">
                    {{ $t('plans.headers.overview') }}
                  </div>
                </div>
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.users') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  {{ $t('up_to') }} 3
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  {{ $t('short_features.custom') }}
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  {{ $t('short_features.custom') }}
                </div>
              </div>
              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('credits') }}
                    <helptip :size="16">
                      {{ $t('helptips.credits') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  {{ $t('up_to') }} 20k
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  {{ $t('short_features.custom') }}
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  {{ $t('short_features.custom') }}
                </div>
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.topic_collections') }}
                    <helptip :size="16">
                      {{ $t('helptips.topic_collections') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  1
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  {{ $t('short_features.custom') }}
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>
            </div>

            <div class="pricing-page__table position-relative">
              <div class="pricing-page__table__row d-flex align-center">
                <div class="pricing-page__table__row__title font-weight-bold">
                  {{ $t('plans.headers.core') }}
                </div>
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.coding') }}
                    <helptip :size="16">
                      {{ $t('helptips.coding') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.topic_detection') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title" v-html="$t('features.native_language_support')" />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.language_support') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.visualizations_access') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.summary') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.scraping') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.api_access') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.support') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>
            </div>

            <div class="pricing-page__table position-relative">
              <div class="pricing-page__table__row d-flex align-center">
                <div class="pricing-page__table__row__title font-weight-bold">
                  {{ $t('plans.headers.additional') }}
                </div>
              </div>
              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.customer_success') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row  d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.live_trainings') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.historic_training') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  3
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  {{ $t('add_on') }}
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.anonymization') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  {{ $t('add_on') }}
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.white_labeling') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  {{ $t('add_on') }}
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.advanced_dashboards') }}
                    <helptip :size="16">
                      {{ $t('helptips.advanced_dashboards') }}
                    </helptip>
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row white d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('features.custom_anonymization') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <check
                    class="pricing-page__table__row__check"
                    :size="24"
                  />
                </div>
              </div>

              <div class="pricing-page__table__row d-flex align-center">
                <div class="col-3 col-md-12 d-flex">
                  <div class="pricing-page__table__row__title">
                    {{ $t('short_features.custom_integrations') }}
                  </div>
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  <close
                    class="pricing-page__table__row__x"
                    :size="24"
                  />
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col pricing-page__table__row__middle-col d-flex justify-center align-center">
                  {{ $t('add_on') }}
                </div>
                <div class="col-3 col-md-12 pricing-page__table__row__col d-flex justify-center align-center">
                  {{ $t('add_on') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pricing-page__call">
      <div class="container">
        <div class="columns">
          <div class="column col-12 text-center text-white">
            <h2 v-text="$t('call.title')" />
            <a
              class="btn btn-primary btn-green"
              :href="`/${$i18n.locale}/register/`"
              rel="noopener"
              target="_blank"
              v-text="$t('call.book')"
            />
          </div>
        </div>
      </div>
    </section>
  </landing-scaffold>
</template>

<script>

import LandingScaffold from '@/pages/LandingScaffold'
import pricing from '@/mixins/pricing.js'
import Check from 'vue-material-design-icons/CheckboxMarkedCircle.vue'
import Close from 'vue-material-design-icons/Close.vue'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    LandingScaffold,
    Check,
    Close
  },

  mixins: [pricing],

  data () {
    return {
    }
  },

  computed: {
  },

  created () {
  },

  mounted () {
  },

  methods: {
  }
}

</script>

<i18n locale='en' src='@/i18n/en/landing/NewPricing.json' />
<i18n locale='de' src='@/i18n/de/landing/NewPricing.json' />
<i18n locale='en' src='@/i18n/en/Subscription.json' />
<i18n locale='de' src='@/i18n/de/Subscription.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />
<i18n locale="en" src="@/i18n/en/landing/Index.json" />
<i18n locale="de" src="@/i18n/de/landing/Index.json" />