<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/link_dark.svg"
              class="case-study__logo"
              alt="link"
            >
            <h2 class="case-study__title">
              Within two weeks from start to finish LINK & Coop were able to complete the entire project of analyzing the quarterly feedback.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/testimonial_laura.jpg" alt="Laura">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Laura Colledani
                </div>
                <div class="case-study__person__position">
                  Head of Food and Retail @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.link.ch/"
                  >
                    LINK
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              Coop, Switzerland’s largest retailer, is working with LINK, a leading market and social research institute, to understand customer behavior and feedback in their approx. 2300 stores. In order to maintain and improve on their excellent in-store experience, Coop is capturing more than 50’000 customer feedbacks per quarter.
            </div>
            <img
              src="@/assets/landing/case-studies/link/hero.jpg"
              class="case-study__image"
              alt="Link"
            >
            <label class="case-study__image-text" />
            <div class="case-study__text">
              Johanna Mäder, Market Research Project Manager at Coop, and Laura Colledani, Head of LINK’s Food and Retail practice, set out to improve Coop’s ability to act on customer feedback.
            </div>
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Coop is collecting such a significant amount of open-ended feedback that it cannot possibly analyze it all manually. Thus, in the past, Coop has routed the verbatims back to the individual stores and relied on the individual store managers to read it and take action. While this enabled store managers to get feedback on their local level (if they read it), it did not allow Coop to get an overview over what their customers were thinking overall about the shopping experience.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              As Coop’s trusted partner, LINK recommended Caplena, their go-to tool for text analysis at scale.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Laura and Joana, why did you choose Caplena?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Joana (Coop)
                </div>
                <div class="case-study__conversation__answer__text">
                  We needed a tool that allowed us to quickly and cost-effectively analyze 50’000 verbatims per quarter while still making sure that the output is as interpretable and accurate as if it were fully analyzed by humans. Caplena is the only tool we’re aware of which can achieve this, notably four all our languages (German, French, English, Italian).
                </div>
              </div>
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Laura, how did you use Caplena to analyze Coop’s verbatims?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Laura (Link)
                </div>
                <div class="case-study__conversation__answer__text">
                  We used our existing coding staff to manually analyze a few responses to nudge the AI into coding the responses the same way they’d be coded manually. In the end we could barely distinguish between human and automatic coding. The system really understands our customers’ answers.
                </div>
              </div>
            </div>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              Within two weeks from start to finish LINK & Coop were able to complete the entire project of analyzing the quarterly feedback. They were able to identify key trends such as an increase in customer satisfaction with the store atmosphere: “The increase from 5% to 9% was so large that we were able to rate the implementation of new store design as a complete success,” according to Johanna Mäder, Market Research Project Manager at Coop.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Joana, do you have any recommendations for other teams setting up a semi or fully automated feedback analysis pipeline?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Joana (Coop)
                </div>
                <div class="case-study__conversation__answer__text">
                  Effectively translating the ever-increasing amount of feedback into actionable insights is a major challenge for us. In our toolbox, Caplena is an important tool that enables us to benefit from the new possibilities of digitization which we are pushing together with our trusted partner LINK.
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Link
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Coop, Switzerland’s largest retailer, is working with LINK, a leading market and social research institute, to understand customer behavior and feedback in their approx. 2300 stores. In order to maintain and improve on their excellent in-store experience, Coop is capturing more than 50’000 customer feedbacks per quarter.
              </div>
              <div class="case-study__company__subtitle">
                Users on caplena
              </div>
              <div class="case-study__company__text">
                5
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                March 2018
              </div>
              <div class="case-study__company__subtitle">
                Verbatims analyzed
              </div>
              <div class="case-study__company__text">
                2M
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                German, French, Italian, English, Portuguese and 20 more.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />