<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/customer_logos/tegut_color.svg"
              class="case-study__logo"
              style="height: 60px;"
              alt="Tegut Logo"
            >
            <h2 class="case-study__title">
              Through Caplena, Tegut was able to easily analyse all forms of user feedback from surveys & online reviews across all 300 stores.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/testimonial_jan.jpg" alt="Jan">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Dr. Jan Rutenberg
                </div>
                <div class="case-study__person__position">
                  Head of Market Research @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.tegut.com/"
                  >
                    Tegut
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <img
              src="@/assets/landing/case-studies/tegut/hero.png"
              class="case-study__image"
              alt="Factworks"
            >
            <label class="case-study__image-text" />
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Finding out what customers think about your in-store experience and comparing against the competition is very time consuming and costly if you are relying on traditional surveys. Google Map reviews are attractive for this purpose as they don’t have to be collected in the field and enable benchmarking without surveying competitor stores. However, making sense of these thousands online reviews and comparing them with traditional NPS surveys is an insurmountable task if done manually.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              Tegut unlocked the capability to cross-analyze reviews from over 300 stores and NPS surveys using AI.
            </div>
            <div class="case-study__quote">
              Getting started was easy. We were able to simply transfer the existing codebook from our customer satisfaction survey to code the Google Maps reviews. Today, we can almost completely automatically evaluate 10,000 reviews per year. The connection between the Google Maps star rating and the free-text reviews helps us to identify the drivers of satisfaction or dissatisfaction and to visualize them.
            </div>
            <div class="case-study__text">
              Analyzing survey and Google Map responses revealed lots of surprising factors, both positive and negative, about tegut's stores. These insights are packaged & presented nicely in dashboards and are made instantly available to intersted stakeholders from sales to teams at the store level. As a result of this new feedback loop, everyone concerned can quickly know if, for example, there is a problem with available parking space in front of the store - or if the fruit and vegetables are of poor quality. Moreover, tegut is now able to benchmark itself against tis main competitors in order to identify key strengths and weaknesses.
            </div>
            <div class="case-study__quote">
              For us, the Google reviews and the automated evaluation with Caplena are an excellent opportunity to quickly analyze a lot into honest, star specific feedback and the customer experience at scale.
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Tegut
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                German food retailer Tegut is a Swiss-owned supermarket chain based in Fulda, Germany. Operating in 300 stores across seven of Germany's Bundesländer, Tegut attaches great importance to sustainability, fairness, and transparency.
              </div>
              <div class="case-study__company__subtitle">
                Users on Caplena
              </div>
              <div class="case-study__company__text">
                2
              </div>
              <div class="case-study__company__subtitle">
                Date joined
              </div>
              <div class="case-study__company__text">
                December 2019
              </div>
              <div class="case-study__company__subtitle">
                Verbatims Analyzed
              </div>
              <div class="case-study__company__text">
                55'000+
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                German, English
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />