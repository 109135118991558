<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/case-studies/dhl/logo.svg"
              class="case-study__logo"
              style="height: 34px;"
              alt="DHL Logo"
            >
            <h2 class="case-study__title">
              Employee Feedback @DHL Group: Every Voice Counts
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/case-studies/dhl/soren.jpg" alt="Soren" style="object-fit: cover; object-position: 20%;">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Sören Bödder
                </div>
                <div class="case-study__person__position">
                  Research Consultant @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.dhl.com/"
                  >
                    DHL Group
                  </a>
                </div>
              </div>
            </div>
            <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              Resonating with the voices of over 600,000 employees, DHL Group is a global logistics leader for international shipping, courier services, and transportation. By leveraging Caplena, DHL Group has redefined its employee feedback and engagement approach.
            </div>
            <label class="case-study__image-text" />
            <h5 class="case-study__title">
              The Unifying Force
            </h5>
            <div class="case-study__text">
              At the heart of DHL Group's fundamental principles, employee feedback is seamlessly intertwined, functioning as a guiding light toward a future characterized by unity and innovation. Acknowledging employees as the company's most valuable asset, everyone across all tiers actively shapes workflows, processes, and values. Some of the biggest feedback projects include:
            </div>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">1.</span> <b>Employee Engagement: </b> DHL Group cultivates a vibrant workplace culture through the annual <a href="https://www.dpdhl.com/en/sustainability/social/employee-engagement.html">Global Employee Opinion Survey (EOS)</a> to measure employee satisfaction and identify potential areas for improvement.
                </div>
                <div class="mt-1">
                  <span class="mr-2">2.</span> <b>Moments that Matter: </b> The HR Quality Survey and touchpoint monitoring meticulously examine the journey from entry to exit.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">3.</span> <b>Employee Feedback Suite (EFS) </b> The EFS is a tool that enables managers and team leads to decentrally capture feedback whenever they see the need to.
                </div>
              </div>
            </div>
            <div class="case-study__quote">
              Caplena has empowered DHL Group to turn the collective voice of our 600,000 employees into a force for innovation and growth. With its cutting-edge analytics and language
              capabilities, we've unlocked new levels of understanding and unity across our global
              workforce.
            </div>
            <h5 class="case-study__title">
              Navigating the Challenge
            </h5>
            <div class="case-study__text">
              With companywide employee feedback initiatives and the need for data-driven decisions, the
              task is to collect feedback and find and act on accurate insights within it. DHL sought a tool
              capable of managing their volume of feedback and effectively addressing the challenges of:
            </div>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">•</span> <b>Sorting Unstructured Data: </b> Having amassed around 550,000 comments in 2023
                  alone, signifying a remarkable 1275% increase since 2019 and growing, DHL Group
                  confronts the intricate task of accurately classifying unstructured data. This challenge
                  catalyzes the pursuit of innovative LLM solutions.
                </div>
                <div class="mt-1">
                  <span class="mr-2">•</span> <b>Analyzing Sentiments: </b> Unraveling the intricacies of textual sentiments and
                  distinguishing between positive and negative tones for nuanced and comprehensive
                  insights at the topic level.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Unifying Multilingual Data: </b> With various languages in play, the need arises for a
                  bridge that accurately translates and unites diverse feedback into a coherent
                  understanding.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Upholding Data Integrity: </b> Maintaining data integrity is a top priority throughout the
                  analysis, reflecting DHL's dedication to responsible practices and inventive problemsolving.
                </div>
              </div>
            </div>

            <h5 class="case-study__title">
              Empowering Insights with Caplena
            </h5>
            <div class="case-study__text">
              Introducing Caplena: the data-driven partner that fills the missing piece of the puzzle with
              cutting-edge analytical skills.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__text">
                <div class="mt-2">
                  <span class="mr-2">•</span> <b>Accelerating Insights: </b> Decreased turnaround time for non-biased, high-quality
                  insights - aligning with DHL Group's ethos of pursuing accelerated growth and
                  comprehensive understanding. Up to 30x faster than traditional software.
                </div>
                <div class="mt-1">
                  <span class="mr-2">•</span> <b>Revealing Themes and Emotions: </b> Caplena delves deeper, revealing nuanced themes
                  and sentiments at the topic level, even within a single response.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>No Language Barrier: </b> Caplena breaks linguistic barriers, uniting languages per
                  DHL's global vision. Native in over 36 languages, Caplena's seamless integration of
                  translation services extends this linguistic reach globally.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Trend Tracking: </b> Caplena detects trends and their progression, while shareable
                  dashboards help monitor them over time. Aligning with DHL's pursuit of
                  transformative insights to shape strategic journeys.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Holistic Insights: </b> Caplena seamlessly intertwines qualitative narratives with
                  quantitative data.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Data Protection: </b> Caplena can automatically remove personally identifiable
                  information (PII) from all text comments if needed.
                </div>
                <div class="mb-2 mt-1">
                  <span class="mr-2">•</span> <b>Connecting Insights: </b> DHL Group merges internal EOS feedback and external
                  insights from platforms like Indeed, revealing correlations that can be put to use for
                  further strategic initiatives.
                </div>
              </div>
            </div>

            <h5 class="case-study__title">
              Conclusion
            </h5>
            <div class="case-study__text">
              In summary, DHL Group's workforce of over 600,000 employees has found a unified voice
              toward action through Caplena's employee feedback solution. Caplena's prowess in handling
              unstructured data, deciphering sentiments, and bridging linguistic gaps has transformed
              challenges into growth opportunities. By converging internal and external insights, Caplena
              aligns with DHL Group's commitment to holistic analysis and innovation, amplifying its
              impact beyond HR applications.
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                DHL Group
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                DHL is the global leader in the logistics industry. Specializing in international shipping, courier services and transportation.
              </div>
              <div class="case-study__company__subtitle">
                Users on Caplena
              </div>
              <div class="case-study__company__text">
                500+
              </div>
              <div class="case-study__company__subtitle">
                Topics tracked
              </div>
              <div class="case-study__company__text">
                120+
              </div>
              <div class="case-study__company__subtitle">
                Languages
              </div>
              <div class="case-study__company__text">
                45+ languages
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />