import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=5d05fb2c"
import script from "./Review.vue?vue&type=script&lang=js"
export * from "./Review.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/en/landing/UseCases.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Flanding%2Fuse-cases%2FReview.vue&locale=en&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/de/landing/UseCases.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Flanding%2Fuse-cases%2FReview.vue&locale=de&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/i18n/en/landing/Global.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Flanding%2Fuse-cases%2FReview.vue&locale=en&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/i18n/de/landing/Global.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fworkspace%2Fsrc%2Flanding%2Fuse-cases%2FReview.vue&locale=de&external"
if (typeof block3 === 'function') block3(component)

export default component.exports