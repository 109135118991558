<template>
  <section class="hero-text overflow-hidden">
    <div class="container">
      <div class="columns">
        <div class="column col-7 col-md-12 col-mx-auto">
          <h1 class="ml-0 mb-2 pb-2">
            Booking confirmed
          </h1>
          <div class="subtitle mt-2">
            An invitation has been emailed to you. <br>We're looking forward to meeting on <strong>{{ on }}</strong> 🤝.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

const moment = require('moment')

export default {
  codit: true,
  name: 'MeetingBooked',

  data () {
    return {
      on: null,
      email: ''
    }
  },

  created () {
    const urlParams = new URLSearchParams(window.location.search)
    this.on = moment(urlParams.get('start_at')).local().format('dddd, MMMM Do YYYY, H:mm')
    this.email = urlParams.get('email')
    if (this.email) {
      setTimeout(() => {
        this.$root.$children[0].posthogCapture('Meeting Booked', { email: this.email })
      }, 1000)
    }
  }
}

</script>
