/* eslint-disable vue/html-indent */
<template>
  <div id="mountpoint">
    <!-- <div
      class="top-menu"
      :class="{
        'top-menu--fixed': nav.fixed && !showMenu,
        'top-menu--hidden': nav.hidden && !showMenu
      }"
    > -->
    <div
      class="top-menu top-menu"
      :class="{
        'top-menu--fixed': nav.fixed
      }"
    >
      <div class="container">
        <header class="navbar d-flex align-center">
          <a class="navbar__brand" :href="`/${$i18n.locale}/`">
            <img src="@/assets/landing/logo.svg?data" alt="logo">
          </a>
          <transition name="fade">
            <div
              class="mobile-menu show"
              v-if="showMenu"
              v-c-click-outside="hideMenu"
            >
              <div class="mobile-menu__close" @click="hideMenu">
                &#215;
              </div>
              <div class="mobile-menu__section">
                <a
                  :href="`/${$i18n.locale}/features/`"
                  class="navbar__link navbar__link--header"
                  v-text="$t('menu.features')"
                />
                <div class="columns">
                  <a
                    :href="`/${$i18n.locale}/features#feature-1`"
                    class="navbar__link col-md-6"
                    v-text="$t('menu.data_ingestion')"
                  />
                  <a
                    :href="`/${$i18n.locale}/features#feature-2`"
                    class="navbar__link col-md-6"
                    v-text="$t('menu.discover_topics')"
                  />
                </div>
                <div class="columns">
                  <a
                    :href="`/${$i18n.locale}/features#feature-3`"
                    class="navbar__link col-md-6"
                    v-text="$t('menu.tag_data')"
                  />
                  <a
                    :href="`/${$i18n.locale}/features#feature-4`"
                    class="navbar__link col-md-6"
                    v-text="$t('menu.story')"
                  />
                </div>
              </div>
              <div class="mobile-menu__section">
                <a
                  :href="`/${$i18n.locale}/use-cases/`"
                  v-text="$t('menu.use_cases')"
                  class="navbar__link navbar__link--header col-md-12"
                />
                <div class="columns">
                  <a
                    :href="`/${$i18n.locale}/use-cases/review-analysis`"
                    v-text="$t('review.title')"
                    class="navbar__link col-md-6"
                  />
                  <a
                    :href="`/${$i18n.locale}/use-cases/transactional-cx`"
                    v-text="$t('cx.title')"
                    class="navbar__link col-md-6"
                  />
                </div>
                <div class="columns">
                  <a
                    :href="`/${$i18n.locale}/use-cases/survey`"
                    v-text="$t('survey.title')"
                    class="navbar__link col-md-6"
                  />
                  <a
                    :href="`/${$i18n.locale}/use-cases/brand-awareness`"
                    v-text="$t('brand.title')"
                    class="navbar__link col-md-6"
                  />
                </div>
                <div class="columns">
                  <a
                    :href="`/${$i18n.locale}/use-cases/text-analytics`"
                    v-text="$t('analytics.title')"
                    class="navbar__link col-md-12"
                  />
                </div>
              </div>
              <div class="mobile-menu__section">
                <a
                  href="#"
                  class="navbar__link navbar__link--header"
                  v-text="$t('menu.product')"
                />
                <div class="columns">
                  <a
                    class="navbar__link col-md-6"
                    :href="`/${$i18n.locale}/pricing`"
                  >{{ $t("menu.pricing") }}</a>
                  <a
                    class="navbar__link col-md-6"
                    :href="`/${$i18n.locale}/managed`"
                  >{{ $t("menu.managed_services") }}</a>
                </div>
              </div>
              <div class="mobile-menu__section">
                <a
                  href="#"
                  class="navbar__link navbar__link--header"
                  v-text="$t('menu.for')"
                />
                <div class="columns">
                  <a
                    class="navbar__link col-md-6"
                    :href="`/${$i18n.locale}/market-research`"
                  >{{ $t("menu.market_research") }}</a>
                  <a
                    class="navbar__link col-md-6"
                    :href="`/${$i18n.locale}/professionals`"
                  >{{ $t("menu.professionals") }}</a>
                  <a
                    class="navbar__link col-md-6"
                    :href="`/${$i18n.locale}/consultants`"
                  >{{ $t("menu.consultants") }}</a>
                </div>
              </div>
              <div class="mobile-menu__section mobile-menu__section--last">
                <a
                  href="#"
                  class="navbar__link navbar__link--header"
                  v-text="$t('menu.resources')"
                />
                <div class="columns">
                  <a
                    :href="`/${$i18n.locale}/about`"
                    v-text="$t('menu.about')"
                    class="navbar__link col-md-6"
                  />
                  <a
                    href="https://caplena.bamboohr.com/careers"
                    class="navbar__link col-md-6"
                    v-text="$t('menu.jobs')"
                  />
                </div>
                <div class="columns">
                  <a
                    href="https://caplena.com/blog/"
                    class=" navbar__link col-md-6"
                    v-text="$t('menu.blog')"
                  />
                  <a
                    href="https://news.caplena.com"
                    class="navbar__link col-md-6"
                    v-text="$t('menu.news')"
                  />
                  <a
                    href="https://caplena.com/docs/developers"
                    class="navbar__link col-md-6"
                    v-text="$t('menu.api')"
                  />
                  <a
                    href="https://caplena.com/docs/knowledge-base"
                    class="navbar__link col-md-6"
                    v-text="$t('menu.docs')"
                  />
                </div>
                <div class="columns">
                  <a
                    class="navbar__link col-md-6"
                    :href="`/${$i18n.locale}/case-studies/`"
                  >
                    {{ $t("menu.case_studies") }}
                  </a>
                  <a
                    class="navbar__link col-md-6"
                    :href="'/en/terms-and-conditions/'"
                  >
                    {{ $t("nav.t_c") }}
                  </a>
                </div>
                <div class="columns">
                  <a
                    class="navbar__link col-md-6"
                    :href="'/en/privacy-policy/'"
                  >
                    {{ $t("nav.privacy_policy") }}
                  </a>
                </div>
              </div>
            </div>
          </transition>
          <div class="hide-md">
            <div class="navbar__link__dropdown d-inline-flex position-relative">
              <a
                :href="`/${$i18n.locale}/`"
                @click.prevent
                class="navbar__link"
              >
                {{ $t("menu.product") }}
                <chevron-down-icon :size="20" />
              </a>
              <div class="navbar__link__dropdown__menu">
                <a
                  :href="`/${$i18n.locale}/features/`"
                  class="navbar__link navbar__link__dropdown__menu__item navbar__link__dropdown__menu__item--header"
                  v-text="$t('menu.features')"
                  style="border: none;"
                />
                <a
                  :href="`/${$i18n.locale}/features#feature-1`"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.data_ingestion')"
                />
                <a
                  :href="`/${$i18n.locale}/features#feature-2`"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.discover_topics')"
                />
                <a
                  :href="`/${$i18n.locale}/features#feature-3`"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.tag_data')"
                />
                <a
                  :href="`/${$i18n.locale}/features#feature-4`"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.story')"
                />
                <a
                  :href="`/${$i18n.locale}/use-cases/`"
                  v-text="$t('menu.use_cases')"
                  class="navbar__link navbar__link__dropdown__menu__item navbar__link__dropdown__menu__item--header"
                />

                <a
                  :href="`/${$i18n.locale}/use-cases/review-analysis`"
                  v-text="$t('review.title')"
                  class="navbar__link navbar__link__dropdown__menu__item"
                />
                <a
                  :href="`/${$i18n.locale}/use-cases/transactional-cx`"
                  v-text="$t('cx.title')"
                  class="navbar__link navbar__link__dropdown__menu__item"
                />
                <a
                  :href="`/${$i18n.locale}/use-cases/survey`"
                  v-text="$t('survey.title')"
                  class="navbar__link navbar__link__dropdown__menu__item"
                />
                <a
                  :href="`/${$i18n.locale}/use-cases/brand-awareness`"
                  v-text="$t('brand.title')"
                  class="navbar__link navbar__link__dropdown__menu__item"
                />
                <a
                  :href="`/${$i18n.locale}/use-cases/text-analytics`"
                  v-text="$t('analytics.title')"
                  class="navbar__link navbar__link__dropdown__menu__item"
                />

                <div
                  v-text="$t('menu.for')"
                  class="navbar__link navbar__link__dropdown__menu__item navbar__link__dropdown__menu__item--header navbar__link__dropdown__menu__item navbar__link__dropdown__menu__item--unclickable"
                />
                <a
                  :href="`/${$i18n.locale}/market-research`"
                  v-text="$t('menu.market_research')"
                  class="navbar__link navbar__link__dropdown__menu__item"
                />
                <a
                  :href="`/${$i18n.locale}/professionals`"
                  v-text="$t('menu.professionals')"
                  class="navbar__link navbar__link__dropdown__menu__item"
                />
                <a
                  :href="`/${$i18n.locale}/consultants`"
                  v-text="$t('menu.consultants')"
                  class="navbar__link navbar__link__dropdown__menu__item"
                />
              </div>
            </div>
            <a class="navbar__link" :href="`/${$i18n.locale}/case-studies/`">
              {{ $t("menu.case_studies") }}
            </a>
            <a class="navbar__link" :href="`/${$i18n.locale}/pricing`">{{
              $t("menu.pricing")
            }}</a>
            <a class="navbar__link" :href="`/${$i18n.locale}/managed`">{{
              $t("menu.managed_services")
            }}</a>
            <div class="navbar__link__dropdown d-inline-flex position-relative">
              <a href="#" @click.prevent class="navbar__link">
                {{ $t("menu.resources") }}
                <chevron-down-icon :size="20" />
              </a>
              <!-- menu component -->
              <div class="navbar__link__dropdown__menu pt-2">
                <a
                  href="https://caplena.bamboohr.com/careers"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.jobs')"
                />
                <a
                  href="https://caplena.com/blog/"
                  class=" navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.blog')"
                />
                <a
                  href="https://news.caplena.com"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.news')"
                />
                <a
                  :href="'/en/terms-and-conditions/'"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('nav.t_c')"
                />
                <a
                  :href="'/en/privacy-policy/'"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('nav.privacy_policy')"
                />
                <a
                  href="https://caplena.com/docs/developers"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.api')"
                />
                <a
                  href="https://caplena.com/docs/knowledge-base"
                  class="navbar__link navbar__link__dropdown__menu__item"
                  v-text="$t('menu.docs')"
                />
              </div>
            </div>
            <a class="navbar__link" :href="`/${$i18n.locale}/about`">{{
              $t("menu.about")
            }}</a>
          </div>
          <div class="d-flex align-center">
            <div v-if="!isMobile">
              <a
                @click="openLogin()"
                class="btn btn-link btn-in-nav"
                style="margin-right: 15px"
              >
                {{ $t("menu.login") }}
              </a>
            </div>
            <a
              class="btn btn-primary btn-dark btn-in-nav hide-xs"
              :href="`/${$i18n.locale}/register/`"
            >
              {{ $t("menu.register") }}
            </a>
            <a
              @click.prevent.stop="showMenu = !showMenu"
              href="#"
              class="navbar__link btn-menu"
            >
              <menu-icon class="menu-icon" :size="30" />
            </a>
          </div>
        </header>
      </div>
    </div>
    <slot :openLogin="openLogin" />
    <footer class="footer">
      <div class="container">
        <div class="navbar-header grey text-center w-100">
          {{ $t("menu.partners") }}
        </div>
        <div
          class="d-flex align-center justify-space-around w-100 footer-logos"
        >
          <helptip :is-icon="false">
            <template v-slot:icon>
              <img
                src="@/assets/landing/footer-logo-1.svg"
                alt="Venture kick"
              >
            </template>
            {{ $t("partners.venture_kick") }}
          </helptip>

          <helptip :is-icon="false">
            <template v-slot:icon>
              <img
                src="@/assets/landing/footer-logo-2.svg"
                alt="Founder friendly labs"
                style="height: 33px;"
              >
            </template>
            {{ $t("partners.FFL") }}
          </helptip>

          <helptip :is-icon="false">
            <template v-slot:icon>
              <img
                src="@/assets/landing/footer-logo-4.jpg"
                alt="Planung & analyse"
                style="height: 40px;"
              >
            </template>
            {{ $t("partners.planung_analyse") }}
          </helptip>

          <helptip :is-icon="false">
            <template v-slot:icon>
              <img
                src="@/assets/landing/footer-logo-3.svg"
                alt="Spinoff"
                style="height: 50px;"
              >
            </template>
            {{ $t("partners.spinoff") }}
          </helptip>
        </div>
        <div
          class="d-flex align-center justify-space-around w-100 footer-logos footer-logos--second-row"
        >
          <helptip :is-icon="false">
            <template v-slot:icon>
              <img src="@/assets/landing/soc.png" alt="SOC 2" width="90">
            </template>
            {{ $t("partners.soc") }}
          </helptip>

          <helptip :is-icon="false">
            <template v-slot:icon>
              <a href="https://www.bvm.org/themen-positionen/detailseite-news/caplena-und-beiersdorf-gewinnen-den-innovationspreis-2023/">
                <img
                  src="@/assets/landing/footer-logo-5.svg"
                  alt="BVM"
                  style="height: 65px;"
                >
              </a>
            </template>
            <div v-html="$t('partners.BVM')" />
          </helptip>

          <helptip :is-icon="false">
            <template v-slot:icon>
              <a href="https://www.g2.com/products/caplena/reviews">
                <img
                  src="@/assets/landing/footer-logo-6.svg"
                  alt="G2"
                  style="height: 90px;"
                >
              </a>
            </template>
            <div v-html="$t('partners.G2')" />
          </helptip>
        </div>
        <div class="columns footer-separator">
          <div class="column col-3 col-md-12">
            <a class="navbar-brand" :href="`/${$i18n.locale}/`">
              <img
                style="height: 48px; width: 48px;"
                src="@/assets/landing/logo.svg?data"
                alt="logo"
              >
            </a>
          </div>
          <div class="column col-3 col-md-12 order-md-2">
            <div class="navbar-header">
              {{ $t("menu.product") }}
            </div>
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" :href="`/${$i18n.locale}/pricing/`">{{
                  $t("menu.pricing")
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :href="`/${$i18n.locale}/managed/`">{{
                  $t("menu.managed_services")
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :href="`/${$i18n.locale}/use-cases/`">
                  {{ $t("menu.use_cases") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :href="`/${$i18n.locale}/case-studies/`">
                  {{ $t("menu.case_studies") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://news.caplena.com">{{
                  $t("menu.news")
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://caplena.com/blog/">{{
                  $t("menu.blog")
                }}</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  :href="`/${$i18n.locale}/supported-languages/`"
                >
                  {{ $t("nav.supported_languages") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://caplena.com/docs/developers">
                  {{ $t("menu.api") }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://caplena.com/docs/knowledge-base"
                >
                  {{ $t("menu.docs") }}
                </a>
              </li>
            </ul>
          </div>
          <div class="column col-3 col-md-12 order-md-3">
            <div class="navbar-header">
              {{ $t("menu.company") }}
            </div>
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" :href="`/${$i18n.locale}/about/`">{{
                  $t("menu.about")
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://caplena.bamboohr.com/careers">{{
                  $t("menu.jobs")
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :href="'/en/terms-and-conditions/'">{{
                  $t("nav.t_c")
                }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :href="'/en/privacy-policy/'">{{
                  $t("nav.privacy_policy")
                }}</a>
              </li>
            </ul>
          </div>
          <div class="column col-3 col-md-12 order-md-1">
            <div class="navbar-header">
              {{ $t("menu.contact") }}
            </div>
            <ul class="navbar-nav">
              <li class="nav-item">
                Caplena AG
              </li>
              <li class="nav-item">
                <a class="nav-link" :href="emailAddrLink" v-text="emailAddr" />
              </li>
              <li class="nav-item">
                <a class="nav-link" href="tel:0041435085100">
                  +41 43 508 51 00
                </a>
              </li>

              <li class="nav-item flex-start-center-row address">
                <map-marker-icon :size="19" />
                <a
                  class="nav-link"
                  href="https://goo.gl/maps/t44zyYx3MSR2rcAH6"
                  target="_blank"
                  rel="noopener"
                >
                  Zweierstrasse 165, 8003 Zurich
                </a>
              </li>
            </ul>
            <div class="navbar-header text-blue hide-md">
              {{ $t("menu.follow") }}
            </div>
            <div class="d-flex hide-md">
              <a
                href="https://twitter.com/CaplenaCH"
                target="_blank"
                rel="noopener"
                class="mr-2"
              >
                <img
                  class="mr-2"
                  src="@/assets/landing/logo_twitter.svg?data"
                  alt="caplena-twitter"
                >
              </a>
              <a
                href="https://www.linkedin.com/company/27224654/"
                target="_blank"
                rel="noopener"
                class="ml-2"
              >
                <img
                  class="ml-2"
                  src="@/assets/landing/logo_linkedin.svg?data"
                  alt="caplena-linkedin"
                >
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="container">
          <div style="text-align: center">
            <div style="margin: -5px 0 10px 0">
              <a title="Realtime application protection" href="https://www.sqreen.com/?utm_source=badge" target="_blank">
                <img style="width:109px;height:36px" src="https://s3-eu-west-1.amazonaws.com/sqreen-assets/badges/20171107/sqreen-mono-badge.svg" alt="Sqreen | Runtime Application Protection">
              </a>
            </div>
          </div>
        </div> -->
        <div class="columns">
          <div class="column col-12 text-center">
            <div class="d-flex align-center footer-bottom">
              <div class="copyright">
                © Caplena
              </div>
              <a class="nav-link mr-2" :href="'/en/terms-and-conditions/'">
                {{ $t("nav.t_c") }}
              </a>
              <a class="nav-link ml-2" :href="'/en/privacy-policy/'">
                {{ $t("nav.privacy_policy") }}
              </a>
              <div class="d-flex ml-auto">
                <a
                  href="/en/"
                  class=" nav-link mr-2 d-flex align-center language"
                >
                  <img
                    class="mr-2"
                    style="width: 25px; height: 25px;"
                    src="@/assets/landing/flags/en.svg"
                    alt="German flag"
                  >
                  English
                </a>
                <a
                  href="/de/"
                  class="nav-link ml-2 d-flex align-center language pl-2"
                >
                  <img
                    class="mr-2"
                    style="width: 25px; height: 25px;"
                    src="@/assets/landing/flags/de.svg"
                    alt="German flag"
                  >
                  Deutsch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!--========== Login Popup ==========-->
    <transition name="fade">
      <div class="overlay" v-if="login.show" @click="closeLogin()" />
    </transition>
    <transition name="fade">
      <div
        v-if="login.show"
        id="login"
        class="modal-input"
        :class="{
          unsupported: browser.isUnsupported,
          challenge: login.step === '2fa-token'
        }"
      >
        <button
          class="modal-input__close"
          title="Close (Esc)"
          type="button"
          @click="closeLogin"
        >
          &#215;
        </button>
        <div v-if="browser.isUnsupported">
          <p v-html="$t('login.browser_unsupported')" />
          <ul>
            <li>
              <a href="https://www.google.com/chrome/">Google Chrome</a>
              version ≥ {{ browser.supported.chrome }}
            </li>
            <li>
              <a href="https://www.mozilla.org/en-US/firefox/new/">
                Mozilla Firefox</a>
              version ≥ {{ browser.supported.firefox }}
            </li>
            <li>
              <a href="http://www.opera.com/">Opera</a>
              version ≥ {{ browser.supported.opera }}
            </li>
            <li>
              <a href="https://www.apple.com/lae/safari/">Apple Safari</a>
              version ≥ {{ browser.supported.safari }}
            </li>
            <li>
              <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
                Microsoft Edge</a>
              version ≥ {{ browser.supported.msedge }}
            </li>
          </ul>
        </div>
        <div v-else-if="maintenance">
          <div class="text-danger mt-1" v-html="maintenance">
            {{ login.failedMessage }}
          </div>
        </div>
        <div v-else-if="pwreset.show">
          <form v-if="!pwreset.success" @submit.prevent="doResetPw">
            <h5 class="text-center pb-2">
              {{ $t("pwreset.title") }}
            </h5>
            <!-- <label class="font-weight-bold text-sm">{{ $t('login.email') }}</label> -->
            <input
              v-model="login.email"
              type="email"
              name="Email"
              :class="{ 'has-error': pwreset.failed }"
              :placeholder="$t('pwreset.email')"
              required
            >
            <div class="d-flex justify-content-end mt-2 mb-2">
              <a
                class="btn btn-primary btn-link btn-in-nav pl-0 pr-0 text-xs"
                href="#"
                @click.prevent="pwreset.show = false"
              >
                {{ $t("pwreset.back_to_login") }}
              </a>
            </div>
            <button
              type="submit"
              class="btn btn-primary modal-input__submit mb-2"
              :disabled="pwreset.loading"
              :class="{ shake: pwreset.failed && !pwreset.loading }"
            >
              <span v-if="!pwreset.loading">{{ $t("pwreset.action") }}</span>
              <span v-else>{{ $t("pwreset.loading") }}</span>
            </button>
            <div class="d-flex pt-2 justify-center">
              <div class="text-sm text-center text-grey mt-2">
                {{ $t("login.capabilities") }}
              </div>
              <a
                class="text-sm font-weight-bold text-green ml-2 mt-2"
                target="_blank"
                :href="`/${$i18n.locale}/register/`"
              >
                {{ $t("login.request_demo") }}
              </a>
            </div>
          </form>
          <div v-if="pwreset.failed" class="text-xs text-danger mt-1">
            {{ pwreset.failedMessage }}
          </div>
          <div v-if="pwreset.success" class="text-green text-sm">
            {{ $t("pwreset.success") }}
          </div>
        </div>
        <div v-else>
          <form @submit.prevent="doLogin">
            <template v-if="login.step === 'email' || login.step === 'auth'">
              <h5 class="text-center pb-2">
                {{ $t("login.title") }}
              </h5>
              <input
                v-show="login.step === 'email'"
                v-model="login.email"
                ref="login-email"
                type="email"
                name="Email"
                autocomplete="email"
                :class="{ 'has-error': login.failed }"
                :disabled="login.loading"
                :placeholder="$t('login.email')"
                required
              >
              <template v-if="login.step === 'auth'">
                <!-- <label class="font-weight-bold text-sm">{{ $t('login.password') }}</label> -->
                <input
                  v-model="login.password"
                  type="password"
                  name="password"
                  autocomplete="current-password"
                  ref="login-password"
                  :class="{ 'has-error': login.failed }"
                  :disabled="login.loading"
                  :placeholder="$t('login.password')"
                >
              </template>
            </template>
            <div v-else-if="login.step.startsWith('2fa')" class="twofa-token">
              <div class="alert-info" v-html="$t('login.2fa_info')" />
              <!-- <label class="font-weight-bold text-sm">{{ $t('login.2fa_token') }}</label> -->
              <input
                v-model="login.token"
                ref="2fa-token"
                :maxlength="current2FADevice.device_type === 'codes' ? 10 : 6"
                name="token"
                type="text"
                :class="{ 'has-error': login.failed }"
                :disabled="login.loading"
                :placeholder="$t('login.2fa_token')"
                required
              >
              <div
                v-if="login.available2FADevices.length"
                class="available-2fa-container"
              >
                <a
                  v-if="!login.showAvailable2FADevices"
                  href="#"
                  @click.prevent="login.showAvailable2FADevices = true"
                  v-text="$t('login.show_other_2fa')"
                />
                <ul v-else class="available-2fa-devices">
                  <li
                    class="2fa-device"
                    v-for="device in login.available2FADevices"
                    :key="device.id"
                  >
                    <a
                      href="#"
                      @click="select2FADevice(device)"
                      v-text="format2FADeviceName(device)"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-2 mb-2">
              <a
                class="btn btn-primary btn-link btn-in-nav pl-0 pr-0 text-xs"
                href="#"
                @click.prevent="pwreset.show = true"
              >
                {{ $t("pwreset.question") }}
              </a>
            </div>
            <button
              type="submit"
              class="btn btn-primary modal-input__submit mb-2"
              :disabled="login.loading"
              :class="{ shake: login.failed && !login.loading }"
            >
              <span v-if="login.loading">{{ $t("login.loading") }}</span>
              <span v-else-if="login.step === 'email'">{{
                $t("login.next")
              }}</span>
              <span v-else>{{ $t("login.button") }}</span>
            </button>
            <div class="text-xs text-danger mt-1" v-if="login.failed">
              {{ login.failedMessage }}
            </div>
            <div class="d-flex pt-2 justify-center">
              <div class="text-sm text-center text-grey mt-2">
                {{ $t("login.capabilities") }}
              </div>
              <a
                class="text-sm font-weight-bold text-green ml-2 mt-2"
                target="_blank"
                :href="`/${$i18n.locale}/register/`"
              >
                {{ $t("login.request_demo") }}
              </a>
            </div>
          </form>
        </div>
      </div>
    </transition>

    <!--========== New Password dialog ==========-->
    <transition name="fade">
      <div class="overlay" v-if="newpw.show" @click="newpw.show = false" />
    </transition>
    <transition name="fade-down">
      <div v-if="newpw.show" id="login" class="modal-input newpw-modal">
        <button
          class="modal-input__close"
          title="Close (Esc)"
          type="button"
          @click="newpw.show = false"
        >
          &#215;
        </button>
        <div v-if="!newpw.success">
          <form @submit.prevent="saveNewPw">
            <h5 class="text-center pb-2">
              {{ $t("newpw.title") }}
            </h5>
            <!-- <label class="font-weight-bold text-sm">{{ $t('newpw.password1') }}</label> -->
            <input
              v-model="newpw.password1"
              type="password"
              :placeholder="$t('newpw.password1')"
              :class="{
                'has-error':
                  newpw.failedResponse.new_password1 ||
                  newpw.failedResponse.new_password2
              }"
              :disabled="newpw.loading"
              required
            >
            <div
              v-if="newpw.failedResponse.new_password1"
              class="text-xs text-danger mt-1"
            >
              {{ newpw.failedResponse.new_password1[0] }}
            </div>
            <!-- <label class="font-weight-bold text-sm">{{ $t('newpw.password2') }}</label> -->
            <input
              v-model="newpw.password2"
              :disabled="newpw.loading"
              type="password"
              :class="{
                'has-error':
                  newpw.failedResponse.new_password1 ||
                  newpw.failedResponse.new_password2
              }"
              :placeholder="$t('newpw.password2')"
              required
            >
            <div
              v-if="newpw.failedResponse.new_password2"
              class="text-xs text-danger mt-1"
            >
              {{ newpw.failedResponse.new_password2[0] }}
            </div>

            <button
              type="submit"
              class="btn btn-primary modal-input__submit modal-input__submit--offset"
              :disabled="newpw.loading"
              :class="{
                animated: newpw.failed && !newpw.loading,
                shake: newpw.failed && !newpw.loading
              }"
              @click="saveNewPw"
            >
              <span v-if="!newpw.loading">{{ $t("newpw.button") }}</span>
              <span v-else>{{ $t("newpw.loading") }}</span>
            </button>

            <div class="text-xs text-danger mt-1" v-if="newpw.failed">
              <template v-if="newpw.failedResponse.resent_email">
                {{ $t("newpw.token_expired") }}
              </template>
              <template
                v-else-if="
                  newpw.failedResponse.token || newpw.failedResponse.uid
                "
              >
                {{ $t("newpw.token_invalid") }}
              </template>
              <template v-else-if="newpw.failedResponse.non_field_errors">
                {{ newpw.failedResponse.non_field_errors[0] }}
              </template>
            </div>
          </form>
        </div>
        <div v-else>
          {{ $t("newpw.success") }}
        </div>
      </div>
    </transition>

    <!--========== Account activation dialog ==========-->
    <transition name="fade">
      <div
        class="overlay"
        v-if="activateAccount.show"
        @click="activateAccount.show = false"
      />
    </transition>
    <transition name="fade-down">
      <div
        v-if="activateAccount.show"
        id="login"
        class="modal-input newpw-modal"
      >
        <button
          class="modal-input__close"
          title="Close (Esc)"
          type="button"
          @click="activateAccount.show = false"
        >
          &#215;
        </button>
        <div v-if="activateAccount.loading" v-html="$t('activation.loading')" />
        <div
          v-else-if="activateAccount.success"
          v-html="$t('activation.success')"
        />
        <div v-else-if="activateAccount.failed">
          <p v-text="activateAccount.failed" />
          <p>
            <span v-html="$t('activation.failed')" />&nbsp;
            <a :href="emailAddrLink">{{ emailAddr }}</a>
          </p>
        </div>
      </div>
    </transition>

    <!--========== COOKIES ACCEPT BAR ==========-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fast fadeInUp"
      leave-active-class="animated fadeOutDown fast"
    >
      <div v-if="showCookiesAccept" class="cookies" id="cookie-accept">
        <div class="d-flex align-center">
          <a class="cookies__close hover" @click.prevent="acceptCookies">&#215;</a>
          {{ $t("cookies.msg") }}
          <a
            class="cookies__link"
            href="/en/privacy-policy/#cookies"
            target="_blank"
          >{{ $t("cookies.more") }}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
import MenuIcon from 'vue-material-design-icons/Menu.vue'
import MapMarkerIcon from 'vue-material-design-icons/MapMarker.vue'

import Intercom from '@/mixins/intercom.js'
import PostHog from '@/mixins/posthog.js'
import Dealfront from '@/mixins/dealfront.js'
import BrowserType from '@/mixins/browserType'
import Cookies from 'js-cookie'

import { STATUS_JSON_URI } from '@/api.js'

require('smoothscroll')

const SUPPORTED_BROWSERS = {
  chrome: '60',
  chromium: '60',
  firefox: '50',
  safari: '11',
  opera: '46',
  msedge: '16',
  vivaldi: '1.14'
}
const EMAIL_KEY =
  '3nyQHV8CFZzg51ADmBKf4j0NvPMW2XcYGoEuTU7sk6lSLOwItqJRdie9xrahbp'

export default {
  codit: true,
  name: 'LandingScaffold',
  components: {
    ChevronDownIcon,
    MenuIcon,
    MapMarkerIcon
  },
  mixins: [Intercom, BrowserType, Dealfront, PostHog],

  data () {
    return {
      // Show the mobile menu icon
      showMenu: false,
      nav: {
        fixed: false,
        hidden: false
      },

      user: { id: 0 },

      login: {
        show: false,
        email: '',
        password: '',
        loading: false,
        failed: false,
        user: null,
        failedMessage: '',
        goto: null,
        next: null,
        token: '',
        step: 'email',
        available2FADevices: [],
        hasBackupTokens: false,
        challenge_device_id: '',
        showAvailable2FADevices: false
      },

      pwreset: {
        show: false,
        loading: false,
        failed: false,
        failedMessage: '',
        success: false
      },

      newpw: {
        token: '',
        uid: '',
        show: false,
        password1: '',
        password2: '',
        loading: false,
        failed: false,
        success: false,
        failedResponse: ''
      },

      activateAccount: {
        key: '',
        show: false,
        success: false,
        loading: false,
        failed: false
      },

      browser: {
        supported: SUPPORTED_BROWSERS,
        isUnsupported: false
      },

      maintenance: '',

      showCookiesAccept: false,
      urlParams: null
    }
  },

  computed: {
    isMobile () {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },

    currentPath () {
      return window.currentPath
    },

    /**
     * Create contact email address vom scrambled address
     * @return {String} Email address
     */
    emailAddr () {
      // Code from http://www.jottings.com/obfuscator/
      let coded = 'CBuq@w5DaFB5.wqG'
      let shift = coded.length
      let link = ''
      let ltr = ''
      for (var i = 0; i < coded.length; i++) {
        if (EMAIL_KEY.indexOf(coded.charAt(i)) === -1) {
          ltr = coded.charAt(i)
          link += ltr
        } else {
          ltr =
            (EMAIL_KEY.indexOf(coded.charAt(i)) - shift + EMAIL_KEY.length) %
            EMAIL_KEY.length
          link += EMAIL_KEY.charAt(ltr)
        }
      }
      return link
    },

    /**
     * Valid href link for support email
     * @return {String} Valid href for info address
     */
    emailAddrLink () {
      return `mailto:${this.emailAddr}?subject=I have a question`
    },

    current2FADevice () {
      return _.find(this.login.available2FADevices, {
        id: this.login.challenge_device_id
      })
    }
  },

  created () {
    this.setPageTitle()
    if (this.isPrerendering) { return }
    this.checkBrowserSupport()
    this.checkIfLoggedIn()
    this.checkQueryParams()
    if (process.env.VUE_APP_CAPLENA_ENV === 'prod') this.loadDealfront()
    window.addEventListener('scroll', _.throttle(this.handleNavScroll, 100))
    if (Cookies.get('cookies-accepted-inception-cookie') !== '1') {
      this.showCookiesAccept = true
    }
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleNavScroll)
  },

  methods: {
    getURLParam (key) {
      if (this.urlParams === null) {
        this.urlParams = new URLSearchParams(window.location.search.substr(1))
      }
      let val = this.urlParams.get(key)
      return val !== null ? decodeURIComponent(val) : null
    },

    /**
     * Check if there are any special query parameters given, such as account activation or password reset tokens
     */
    checkQueryParams () {
      // Check if account activation key is present, if not check if the reset pw token is present
      let activateAccountFlow = this.doActivateAccount()
      if (!activateAccountFlow) this.parseSetPWToken()
    },

    parseSetPWToken () {
      let token = this.getURLParam('setpwtoken')
      if (token !== null) {
        [this.newpw.uid, this.newpw.token] = decodeURIComponent(token).split(
          ':'
        )
        this.newpw.show = true
        return true
      } else return false
    },

    doActivateAccount () {
      let key = this.getURLParam('activationkey')
      if (key == null) return false
      this.activateAccount.show = true
      this.activateAccount.loading = true
      this.$api
        .post(
          '/api/auth/registration/verify-email',
          { key },
          { dontReport: [400] }
        )
        .then(res => {
          this.activateAccount.loading = false
          if (res.status === 400) {
            this.activateAccount.failed = res.data.details || 'Error'
          } else if (res.status === 200) {
            // For SSO accounts, directly go to IDP login page
            if ('saml2_redirect' in res.data) {
              window.location.replace(res.data.saml2_redirect)
              return
            }
            let hasResetFlow = this.parseSetPWToken()
            if (hasResetFlow) this.activateAccount.show = false
            else this.activateAccount.success = true
          } else this.activateAccount.failed = 'Error'
        })
        .catch(err => {
          this.activateAccount.loading = false
          this.activateAccount.failed = 'Error'
          console.error(err)
        })
      return true
    },

    /**
     * Open the login modal
     */
    openLogin (goTo) {
      if (goTo) this.login.goto = goTo
      if (this.user.id) this.redirectToApp()
      else {
        this.login.show = true
        this.login.failed = false
        this.login.step = 'email'
        this.login.available2FADevices = []
        this.login.showAvailable2FADevices = false
        this.login.challenge_device_id = ''
        if (!this.browser.isUnsupported) {
          setTimeout(v => this.$refs['login-email'].focus(), 50)
        }
      }
    },

    /**
     * Close the login modal
     */
    closeLogin () {
      this.login.password = ''
      this.login.token = ''
      this.login.show = false
      this.pwreset = {
        show: false,
        loading: false,
        failed: false,
        failedMessage: '',
        success: false
      }
    },

    /**
     * Check if the browser is among the supported ones for this app
     * If no, set the `browser.isUnsupported` variable, which prevents login
     */
    checkBrowserSupport () {
      let bowser = require('bowser')
      // Make strict check about supported browser
      this.browser.isUnsupported = !bowser.check(SUPPORTED_BROWSERS, true)
      if (this.browser.isUnsupported) console.error(bowser)
    },

    /**
     * Check if the user is logged in already
     * If yes, store the user details
     * Also check if there was a session issue, if yes show the login dialog and display an error message
     */
    checkIfLoggedIn () {
      this.$api
        .get('/api/auth/user/', {
          dontReport: [401, 403],
          validateStatus: s => s >= 200 && s <= 403
        })
        .then(res => {
          if (res.status === 200) {
            this.$set(this, 'user', res.data)
            this.identifyDealfront(this.user.id)
          }
          const urlParams = new URLSearchParams(
            window.location.search.substr(1)
          )
          let login = false
          if (urlParams.get('session_invalid') !== null) {
            this.login.failedMessage = this.$t('login.session_expired')
            login = true
          } else if (urlParams.get('login') !== null) login = true
          if (login) {
            // The goto comes from the App: Re-Open that page in the frontend
            this.login.goto =
              urlParams.get('go_to') !== null
                ? window.location.origin + decodeURIComponent(urlParams.get('go_to'))
                : null
            // The next comes from the api: Go to the api in this case (e.g. for OAUTH2 workflow)
            this.login.next =
              urlParams.get('next') !== null
                ? decodeURIComponent(urlParams.get('next'))
                : null
            this.openLogin()
            this.login.failed = true
          }
          this.postIdentifyInits()
        })
        .catch(err => {
          // If the API didn't answer properly, check if maintenance work is being carried out
          this.$api
            .get(STATUS_JSON_URI, {
              params: { ts: new Date().getTime() }, // Make sure no cached version is served
              headers: { Pragma: 'no-cache' },
              withCredentials: false
            })
            .then(res => {
              if (
                res.status === 200 &&
                res.data.maintenance &&
                res.data.maintenance.active
              ) {
                this.maintenance = res.data.maintenance.msg
              }
            })
          this.postIdentifyInits()
          console.error(err)
        })
    },

    /**
     * Submit the login information provided by the user
     * On success, go to the app
     * On failure, show failure message
     */
    doLogin () {
      if (this.login.loading) return
      this.login.loading = true
      this.login.failed = false

      let postData
      switch (this.login.step) {
        case 'email':
          postData = { email: this.login.email, next: this.login.goto }
          break
        case 'auth':
          postData = {
            email: this.login.email,
            password: this.login.password,
            next: this.login.goto
          }
          break
        case '2fa-token':
          postData = {
            token: this.login.token,
            challenge_device_id: this.login.challenge_device_id
          }
          if (this.current2FADevice.device_type === 'codes') {
            postData.token = 1234
            postData.backup_code = this.login.token
          }
          break
        case '2fa-challenge':
          postData = { challenge_device_id: this.login.challenge_device_id }
          break
      }

      this.$api
        .post(`/api/auth/login/?step=${this.login.step}`, postData, {
          dontReport: [400, 401, 403, 429],
          validateStatus: s => s >= 200 && s <= 429
        })
        .then(res => {
          this.login.loading = false
          if ('success' in res.data && res.data.success) this.redirectToApp()
          else if ('saml2_redirect' in res.data) {
            window.location.replace(res.data.saml2_redirect)
          } else if ('try' in res.data) {
            this.login.step = 'auth'
            this.$nextTick(() => {
              this.$refs['login-password'] &&
                this.$refs['login-password'].focus()
            })
          } else if ('challenge' in res.data && res.data.challenge) {
            this.login.step = '2fa-token'
            this.login.challenge_device_id = res.data.challenge_device_id
            this.$nextTick(() => this.$refs['2fa-token'].focus())
            this.$set(
              this.login,
              'available2FADevices',
              res.data.available_devices
            )
            this.$set(
              this.login,
              'hasBackupTokens',
              res.data.has_backup_tokens
            )
          } else {
            if ('non_field_errors' in res.data) {
              this.login.failedMessage = res.data.non_field_errors[0]
            } else if ('error' in res.data) {
              this.login.failedMessage = res.data.error
            } else this.login.failedMessage = this.$t('login.invalid')
            if (this.login.step === 'auth') {
              this.$refs['login-password'].focus()
            } else if (this.login.step === '2fa-token') {
              this.$refs['2fa-token'].focus()
            } else this.$refs['login-email'].focus()
            this.login.failed = true
          }
        })
        .catch(err => {
          this.login.loading = false
          this.login.failedMessage = this.$t('login.error')
          this.login.failed = true
          console.error(err)
        })
    },

    format2FADeviceName (device) {
      let t = this.$t(`2fa.device_types.${device.device_type}`)
      if (device.device_type === 'sms') t += ' ' + device.number
      if (device.name === 'default') {
        t += ` (${this.$t('2fa.device_names.default')})`
      }
      return t
    },

    select2FADevice (device) {
      this.login.challenge_device_id = device.id
      this.login.step = '2fa-challenge'
      this.login.showAvailable2FADevices = false
      this.doLogin()
    },

    /**
     * Submit the reset password form
     */
    doResetPw () {
      if (this.pwreset.loading) return
      this.pwreset.loading = true
      this.pwreset.failed = false
      this.$api
        .post(
          '/api/auth/password/reset/',
          { email: this.login.email },
          { dontReport: [400] }
        )
        .then(res => {
          this.pwreset.loading = false
          if ('detail' in res.data) this.pwreset.success = true
          else if ('email' in res.data) {
            if ('email' in res.data.email) {
              this.pwreset.failedMessage = res.data.email.email[0]
            } else this.pwreset.failedMessage = res.data.email[0]
            this.pwreset.failed = true
          } else {
            this.pwreset.failedMessage = this.$t('pwreset.invalid')
            this.pwreset.failed = true
          }
        })
        .catch(err => {
          this.pwreset.loading = false
          this.pwreset.failedMessage = this.$t('pwreset.error')
          this.pwreset.failed = true
          console.log(err)
        })
    },

    /**
     * Save the new password after the user has entered them, concluding the reset passwort process
     */
    saveNewPw () {
      if (this.newpw.loading) return
      this.newpw.loading = true
      this.newpw.failed = false
      this.$api
        .post(
          '/api/auth/password/reset/confirm/',
          {
            uid: this.newpw.uid,
            token: this.newpw.token,
            new_password1: this.newpw.password1,
            new_password2: this.newpw.password2
          },
          { dontReport: [400] }
        )
        .then(res => {
          this.newpw.loading = false
          if (res.status === 200 && 'detail' in res.data) {
            this.newpw.success = true
          } else {
            this.$set(this.newpw, 'failedResponse', res.data)
            this.newpw.failed = true
          }
        })
        .catch(err => {
          this.newpw.loading = false
          this.newpw.failedMessage = this.$t('newpw.error')
          this.newpw.failed = true
          console.log(err)
        })
    },

    postIdentifyInits () {
      this.posthogInit({ identity: this.user.id, disableSessionRecording: true })
      if (process.env.VUE_APP_CAPLENA_ENV !== 'dev') setTimeout(this.loadIntercom, 4000)
    },

    /**
     * Set the page title and description
     */
    setPageTitle () {
      let key = !this.currentPath ? 'index' : this.currentPath
      document.title = this.$t(`meta.${key}.title`) + ' | Caplena'
      document.documentElement.lang = this.$i18n.locale

      // If a description element is already set, (from prerendering)
      // then do not insert it again
      if (document.getElementById('head-meta-description')) return
      let description = document.createElement('meta')
      description.id = 'head-meta-description'
      description.name = 'description'
      description.content = this.$te(`meta.${key}.description`)
        ? this.$t(`meta.${key}.description`)
        : this.$t(`meta.index.description`)
      document.head.appendChild(description)
    },

    /**
     * Go to the application
     */
    redirectToApp () {
      // The `next` param means we need to go to the API, not the frontend
      if (this.login.next) {
        const prefix = this.login.next.startsWith('http') ? '' : window.API_HTTP_BASE_URI
        window.location = `${prefix}${this.login.next}`
      } else {
        window.location = this.login.goto !== null ? this.login.goto : '/app/'
      }
    },

    /**
     * Hide the navigation menu
     */
    hideMenu () {
      this.showMenu = false
    },

    /**
     * Hide the cookie notice and set a cookie that it has been noticed. Ha.
     */
    acceptCookies () {
      this.showCookiesAccept = false
      Cookies.set('cookies-accepted-inception-cookie', '1', { expires: 360 })
    },

    handleNavScroll () {
      if (
        !document.getElementsByClassName('hero-text')[0] ||
        !document.getElementsByClassName('top-menu')[0]
      ) {
        return
      }

      // const heroElH = document.getElementsByClassName('hero-text')[0].offsetHeight
      // const navElH = document.getElementsByClassName('top-menu')[0].offsetHeight
      // const limitElH = heroElH - navElH

      if (window.scrollY > 50) {
        this.nav.fixed = true
        // return
      } else {
        this.nav.fixed = false
      }

      // if (window.scrollY > limitElH) {
      //   this.nav.fixed = true
      //   this.nav.hidden = false
      // } else if (window.scrollY > navElH) {
      //   this.nav.hidden = true
      //   this.nav.fixed = false
      // } else {
      //   this.nav.fixed = false
      //   this.nav.hidden = false
      // }
    }
  }
}
</script>

<style lang="scss">
@import "~@/css/landing/landing.scss";
</style>

<i18n locale="en" src="@/i18n/en/landing/Global.json" />
<i18n locale="de" src="@/i18n/de/landing/Global.json" />
<i18n locale="en" src="@/i18n/en/landing/UseCases.json" />
<i18n locale="de" src="@/i18n/de/landing/UseCases.json" />
<i18n locale="en" src="@/i18n/en/components/account/Manage2FA.json" />
<i18n locale="de" src="@/i18n/de/components/account/Manage2FA.json" />