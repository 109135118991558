<template>
  <landing-scaffold>
    <section class="hero-text hero-text--case-study overflow-hidden">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-6 col-md-12 position-relative case-study__info">
            <img
              src="@/assets/landing/case-studies/teufel/logo.svg"
              class="case-study__logo"
              alt="Teufel Logo"
            >
            <h2 class="case-study__title">
              How Teufel Audio Turned up the Volume on Customer Satisfaction With Caplena.
            </h2>
            <div class="case-study__person case-study__person--large d-flex align-center">
              <img class="mr-2" src="@/assets/landing/testimonial_maria.png" alt="Maria" style="object-fit: cover;">
              <div class="ml-2">
                <div class="case-study__person__name">
                  Maria Mörbe
                </div>
                <div class="case-study__person__position">
                  Team Lead Customer & Market Insights @
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://lu.teufelaudio.com/"
                  >
                    Teufel
                  </a>
                </div>
              </div>
              <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="case-study-content">
      <div class="container position-relative">
        <div class="columns">
          <div class="col-1 hide-md" />
          <div class="col-7 col-md-12 order-md-2 case-study__column">
            <div class="case-study__text">
              Teufel Audio is a prominent German consumer electronics manufacturer that offers a wide range of audio devices, speakers, and accessories to cater to various preferences, budgets, and room sizes. The company has made a remarkable mark in the German-speaking market and expanded its global reach.
            </div>
            <img
              src="@/assets/landing/case-studies/teufel/thumbnail.png"
              class="case-study__image"
              alt="Factworks"
            >
            <label class="case-study__image-text" />
            <h5 class="case-study__title">
              Challenge
            </h5>
            <div class="case-study__text">
              Teufel Audio recognizes that its customers are not just consumers but passionate users who seek high-quality sound and music experiences. Therefore, the company understands the importance of quantitative text research to comprehend its deeper reasoning. However, as the data accumulates, analyzing it becomes more challenging.
            </div>
            <h5 class="case-study__title">
              Solution
            </h5>
            <div class="case-study__text">
              Caplena provides Teufel Audio with a way to organize vast amounts of unstructured customer feedback, allowing for efficient data analysis and practical recommendations.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  What is your favorite feature of Caplena for customer feedback?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Maria (Teufel)
                </div>
                <div class="case-study__conversation__answer__text">
                  The dashboards are incredibly beneficial for us. We can view and sort our coding in multiple ways and share them with internal stakeholders, who can delve into the data, coding, and original mentions themselves.
                </div>
              </div>
            </div>
            <h5 class="case-study__title">
              Results
            </h5>
            <div class="case-study__text">
              With Caplena on its side, Teufel Audio is always in tune with its customers. Teufel Audio's Customer and Market Insights team leverages Caplena's capabilities to ensure customer feedback is heard and action is taken swiftly. For example, the team employs Caplena's integration capabilities to monitor ongoing customer feedback continuously from Google, Amazon, and Trust Pilot reviews.
            </div>
            <div class="case-study__conversation">
              <div class="case-study__conversation__question">
                <div class="case-study__conversation__question__author">
                  Caplena
                </div>
                <div class="case-study__conversation__question__text">
                  Could you provide an example of how you utilized Caplena to enhance a Teufel product?
                </div>
              </div>
              <div class="case-study__conversation__answer">
                <div class="case-study__conversation__answer__author">
                  Maria (Teufel)
                </div>
                <div class="case-study__conversation__answer__text">
                  We used Caplena to improve the Teufel Boomster, which had high satisfaction ratings, but quantitative KPIs showed room for customer-centric improvement. Caplena helped us identify areas for improvement, like a key lock to prevent unintentional operation during transportation or a modified carry handle for customers with bigger hands, based on open text fields for specific feedback.
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 hide-md" />
          <div class="col-3 col-md-12 order-md-1">
            <div class="case-study__company">
              <div class="case-study__company__title">
                Teufel
              </div>
              <div class="case-study__company__text case-study__company__text--small">
                Teufel Audio is a prominent German consumer electronics manufacturer that offers a wide range of audio devices, speakers, and accessories to cater to various preferences, budgets, and room sizes. The company has made a remarkable mark in the German-speaking market and expanded its global reach.
              </div>
              <div class="case-study__company__subtitle">
                Datasources connected
              </div>
              <div class="case-study__company__text">
                Google Maps, Trustpilot, Amazon, Customer satisfaction survey, Ad-Hoc studies
              </div>
              <div class="case-study__company__subtitle">
                Topics being analyzed
              </div>
              <div class="case-study__company__text">
                Product quality, Sustainability, Brand recognition, Reasons for satisfaction/dissatisfaction
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<i18n locale='en' src='@/i18n/en/landing/CaseStudies.json' />
<i18n locale='de' src='@/i18n/de/landing/CaseStudies.json' />