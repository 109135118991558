<template>
  <section class="section-comparison" id="section-comparison">
    <div class="container">
      <div class="comparison-banner d-flex flex-column align-center text-center">
        <h2 class="text-green">
          {{ $t('banner.title') }}
        </h2>
        <div class="comparison-banner__text">
          {{ $t('banner.text') }}
        </div>
        <a class="btn btn-primary btn-green mr-2" :href="`/${$i18n.locale}/register/`">
          {{ $t('menu.register') }}
          <arrow-right class="ml-2" :size="19" />
        </a>
      </div>
      <div class="container">
        <div class="comparison-table column col-12">
          <h3 class="comparison-table__title">
            {{ $t('comparison.title') }}
          </h3>
          <div class="comparison-table__subtitle">
            {{ $t('comparison.subtitle') }}
          </div>
          <div class="comparison-table-container">
            <div class="d-flex comparison-table__row comparison-table__row--header caplena d-flex">
              <div class="comparison-table__row__cell comparison-table__row__cell--header" />
              <div class="comparison-table__row__cell comparison-table__row__cell--caplena">
                <div class="comparison-table__row__cell__logo">
                  <img src="@/assets/landing/logo.svg?data" alt="logo">
                </div>
                <div class="comparison-table__row__cell__text">
                  Caplena
                </div>
              </div>

              <div class="comparison-table__row__cell comparison-table__row__cell--xls">
                <div class="comparison-table__row__cell__logo">
                  <img src="@/assets/landing/comparison_logos/xls.svg" alt="xls-logo">
                </div>
                <div class="comparison-table__row__cell__text">
                  Excel
                </div>
              </div>

              <div class="comparison-table__row__cell">
                <div class="comparison-table__row__cell__logo">
                  <img src="@/assets/landing/comparison_logos/qda.png" alt="qda-logo">
                </div>
                <div class="comparison-table__row__cell__text">
                  MaxQDA
                </div>
              </div>

              <div class="comparison-table__row__cell">
                <div class="d-flex">
                  <div class="comparison-table__row__cell__logo">
                    <img src="@/assets/landing/comparison_logos/qualtrics.png" alt="qualtrics-logo">
                  </div>
                </div>
                <div class="comparison-table__row__cell__text">
                  Qualtrics
                </div>
              </div>

              <div class="comparison-table__row__cell">
                <div class="comparison-table__row__cell__logo">
                  <img src="@/assets/landing/comparison_logos/gavagai.jpg" alt="gavagai-logo">
                </div>
                <div class="comparison-table__row__cell__text">
                  Gavagai
                </div>
              </div>

              <div class="comparison-table__row__cell">
                <div class="comparison-table__row__cell__logo">
                  <img src="@/assets/landing/comparison_logos/discover.jpg" alt="discover-logo">
                </div>
                <div class="comparison-table__row__cell__text">
                  Discover.ai
                </div>
              </div>
            </div>

            <!-- core_principle -->
            <div class="d-flex comparison-table__row d-flex">
              <div class="comparison-table__row__cell comparison-table__row__cell--header">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.headers.core_principle')"
                />
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--caplena">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.caplena.core_principle')"
                />
                <helptip class="ml-2" :size="15">
                  {{ $t('comparison.table.caplena.core_principle_helptip') }}
                </helptip>
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.xls.core_principle')"
                />
                <helptip class="ml-2" :size="15">
                  {{ $t('comparison.table.xls.core_principle_helptip') }}
                </helptip>
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qda.core_principle')"
                />
                <helptip class="ml-2" :size="15">
                  {{ $t('comparison.table.qda.core_principle_helptip') }}
                </helptip>
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qualtrics.core_principle')"
                />
                <helptip class="ml-2" :size="15">
                  {{ $t('comparison.table.qualtrics.core_principle_helptip') }}
                </helptip>
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.gavagai.core_principle')"
                />
                <helptip class="ml-2" :size="15">
                  {{ $t('comparison.table.gavagai.core_principle_helptip') }}
                </helptip>
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.discover.core_principle')"
                />
                <helptip class="ml-2" :size="15">
                  {{ $t('comparison.table.discover.core_principle_helptip') }}
                </helptip>
              </div>
            </div>

            <!-- text_type -->
            <div class="d-flex comparison-table__row d-flex">
              <div class="comparison-table__row__cell comparison-table__row__cell--header">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.headers.text_type')"
                />
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--caplena">
                <div class="d-flex">
                  <div
                    class="comparison-table__row__cell__text"
                    v-html="$t('comparison.table.caplena.text_type')"
                  />
                  <helptip class="ml-2" :size="15">
                    {{ $t('comparison.table.caplena.text_type_helptip') }}
                  </helptip>
                </div>
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.xls.text_type')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qda.text_type')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qualtrics.text_type')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.gavagai.text_type')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.discover.text_type')"
                />
              </div>
            </div>

            <!-- data_ingestion -->
            <div class="d-flex comparison-table__row d-flex">
              <div class="comparison-table__row__cell comparison-table__row__cell--header">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.headers.data_ingestion')"
                />
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--caplena">
                <div class="d-flex">
                  <div
                    class="comparison-table__row__cell__text"
                    v-html="$t('comparison.table.caplena.data_ingestion')"
                  />
                  <helptip class="ml-2" :size="15">
                    {{ $t('comparison.table.caplena.data_ingestion_helptip') }}
                  </helptip>
                </div>
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.xls.data_ingestion')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qda.data_ingestion')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qualtrics.data_ingestion')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.gavagai.data_ingestion')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.discover.data_ingestion')"
                />
              </div>
            </div>

            <!-- pros -->
            <div class="d-flex comparison-table__row d-flex">
              <div class="comparison-table__row__cell comparison-table__row__cell--header">
                <div class="comparison-table__row__cell__text">
                  <div>{{ $t('comparison.table.headers.pros') }}</div>
                </div>
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--caplena">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.caplena.pros')"
                />
                <helptip class="ml-2" :size="15">
                  <div style="text-align: left" v-html="$t('comparison.table.caplena.pros_helptip')" />
                </helptip>
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.xls.pros')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qda.pros')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qualtrics.pros')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.gavagai.pros')"
                />
              </div>
              <div class="comparison-table__row__cell">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.discover.pros')"
                />
              </div>
            </div>

            <!-- cons -->
            <div class="d-flex comparison-table__row d-flex">
              <div class="comparison-table__row__cell comparison-table__row__cell--header comparison-table__row__cell--last">
                <div class="comparison-table__row__cell__text">
                  <div>{{ $t('comparison.table.headers.cons') }}</div>
                </div>
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--last comparison-table__row__cell--caplena comparison-table__row__cell--caplena--last">
                <div class="comparison-table__row__cell__text">
                  <div
                    class="comparison-table__row__cell__text"
                    v-html="$t('comparison.table.caplena.cons')"
                  />
                </div>
                <helptip class="ml-2" :size="15">
                  {{ $t('comparison.table.caplena.cons_helptip') }}
                </helptip>
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--last">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.xls.cons')"
                />
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--last">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qda.cons')"
                />
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--last">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.qualtrics.cons')"
                />
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--last">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.gavagai.cons')"
                />
              </div>
              <div class="comparison-table__row__cell comparison-table__row__cell--last">
                <div
                  class="comparison-table__row__cell__text"
                  v-html="$t('comparison.table.discover.cons')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'

export default {
  components: {
    ArrowRight
  }
}
</script>
