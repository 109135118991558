<template>
  <landing-scaffold class="about-page">
    <section class="hero-text overflow-hidden hero-text--about hero-text--dense">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-6 col-md-12">
            <div class="d-flex flex-column about-page__info">
              <h1 v-html="$t('hero.h1')" />
              <div class="hero-text__subtitle" v-html="$t('hero.subtitle')" />
              <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
            </div>
          </div>
          <div class="col-6 col-md-12 d-flex justify-center align-center h-100">
            <img
              class="hero-text--about__image"
              src="@/assets/landing/about-hero.svg"
              alt="About us hero"
            >
          </div>
        </div>
      </div>
    </section>
    <section class="about-page__team">
      <div class="container">
        <div class="columns">
          <div class="column col-12 text-center">
            <h3 v-text="$t('team.title')" />
            <div class="about-page__team__text">
              {{ $t('team.subtitle') }}
            </div>
          </div>
        </div>
        <div class="columns">
          <div v-for="(member, idx) in team"
               :key="member"
               class="column col-4 col-md-12"
          >
            <helptip :is-icon="false" x-offset="0" x-offset-footnote="50%">
              <template v-slot:icon>
                <div class="flex-center-center about-page__team__member position-relative">
                  <img :src="photos[idx]" :alt="member" :style="`object-position: 'top'}`">
                  <h5>
                    {{ $t(`team.${member}.first`) }} {{ $t(`team.${member}.second`) }}
                  </h5>
                  <div class="about-page__team__member__position">
                    {{ $t(`team.${member}.position`) }}
                  </div>
                </div>
              </template>
              {{ $t(`team.${member}.bio`) }}
            </helptip>
          </div>
        </div>
      </div>
    </section>
    <section class="blue about-page__companies">
      <div class="container text-center">
        <h3>{{ $t('companies.title') }}</h3>
        <div class="about-page__companies__text" v-html="$t('companies.subtitle')" />
        <div class="about-page__companies__logos logos d-flex">
          <div class="logo-holder">
            <img src="@/assets/landing/customer_logos/factworks_dark.svg?data" alt="Factworks" style="height: 30px;">
          </div>
          <div class="logo-holder">
            <img src="@/assets/landing/customer_logos/swisscom.svg?data" alt="Swisscom" style="height: 50px;">
          </div>
          <div class="logo-holder">
            <img src="@/assets/landing/customer_logos/ebay_dark.svg?data" style="height: 30px;" alt="Ebay">
          </div>
          <div class="logo-holder">
            <img src="@/assets/landing/customer_logos/dhl.svg?data" alt="DHL" style="height: 20px;">
          </div>
          <div class="logo-holder">
            <img src="@/assets/landing/customer_logos/coop.svg?data" style="height: 25px;" alt="Coop">
          </div>
          <div class="logo-holder">
            <img src="@/assets/landing/customer_logos/joyn.svg?data" style="height: 28px; top: 3px;" alt="Joyn">
          </div>
          <!-- <div class="logo-holder">
            <img src="@/assets/landing/customer_logos/link.svg?data" alt="Link Institut" style="height: 23px;">
          </div> -->
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="columns">
          <div class="column col-7 col-md-12 col-mx-auto">
            <div class="about-page__text-section">
              <h3 class="about-page__title">
                {{ $t('about.company') }}
              </h3>
              <div class="about-page__text" v-html="$t('about.company_text')" />
            </div>
            <h3 class="about-page__title">
              {{ $t('about.story') }}
            </h3>
            <div class="about-page__text" v-html="$t('about.story_text_1')" />
            <img
              src="@/assets/landing/maurice&pascal.jpg"
              class="about-page__image w-100"
              alt="Selina"
            >
            <div class="about-page__text" v-html="$t('about.story_text_2')" />
          </div>
        </div>
      </div>
    </section>
    <trial />
  </landing-scaffold>
</template>

<script>

import LandingScaffold from '@/pages/LandingScaffold'
import Trial from '@/landing/Trial'

export default {
  components: {
    LandingScaffold,
    Trial
  },

  data () {
    return {
      team: ['maurice', 'pascal', 'misel', 'aleks', 'sheila', 'nick', 'andre', 'onur', 'yves', 'andrea', 'marco', 'anna', 'maki', 'bogdan', 'lynn', 'kate', 'aykut']
    }
  },

  computed: {
    photos () {
      return _.map(this.team, member => require(`@/assets/landing/team/${member}.jpg`))
    }
  },

  created () {
  },

  mounted () {
  },

  methods: {
  }
}

</script>

<i18n locale='en' src='@/i18n/en/landing/About.json' />
<i18n locale='de' src='@/i18n/de/landing/About.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />