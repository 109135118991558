<template>
  <div class="faq">
    <div class="faq__item" :class="expanded && 'faq__item--active'" @click="toggleExpand">
      <div class="d-flex w-100 align-center justify-space-between">
        <div class="faq__item__title">
          {{ title }}
        </div>
        <div class="faq__item__icon">
          <plus v-if="!expanded" :size="30" />
          <minus v-else :size="30" />
        </div>
      </div>
      <vue-typer v-if="expanded" :repeat="0" :type-delay="20" class="faq__item__answer" :text="answer" />
    </div>
  </div>
</template>

<script>

import Plus from 'vue-material-design-icons/Plus.vue'
import Minus from 'vue-material-design-icons/Minus.vue'
import { VueTyper } from 'vue-typer'

export default {
  components: {
    Plus,
    Minus,
    VueTyper
  },

  props: {
    title: { type: String, default: '' },
    answer: { type: String, default: '' },
    open: { type: Boolean, default: false }
  },

  data () {
    return {
      expanded: this.open
    }
  },

  methods: {
    toggleExpand () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style>

</style>