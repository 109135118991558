<template>
  <landing-scaffold class="use-cases-page">
    <section class="hero-text overflow-hidden hero-text--use-cases hero-text--dense">
      <div class="container position-relative">
        <div class="columns align-center">
          <div class="col-1 hide-md" />
          <div class="col-5 col-md-12">
            <div class="d-flex flex-column hero-text--use-cases__text-container">
              <h1 class="use-case__title" v-html="$t('cx.title')" />
              <div class="hero-text__subtitle" v-html="$t('cx.subtitle')" />
              <a
                class="btn btn-primary btn-green"
                :href="`/${$i18n.locale}/register/`"
              >
                {{ $t('menu.register') }}
                <arrow-right :size="19" />
              </a>
              <img src="@/assets/landing/hero-text-mask.svg" alt="hero-mask" class="hero-text__mask position-absolute">
            </div>
          </div>
          <div class="col-6 col-md-12 d-flex justify-center align-center">
            <img
              class="hero-text--use-cases__title-image"
              src="@/assets/landing/use-cases/cx-large.svg"
            >
          </div>
        </div>
      </div>
    </section>
    <section class="feature use-case">
      <div class="container">
        <div class="columns">
          <div class="col-6 col-md-12 d-flex justify-center order-md-2">
            <img
              src="@/assets/landing/feature-2-learning.svg"
              class="feature__image feature__image--offset-top-md"
              alt="Insights"
            >
          </div>
          <div class="col-6 col-md-12 d-flex justify-center align-center order-md-1">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('cx.title') }}
              </div>
              <h4 class="position-relative">
                {{ $t('cx.subitems[0].title') }}
              </h4>
              <div class="feature__list mb-2">
                <div
                  v-for="(feature, idx) in getFeatureListByIdx(0)"
                  :key="idx"
                  class="feature__list__item feature__list__item--small d-flex"
                >
                  <check class="feature__list__check h-100" :size="24" />
                  <div>
                    <div class="feature__list__subtitle">
                      {{ feature.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12 d-flex align-center justify-center feature__subitem order-md-3">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('cx.title') }}
              </div>
              <h4>{{ $t('cx.subitems[1].title') }}</h4>
              <div class="feature__text feature__text--small">
                {{ $t('cx.subitems[1].generated') }}
              </div>
              <div class="feature__list">
                <div
                  v-for="(feature, idx) in getFeatureListByIdx(1)"
                  :key="idx"
                  class="feature__list__item feature__list__item--small d-flex"
                >
                  <check class="feature__list__check h-100" :size="24" />
                  <div>
                    <div class="feature__list__subtitle">
                      {{ feature.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex order-md-4">
            <img
              src="@/assets/landing/feature-2.svg"
              class="feature__image"
              alt="Auto learning"
              style="height: 420px;"
            >
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex align-center justify-center order-md-6">
            <img
              src="@/assets/landing/feature-4.svg"
              class="feature__image"
              alt="Brand management"
              style="height: 335px;"
            >
          </div>
          <div class="col-6 col-md-12 feature__subitem d-flex align-center justify-center order-md-5">
            <div class="feature__item">
              <div class="feature__tag">
                {{ $t('cx.title') }}
              </div>
              <h4>{{ $t('cx.subitems[2].title') }}</h4>
              <div class="feature__list">
                <div
                  v-for="(feature, idx) in getFeatureListByIdx(2)"
                  :key="idx"
                  class="feature__list__item feature__list__item--small d-flex"
                >
                  <check class="feature__list__check h-100" :size="24" />
                  <div>
                    <div class="feature__list__subtitle">
                      {{ feature.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <features-banner />
        </div>
      </div>
    </section>
  </landing-scaffold>
</template>

<script>
import LandingScaffold from '@/pages/LandingScaffold'
import FeaturesBanner from '@/landing/FeaturesBanner'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import Check from 'vue-material-design-icons/CheckboxMarkedCircleOutline.vue'

export default {
  components: {
    LandingScaffold,
    ArrowRight,
    FeaturesBanner,
    Check
  },

  data () {
    return {}
  },
  computed: {},
  mounted () {},
  methods: {
    getFeatureListByIdx (idx) {
      return this.$t(`cx.subitems[${idx}].feature_list`)
    }
  }
}
</script>

<i18n locale='en' src='@/i18n/en/landing/UseCases.json' />
<i18n locale='de' src='@/i18n/de/landing/UseCases.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />
